// 
// widgets.scss
//

// widget-box-2
.widget-box-2 {
    .widget-detail-2 {
      .badge {
        padding: 5px 10px;
      }
    }
}


// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        border-bottom: 1px solid $light;
        overflow: hidden;
        padding: 0.75rem 0;
        position: relative;
        &:last-child {
            border: none;
        }
        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;
            img {
                width: 40px;
            }
        }
        .inbox-item-author {
            color: $dark;
            display: block;
            margin-bottom: 3px;
        }
        .inbox-item-text {
            color: $gray-600;
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }
        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}



.list-group-custom{
    max-height: 442px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 
    overflow-y: scroll;
    scrollbar-color:$blue;
}


// .list-group-custom::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
//     background-color: $gri;
//   }
  
//   .list-group-custom::-webkit-scrollbar {
//     width: 8px;
//     // background-color: #F5F5F5;
//   }
  
//   .list-group-custom::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
//     background-color: $blue-cost;
//   }


.dropzone-btn{
  height: 38px;
  align-self: flex-end;
}

.DraftEditor-editorContainer{
    ol{
      // display:list-item;
        li{
            display: block !important;
        }
    }
    ul{
      
        li{
          list-style-position:outside;
           display:list-item;
        }
    }
}




.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
  }


  .card-box-fin-edge{
    height: 165px;
    padding:1.2em;
  }