.rounds-management-container {
    //margin-left: 19.5%;
    height: 1035px;
    width: 100%;
    margin-right: 16px;
    margin-top: 15px;
    border-radius: 10px;
    background-image: linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%);
    //margin-top: 90px;

    .rounds-container {
        // border: 2px solid;
        //height: 1035px;

        .inner {
            padding-top: 100px;

            .rounds-container-row1 {
                //margin-top: 150px;

                // border: 2px solid;
                display: flex;
                justify-content: space-around;
            }

            .white-line {
                margin-top: 6px;
                //margin-left: 140px;
            }

            .rounds-container-row2 {
                // border: 2px solid;
                display: flex;
                margin-top: 120px;

                justify-content: space-around;
               //padding: 0 25px;
                .round-moderator{
                    width:170px;
                }
                :not(:last-child) {
                    //margin-right: auto;
                }

            }

            .white-separator{
                display: flex;
                justify-content:center;


                .white-separator-line{
                    background-color: #ffffff;
                    height:20px;
                    width:85%;
                }
            }

            .white-line2 {}

        }
    }
}
