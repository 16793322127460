//RoundFinished.js
.round-finished-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around;
  align-items: center;
  width: 1728px;
  height: 1544px;
  background: url(../../../../../assets/images/leadEdge/bg.svg) no-repeat center
    center/cover; //good
  background-color: #1a6aa0e7;
  border-radius: 10px;
  padding-top: 100px;
  margin-top: 15px;

  .round-finished-body-bottom {
    position: relative;
    bottom: 80px;
    padding: 15px 30px;
    height: 600px;
    overflow-y: auto;

    //all elements inside
    & > * {
      margin-bottom: 20px;
    }
  }

  .round-finished-footer {
    position: relative;
    bottom: 80px;
    left: 580px;
  }
  .round-finished-header {
    position: relative;
    bottom: 30px;
  }
  .round-finished-header-btn-moderator {
    margin-left: 1240px;
    margin-top: -120px;
  }
}

//RoundFinishedHeader.js
.round-finished-header-container {
  display: flex;
  flex-direction: row !important;
  position: relative;
  bottom: 25px;

  .round-finished-header-btn {
    display: inline-block;
    height: 50px;
    padding: 0px;

    .button-close {
      position: relative;
      left: 320px;
      bottom: 40px;
    }
  }

  .round-finished-header-spans {
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;

    .congratulations {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 28px;
      color: #ffffff;
      margin: 0 auto;
    }

    .rank-span {
      font-family: Karla;
      font-style: italic;
      font-weight: normal;
      font-size: 55px;
      line-height: 28px;
      color: #ffffff;
    }
  }
}

.round-finished-body-bottom::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px;
}

/* Track */
.round-finished-body-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: grey;
}

/* Handle */
.round-finished-body-bottom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: darkgray;
}

/* Handle */
.round-finished-body-bottom::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #f4f2ff;
}
