//these styles are specific to cashflowStatement, and IncomeStatement containers
.accounting-container {
  &.cash-flow {
    border-radius: 75px 0px 0px 0px;
    background: #f9f9f9;
    //width: fit-content;
    padding: 50px 0px 60px 10px;
    // border: 1px solid;

    .accounting-container-stack {
      .cashflow-operations {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      & > *:nth-child(2) {
        margin-left: 20px;
      }
      

      & > *:nth-child(7) {
        margin-left: 20px;
        margin-top: 20px;
      }

      & > *:nth-child(10) {
        margin-left: 20px;
        margin-top: 20px;
      }

      & > *:nth-child(23) {
        margin-left: 10px;
        margin-right: 10px;
        // border: 2px solid;
      }
    }
  }
}

.cash-flow {
  width: 100%;

  .cashflow-statement-footer {
    margin-top: 10px;
    display: inline-block;

    & > * {
      // border: 1px solid red;
      //width: 570px;
    }
  }
}

.accounting-subheader {
  span {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 140.5%;
    color: #34607f;
  }
}

// prop class
.whiteBorder {
  display: inline-block;
  border: 4px solid white;
  border-radius: 75px 0px 0px 0px;
}

.income-statement-container {
  width:100%;
  .accounting-main-stack {
    .accounting-container-header {
      flex: 1;
      .accounting-container-header-inner {
        margin-bottom: 10px;

      }
    }
    .account-revenue {
      margin-top: 10px;
    }
    .account-cogs {
      //
    }
    .account-sales {
      margin-top: 10px;
    }
  }
}
