//WinnersList.js
.winners-list-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  //we can apply an overlay color this way
  // margin-left: -50px;
  width: 800px;

  .winners-list-items {
    padding: 0px 50px;
    // border: 2px solid;
    display: flex;
    justify-content: center;

    & > * {
      display: inline-block !important;
    }
  }
}

//WinnersRank single Component
.winners-rank-container {
  //   padding: 100px 0px;
  padding-bottom: 0px;
  margin-bottom: 15px;

  .winners-rank-content {
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .winner-rank {
      margin-bottom: 20px;

      img {
        &:hover {
          opacity: 0.7;
          transform: scale(1.05);
        }
      }
    }

    .winner-brand {
      margin-bottom: 20px;

      img {
        &:hover {
          opacity: 0.7;
          transform: scale(1.05);
        }
      }
    }

    .winner-infos {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      span.info1 {
        display: inline-block !important;
        margin-bottom: -10px;
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        color: #ffffff;
      }

      span.info2 {
        display: inline-block !important;
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        color: #3affdc;
      }
    }
  }
}

//customized classes for the content on the center(bmw) applied as props
.class1 {
  position: relative;
  bottom: 200px;
  margin-left: -40px;
  margin-right: -40px;
  z-index: 999;
}

.class2 {
  position: relative;
  top: -190px;
}

.class3 {
  position: relative;
  bottom: 70px;
}

.movetoTop {
  position: relative;
  bottom: 100px;
}

.moveItemToRight {
  //   border: 2px solid red;
  position: relative;
  left: 20px;
}

.moveItemToLeft {
  //   border: 2px solid green;
  position: relative;
  right: 20px;
}
