//Without these imports, lots of styles will be lost !!

@import "_accounting_entry_styles.scss";
@import "_accounting_section_styles.scss";

// AccountingHeader Component
.accounting-header-container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  .account-header-stack {
    .account-text-wrapper {
      background: none;
      box-shadow: none;

      span.title {
        font-family: Karla !important;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 140.5%;
        color: #142d3f;
      }

      img.alert_circle {
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .account-year-wrapper {
    background: none;
    box-shadow: none;

    div.year-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #ece9f1;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 8px;

      span.year-span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #11263c;
        margin: 0px 10px;
        margin-top: 2px;
      }
    }
  }
}

//Balance Sheet Component
//these styles are relevant to BalanceSheet component
.accounting-container {
   width: 100%;
  &.stack-wrapper {
    width: 100%;

    //equivalent to .accounting-container.stack-wrapper, to increase specifity, SASS
    background: #f9f9f9;
    //width: 1364px
    border-radius: 75px;
    padding-bottom: 20px;
    padding-right: -20px;
    position: relative;

    //children
    .stack1 {
      //Debit
      display: inline-block !important;
      width: 680px;
      height: auto;
      margin-right: -5px;

      & > *:first-child {
        position: relative;
        bottom: 80px; //move it from bottom
      }

      & > *:last-child {
        margin-top: 0px;
        border-bottom-left-radius: 70px;
        margin-left: 15px;
      }

      & > *:nth-child(8) {
        margin-bottom: 25px;
        margin-top: 15px;
        margin-left: 10px;
      }

      & > *:nth-child(13) {
        margin-left: 0px;
        margin-top: 0px;
      }

      & > *:nth-child(14).divider {
        border: 1px solid #000000;
        margin: 0px auto; // to center the divider
        margin-top: 20px;
        width: 620px !important;
      }

      & > * {
        width: 660px !important;
      }

      .divider {
        margin-bottom: 10px;
      }
    }

    .stack2 {
      //Credit
      display: inline-block !important;
      width: 660px;
      height: auto;
      position: absolute;
      top: 13%;
      margin-left: 10px;

      & > *:first-child {
        position: relative;
        bottom: 80px;
      }

      & > *:nth-child(3) {
        margin-bottom: 15px;
      }

      & > *:nth-child(4) {
        margin-bottom: 25px;
        margin-top: -10px;
      }

      & > *:nth-child(5) {
        margin-bottom: 20px;
        margin-top: -10px;
      }

      & > *:nth-child(6) {
        margin-top: -15px;
      }

      & > *:nth-child(7) {
        margin-bottom: -25px;
      }

      & > *:nth-child(8) {
        margin-bottom: 15px;
        margin-top: 10px;
      }

      & > *:nth-child(10).divider {
        //divider
        border: 1px solid black;
        margin: 0px auto; // to center the divider
        width: 620px !important;
        position: relative;
        top: 207px;
      }

      & > *:last-child {
        margin-top: 205px;
        border-bottom-right-radius: 70px;
      }

      & > * {
        //all children
        width: 660px !important;
      }
    }

    .vertical-rule {
      border: 1px solid #969696;
      height: 905px !important;
      // margin-left: 5px;
      display: inline-block;
    }
  }
}

// AccountingCategory Component
.accounting-category {
  display: flex;
  justify-content: center; //equivalent to margin: 0px auto;
  padding-top: 10px;

  span {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140.5%;
    color: #34607f;
    display: inline-block;
  }
}

.stack1_res{
  border-right:1px solid #969696;

    >{
      :nth-child(1){
        background-color: #F9F9F9;
        border-top-left-radius: 75px;
      }
    }

}
.stack2_res{
  display:flex;
  flex: 1;


  >{
    :nth-child(1){
      background-color: #F9F9F9;
      border-top-right-radius: 75px;
    }
  }
}
