//Table10.js
.table10-all {
  overflow-x: hidden !important;

  .category-wrapper {
    //the width property here is so important, without it, a greyish large stroke will appear at the bottom of the table, occupying the whole width of the page
    position: relative;
    left: 264px;
    width: 300px;
  }

  .table10 {
    width: 587px;
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .head {
      background: #f4f2ff;

      .row {
        position: relative;

        .c1 {
          padding: 0;

          & > * {
            position: relative;
            left: 20px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
          }
        }

        .c2 {
          padding: 0;

          & > * {
            width: 61px;
            position: absolute;
            left: 282px;
            bottom: 13px;
          }
        }

        .c3 {
          padding: 0;

          & > * {
            width: 61px;
            position: absolute;
            left: 410px;
            bottom: 13px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .c1 {
          padding: 0px !important;

          & > * {
            position: relative;
            left: 20px;
          }
        }

        .c2 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 282px;
            bottom: 25px;
          }
        }

        .c3 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 410px;
            bottom: 12px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

//Table11.js
.table11-all {
  .category-wrapper {
    display: flex;
    justify-content: space-between;
    width: 546px;
    position: relative;
    left: 225px;
    & > *:nth-child(1),
    :nth-child(2) {
      width: 238px;
    }
  }

  .table11 {
    width: 880px;
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .head {
      background: #f4f2ff;

      .row {
        position: relative;

        .c1 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 24px;
            bottom: 15px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
          }
        }

        .c2 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 258px;
            bottom: 15px;
          }
        }

        .c3 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 379px;
            bottom: 15px;
          }
        }

        .c4 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 554px;
            bottom: 15px;
          }
        }

        .c5 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 671px;
            bottom: 15px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .c1 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 24px;
            bottom: 10px;
          }
        }

        .c2 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 257px;
            bottom: 20px;
          }
        }

        .c3 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 380px;
            bottom: 10px;
            line-height: 30px !important;
          }
        }

        .c4 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 555px;
            bottom: 20px;
          }
        }

        .c5 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 670px;
            bottom: 10px;
            line-height: 30px !important;
          }
        }
      }
    }
  }
}

//Table12.js
.table12-all {
  .category-wrapper {
    display: flex;
    justify-content: space-between;
    width: 772px;
    position: relative;
    left: 225px;

    & > * {
      width: 238px;
    }
  }

  .table12 {
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .head {
      background: #f4f2ff;

      .row {
        position: relative;

        .c1 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 24px;
            bottom: 15px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
          }
        }

        .c2 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 258px;
            bottom: 15px;
          }
        }

        .c3 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 369px;
            bottom: 15px;
          }
        }

        .c4 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 533px;
            bottom: 15px;
          }
        }

        .c5 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 640px;
            bottom: 15px;
          }
        }

        .c6 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 792px;
            bottom: 15px;
          }
        }

        .c7 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 900px;
            bottom: 15px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .c1 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 24px;
            bottom: 10px;
          }
        }

        .c2 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 257px;
            bottom: 20px;
          }
        }

        .c3 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 370px;
            bottom: 10px;
            line-height: 30px !important;
          }
        }

        .c4 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 530px;
            bottom: 20px;
          }
        }

        .c5 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 645px;
            bottom: 10px;
            line-height: 30px !important;
          }
        }

        .c6 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 790px;
            bottom: 20px;
          }
        }

        .c7 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 900px;
            bottom: 10px;
            line-height: 30px !important;
          }
        }
      }
    }
  }
}

//Table13.js

.table13-all {
  background: #f9f8ff;
  width: 1078px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;
  overflow: hidden !important;

  .category-wrapper {
    background: #fbfbfd;

    .inner {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 850px;
      // border: 2px solid;
      margin-left: 150px;

      .item1 {
        width: 184px;
      }
      .item2,
      .item3,
      .item4,
      .item5,
      .item6,
      .item7 {
        width: 101px;
        height: 45px;
      }
    }
  }

  .table13 {
    width: 1078px !important;
    max-height: 228px !important;

    .table13-head {
      width: 1078px !important;
      background: white;
      height: 45px;

      .table13-row {
        position: relative;

        .t13-c1 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 15px;
            bottom: 15px;
          }
          span {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
          }
        }
        .t13-c2 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 176px;
            bottom: 15px;
          }
        }
        .t13-c3 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 261px;
            bottom: 15px;
          }
        }
        .t13-c4 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 701px;
            bottom: 5px;
          }
        }
        .t13-c5 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 812px;
            bottom: 5px;
          }
        }
        .t13-c6 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 923px;
            bottom: 5px;
          }
        }
      }
    }
    .table13-body {
      .table13-row {
        height: 61px !important;
        position: relative;

        .t13-c1 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 20px;
            bottom: 10px;
          }
        }
        .t13-c2 {
          & > * {
            position: absolute;
            left: 210px;
            bottom: 20px;
          }
        }
        .t13-c3 {
          &.c3 {
            padding: 0px !important;

            & > * {
              position: absolute;
              left: 275px;
              bottom: 12px !important;
              margin-left: 10px !important;
              line-height: 30px;
            }
          }
        }
        .t13-c4 {
          & > * {
            position: absolute;
            left: 366px;
            bottom: 20px;
          }
        }
        .t13-c5 {
          & > * {
            position: absolute;
            left: 475px;
            bottom: 20px;
          }
        }
        .t13-c6 {
          & > * {
            position: absolute;
            left: 580px;
            bottom: 20px;
          }
        }
        .t13-c7 {
          & > * {
            position: absolute;
            left: 710px;
            bottom: 20px;
          }
        }
        .t13-c8 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 820px;
            bottom: 20px;
          }
        }
        .t13-c9 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 930px;
            bottom: 20px;
          }
        }
      }
    }
  }
}

//Table15.js
.table15 {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;
  width: 506px !important;
  height: 140px;
  margin-top: 10px;

  .head {
    .row {
      position: relative;

      .c2 {
        & > * {
          position: absolute;
          left: 246px;
          bottom: 15px;
        }
      }

      .c3 {
        & > * {
          position: absolute;
          left: 361px;
          bottom: 15px;
        }
      }
    }
  }

  .body {
    .row {
      position: relative;

      .c1 {
        & > * {
          position: absolute;
          left: 48px;
          bottom: 29px;
        }
      }

      .c2 {
        & > * {
          position: absolute;
          left: 250px;
          bottom: 25px;
          line-height: 30px;
        }
      }

      .c3 {
        & > * {
          position: absolute;
          left: 379px;
          bottom: 40px;
        }
      }
    }
  }
}

//Table16.js
.table16 {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;
  height: 140px;
  margin-top: 10px;

  .head {
    .row {
      position: relative;

      .c1 {
        & > * {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #6e6893;
          position: absolute;
          left: 24px;
          bottom: 15px;
        }
      }

      .c2 {
        & > * {
          position: absolute;
          left: 240px;
          bottom: 15px;
        }
      }

      .c3 {
        & > * {
          position: absolute;
          left: 405px;
          bottom: 15px;
        }
      }

      .c4 {
        & > * {
          position: absolute;
          left: 559px;
          bottom: 15px;
        }
      }

      .c5 {
        & > * {
          position: absolute;
          left: 733px;
          bottom: 15px;
        }
      }
    }
  }

  .body {
    .row {
      position: relative;

      .c1 {
        & > * {
          position: absolute;
          left: 24px;
          bottom: 29px;
        }
      }

      .c2 {
        & > * {
          position: absolute;
          left: 235px;
          bottom: 15px;
          line-height: 40px;
        }
      }

      .c3 {
        & > * {
          position: absolute;
          left: 410px;
          bottom: 15px;
          line-height: 40px;
        }
      }

      .c4 {
        & > * {
          position: absolute;
          left: 564px;
          bottom: 15px;
          line-height: 40px;
        }
      }

      .c5 {
        & > * {
          position: absolute;
          left: 737px;
          bottom: 45px;
        }
      }
    }
  }
}

//Table17.js
.table17-all {
  overflow-x: hidden !important;
  height: 380px;
  max-width: 1184px;
  /*  The scroll bars are achieved by using max-width property on the TableContainer Component */

  .category-wrapper {
    position: relative;
    top: 8px;

    & > * {
      //every item
      width: 200px;
      height: 45px;
      position: relative;
      left: 84px;
      top: 2px;
    }
  }

  .table17 {
    overflow-y: scroll !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px;
    margin-top: 10px;

    .head {
      .row {
        position: relative;

        .c1 {
          & > * {
            // border: 2px solid;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
            position: absolute;
            left: 24px;
            bottom: 15px;
          }
        }

        .c2 {
          & > * {
            position: absolute;
            left: 320px;
            bottom: 15px;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 406px;
            bottom: 15px;
          }
        }

        .c4 {
          & > * {
            position: absolute;
            left: 605px;
            bottom: 15px;
          }
        }

        .c5 {
          & > * {
            position: absolute;
            left: 695px;
            bottom: 15px;
          }
        }

        .c6 {
          & > * {
            position: absolute;
            left: 892px;
            bottom: 15px;
          }
        }

        .c7 {
          & > * {
            position: absolute;
            left: 980px;
            bottom: 15px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .c1 {
          & > * {
            position: absolute;
            left: 24px;
            // bottom: 29px;
          }
        }

        .c2 {
          & > * {
            position: absolute;
            left: 315px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 420px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c4 {
          & > * {
            position: absolute;
            left: 602px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c5 {
          & > * {
            position: absolute;
            left: 710px;
            line-height: 30px;
          }
        }

        .c6 {
          & > * {
            position: absolute;
            left: 892px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c7 {
          & > * {
            position: absolute;
            left: 995px;
            // bottom: 45px;
            line-height: 30px;
          }
        }
      }

      .row,
      .row1,
      .row2,
      .row3,
      .row4,
      .row5,
      .row6,
      .row7,
      .row8 {
        &:hover {
          background: #f8f9fa;
          transform: scale(1.015);
        }

        & > * {
          &:hover {
            // padding: 60px ;
          }
        }
      }
    }
  }
}

//table18.js
//Table16.js
.table18-all {
  max-width: 1310px;
  height: 350px;

  .category-wrapper {
    // border: 2px solid red;
    & > * {
      position: relative;
      left: 0px;
      top: 2px;
    }
    .wrapper-inner {
      display: flex;
      justify-content: space-between;
      width: 1100px;
      margin-left: 200px;
      // border: 2px solid red;
      // margin-left: 208px;
      .category-left-content {
        & > *:nth-child(1),
        & > *:nth-child(2),
        & > *:nth-child(3) {
          width: 142px;
        }
      }
      .category-right-content {
        margin-right: 60px;
        & > *:nth-child(1),
        & > *:nth-child(2),
        & > *:nth-child(3) {
          width: 187px;
        }
      }
    }
  }

  .table18 {
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px;
    margin-top: 10px;

    .head {
      background: #f4f2ff;

      .row {
        position: relative;

        .c1 {
          & > * {
            // border: 2px solid;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
            position: absolute;
            left: 30px;
            bottom: 15px;
          }
        }

        .c2 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 680px;
            bottom: 15px;
          }
        }

        .c3 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 762px;
            bottom: 15px;
          }
        }

        .c4 {
          padding: 0px;
          & > * {
            position: absolute;
            left: 870px;
            bottom: 15px;
          }
        }

        .c5 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 963px;
            bottom: 15px;
          }
        }

        .c6 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 1070px;
            bottom: 15px;
          }
        }

        .c7 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 1163px;
            bottom: 15px;
          }
        }

        .c8 {
          padding: 0px;
        }

        .c9 {
          padding: 0px;
        }

        .c10 {
          padding: 0px;
        }
      }
    }

    .body {
      // height: 250px ;

      .row {
        position: relative;

        .c1 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 30px;
            bottom: 10px;
          }
        }

        .c2 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 248px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c3 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 393px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c4 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 548px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c5 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 687px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c6 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 785px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c7 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 882px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c8 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 980px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c9 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 1080px;
            bottom: 10px;
            line-height: 30px;
          }
        }

        .c10 {
          padding: 0px;

          & > * {
            position: absolute;
            left: 1179px;
            bottom: 10px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

/* Bootstrap 5 grey color shades
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000; */

// table17-all
.table17-all::-webkit-scrollbar {
  // height: 8px;
  width: 11px;
}

.table17-all::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.table17-all::-webkit-scrollbar-thumb {
  background-color: #e3e5e6;
  border-radius: 30px;
  width: 300px;
}

.table17-all::-webkit-scrollbar-thumb:hover {
  background-color: #303233;
  border-radius: 30px;
  width: 300px;
  z-index: 999;
}

//ScenarioOpexSalesTable
.scenario-opex-table-container {
  width: 100%;
  overflow: hidden;

  .scenario-opex-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .scenario-opex-table-head {
      background: #f9f8ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .scenario-opex-table-body {
      .body-row {
        & > * {
          padding: 0px;
          padding-top: 0px;
        }
        & > *:nth-child(1) {
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2,
        .c3 {
          .c2-c1 {
            // padding: 0px;
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

// ScenarioOpexProdTable
.opex-prod-table-scenarios-container {
  width: 100%;
  overflow: hidden;

  .opex-prod-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .table-head {
      background: #f9f8ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .table-body {
      .body-row {
        & > * {
          padding: 0px;
          padding-top: 0px;
        }
        & > *:nth-child(1) {
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2,
        .c3 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c5 {
          width: 100px;
        }
      }
    }
  }
}
