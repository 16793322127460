.label{
  font-size: 14px !important;
  line-height: 20px !important;
}

.custom_checkbox{
  font-size: 25px !important;
  margin-bottom: 8px !important;
}

.container {
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  //border: 1px solid red;
  display: flex;
  align-items: self-end;
  text-align: left;
  font: normal normal normal 14px/17px Karla !important;
  letter-spacing: 0;
  color: #6C757D;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          background-color: #71B6F9;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          cursor: pointer;
          //background-color: #ccc;
        }
      }
    }
  }
  .checkmark {
    &:after {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
