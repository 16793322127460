// Mixins
@import "./_mixins.scss";
@import "./_variables.scss";

//it is important tu set the width and height of all buttons to be 100%, and later we can set the width and height we want inside the stylesheets of the components that use these btns

.violet-button {
  @include mixin_flex_fit_content;
  background: $my-violet !important;
  // height: 32px;
  height: 100%;
  margin: 0px 8px !important;

  .violet-button-text {
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
}

.purple-button {
  @include mixin_flex_fit_content;
  background: $my-purple !important;
  // height: 32px;
  height: 100%;
  margin: 0px 8px !important;

  .purple-button-text {
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
}

.gradient-blue-button {
  background: $my-linear-gradient1 !important;
  border-radius: 4px;
  height: 40px;
  width: 168px;
  z-index: 999 !important;

  &:hover {
    transform: scale(1.1);
    transition: ease 0.25s;
  }

  .gradient-blue-button-text {
    @include mixin_roboto_uppercase;
    font-size: 15px;
    text-align: center;
    letter-spacing: 1.5px;
    /* Gray · 80% */
    color: $my-grey1;
  }
}

.transparent-button {
  @include mixin_flex_fit_content;
  // height: 32px;
  height: 100%;
  margin: 0px 8px !important;
  background: $white !important;

  .transparent-button-text {
    @include mixin_karla_center;
    font-size: 14px;
    line-height: 20px;
    /* Gray / 600 */
    color: $my-grey2;
  }
}

.without-border-button {
  @include mixin_flex_fit_content;
  // height: 32px;
  height: 100%;
  background: $light !important;
  border-radius: 4px !important;
  margin: 0px 10px !important;

  .without-border-button-text {
    /*width changed from 32px to auto*/
    @include mixin_karla_center;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    /* Gray / 600 */
    color: $my-grey2;
  }
}

.gradient-purple-button {
  @include mixin_flex_fit_content;
  min-height: 71px;
  height: 100%;
  background: $my-linear-gradient2;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  width: 299px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;

  .gradient-purple-button-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $white;
    display: inline-block !important;
  }

  img {
    display: inline-block;
  }
}

.blue-button {
  height: 100%;
  background: $my-blue !important;
  border-radius: 5px !important;

  &:hover {
    transform: scale(1.06);
    opacity: 0.9;
    transition: 0.05s ease;
  }

  .blue-button-text {
    @include mixin_roboto_uppercase;
    letter-spacing: 0.05em;
    height: 18px;
    font-size: 14px;
    color: $white;
  }
}

.back-button {
  border: none !important;
  background: $white !important;
  height: 100%;

  .back-button-text {
    @include mixin_karla_center;
    font-size: 14px;
    line-height: 20px;
    color: $my-purple !important;
    text-transform: initial;
  }
}

.gray-button1 {
  width: 144px;
  height: 45px;
  background: $light2 !important;
  border-radius: 5px !important;
  padding: 14px 42px;

  &:hover {
    transform: scale(1.06);
    opacity: 0.9;
    transition: 0.05s ease;
  }

  .gray-button1-text {
    @include mixin_roboto_uppercase;
    color: $my-grey1 !important;
    font-size: 14px !important;
    height: 16px;
    letter-spacing: 0.05em !important;
  }
}

.gray-button2 {
  width: 96px;
  height: 40px;
  background: $light2 !important;
  border-radius: 5px !important;

  &:hover {
    transform: scale(1.06);
    opacity: 0.9;
    transition: 0.05s ease;
  }

  .gray-button2-text {
    @include mixin_roboto_uppercase;
    color: $my-grey1 !important;
    font-size: 14px !important;
    height: 16px;
    letter-spacing: 0.05em !important;
  }
}

.light-gray-button {
  background: $light-grey !important;
  border-radius: 5px !important;
  display: inline-block !important;

  .light-gray-button-text.light-gray-button-text {
    @include mixin_roboto_uppercase;
    color: $my-grey1 !important;
    font-size: 14px;
    height: 16px;
    letter-spacing: 0.05em !important;
  }
}

.red-button {
  width: 100%;
  height: 100%;
  background: $my-red !important;
  border-radius: 5px !important;

  &:hover {
    transform: scale(1.06);
    opacity: 0.9;
    transition: 0.05s ease;
  }

  .red-button-text {
    @include mixin_roboto_uppercase;
    height: 18px;
    font-size: 14px;
    letter-spacing: 0.05em !important;
    color: $white;
  }
}

.decision-button {
  width: 214px;
  height: 50px;
  background: $my-linear-gradient3;
  border-radius: 20px !important;

  &:hover {
    transform: scale(1.1);
    transition: ease 0.25s;
  }

  .decision-button-text {
    // height: 24px;
    @include mixin_roboto_uppercase;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $my-grey1;
  }
}

.round-choice-button {
  background: $white;
  border: 1px solid $light2 !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  height: 100%;

  &:hover {
    transform: scale(1.05);
  }

  .round-choice-button-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: flex-end !important;
    text-align: right !important;
    letter-spacing: 0.15px;
    color: $my-grey1;
  }
}

.help-button {
  display: flex !important;
  justify-content: space-evenly !important;
  flex-direction: row;
  width: 100%;
  max-width: 96px !important;
  background: $light3 !important;
  border-radius: 10px !important;
  height: 40px !important;

  .help-button-text {
    display: inline-block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: $my-grey1;
    text-transform: initial;
    margin-right: 15px;
  }

  img {
    display: inline-block;
  }
}

.light-green-button {
  width: 96px;
  height: 40px !important;
  height: 100%;
  background: $light-green !important;
  border-radius: 5px !important;

  &:hover {
    transform: scale(1.16);
    opacity: 0.9;
    transition: 0.05s ease;
  }

  .light-green-button-text {
    @include mixin_roboto_uppercase;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: $my-grey1;
  }
}

.decision-category-button {
  background: $white !important;
  box-shadow: $box-shadow3 !important;
  border-radius: 15px !important;
  height: 36px;
  width: 86px;

  .decision-category-button-text {
    @include mixin_karla_center;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.018em;
    color: $my-grey3;
  }
}

.decision-category-button-blue {
  background: #2d9cdb !important;
  box-shadow: $box-shadow3 !important;
  border-radius: 15px !important;
  height: 36px;
  width: 86px;

  .decision-category-button-text {
    @include mixin_karla_center;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.018em;
    color: $white;
  }
}

.capacity-button {
  @include btn_bg_color($bg: $my-blue, $text_color: $white);
  // Not used
}

.remuneration-button {
  background: $white !important;
  box-shadow: $box-shadow1;
  border-radius: 15px !important;
  height: 100%;

  .remuneration-button-text {
    @include mixin_karla_center;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.018em;
    color: $my-grey3 !important;
    text-transform: none;
  }
}

.gradient-green-button {
  // width: 100%;
  min-height: 60px; //this solves the prb of the height
  height: 100%;
  background: $my-linear-gradient6 !important;
  // background: $my-linear-gradient4 ;
  //gradient6 left on right , grad4 left on right
  border-radius: 20px !important;
  padding: 15px 35px !important;

  .gradient-green-button-text {
    @include mixin_roboto_uppercase;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $my-grey1 !important;
  }

  img {
    margin-left: 5px; // added 3 fevrier 2022
  }
}

.next-round-button {
  background: $my-linear-gradient5 !important;
  box-shadow: $box-shadow4;
  border-radius: 5px !important;
  height: 100%;
  width: 299px;
  height: 71px;

  .next-round-button-text {
    @include mixin_roboto_uppercase;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: $my-dark-blue !important;
  }

  img {
    margin-left: 25px;
  }
}

.home-button {
  width: 200px;
  height: 32px;
  // height: 100%;
  background: $white !important;
  border: none;
  box-shadow: none !important;

  .home-button-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.44px;
    color: $my-grey4 !important;
    text-transform: initial;
    margin-left: 10px;
  }
}

.magenta {
  background: #bb6bd9;
}

.results-btn-container {
  // background: $my-magenta ;
  box-shadow: $box-shadow1 !important;
  border-radius: 15px !important;
  height: 100%;
  padding: 5px 63px 5px 63px !important;

  &:hover {
    transform: scale(1.04);
    transition: ease 0.25s;
  }

  .results-button-text {
    @include mixin_karla_center;
    font-size: 20px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.018em;
    // color: $white;
    text-transform: capitalize;
    margin-left: 12px;
  }
}

.inputsBtnMagenta {
  background: $my-magenta !important;
  box-shadow: $box-shadow2 !important;
  border-radius: 15px !important;
  height: 100%;
  padding: 5px 63px 5px 63px !important;

  &:hover {
    transform: scale(1.04);
    transition: ease 0.25s;
  }

  .inputs-button-text {
    @include mixin_karla_center;
    font-size: 20px;
    font-weight: 700;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.018em;
    color: $white;
    text-transform: capitalize;
    margin-left: 12px;
  }

  img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
}

.inputs-btn-container {
  // background: $white;
  box-shadow: $box-shadow2 !important;
  border-radius: 15px !important;
  height: 100%;
  padding: 5px 63px 5px 63px !important;

  &:hover {
    transform: scale(1.04);
    transition: ease 0.25s;
  }

  .inputs-button-text {
    @include mixin_karla_center;
    font-size: 20px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.018em;
    // color: $my-grey3;
    text-transform: capitalize;
    margin-left: 12px;
  }

  img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
}

.inputsBtnBlue {
  background: blue;
  box-shadow: $box-shadow2;
  border-radius: 15px;
  height: 100%;
  padding: 5px 63px 5px 63px;
}

.close_btn {
  display: inline-block;

  .button {
    &:hover {
      transform: scale(1.4);
      transition: 0.15s ease;
    }
  }
}

.random-button {
  background: #4777a7;
  box-shadow: 0px 1px 2px rgba(27, 78, 163, 0.24),
    0px 2px 4px rgba(41, 121, 255, 0.24);
  border-radius: 15px;

  background-color: #4777a7 !important;
  /* Shadow Blue / 2dp */

  box-shadow: 0px 1px 2px rgba(27, 78, 163, 0.24),
    0px 2px 4px rgba(41, 121, 255, 0.24);
  border-radius: 15px !important;

  .random-button-text {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 143%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.018em;
    color: #ffffff;
    text-transform: initial;
    margin-left: 8px;
  }
}

#start-btn {
  width: 144px;
  height: 36px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .start-button-container {
    border-radius: 10px !important;

    .start-button-text {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
      position: relative;
      bottom: 5px;
    }
  }
}


.video{
  width: 600px;
  aspect-ratio: 16/9;

}