.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  //pointer-events: none;
  z-index: 5000;



  .modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    background-color: #fff;

    transition: all 0.3s ease-in-out;
    padding: 15px;

  }

  .modal_header,
  .modal_footer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    z-index: 2;
    .next_btn{
      background: #1DA4BE;
      width: 74px;
      height: 40px;
      text-align: center;
      font: normal normal bold 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #FFFFFF;
      border: 0;
      border-radius: 4px;
      margin-left: auto;
    }
    //button{
    //  border: 0;
    //  border-radius: 4px;
    //  padding: 10px 15px ;
    //  font: normal normal bold 14px/17px Karla;
    //  letter-spacing: 0.28px;
    //  color: #FFFFFF;
    //  display: flex;
    //  align-items: center;
    //  cursor: pointer;
    //
    //}
  }

  .modal_title {
    margin: 0;
  }

  .modal_body {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    //background-color: #91a87d;
    .msg{
     //background-color: #2D9CDB;
      h3{
        font: normal normal normal 25px/41px Karla;
        letter-spacing: 0.5px;
        color: #343A40;
        text-transform: uppercase;
      }
    }
  }

}
