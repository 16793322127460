//------------
.dayOneModalPV-direct {
  position: relative;
  width: 90%;
  height: 56vh;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  // background-color: #c1d322;
  .content-notification-mixte {
    display: flex;
    flex-direction: column;
    flex: 1;
    // background-color: #22d389;

    .row-notification-mixte {
      display: flex;
      // flex-direction: row;
      // padding: 30px;
      .item-notification-mixte {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-notification-mixte-2 {
    // background-color: #5722d3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1.2;
    div {
      flex: 1;
      display: flex;
      justify-content: center;
      .img-rot {
        transform: rotate(180deg);
      }
    }

    .row-notification-mixte {
      display: flex;

      .item-notification-mixte {
        flex: 1;
      }
    }
  }

  .block-select {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    align-content: center;
    margin-top: 16px;
    .icon {
      display: inline-block;
      border: 3px solid;
      width: fit-content;
      text-align: center;
      align-self: center;
      color: #bfe0ff;
      padding: 0px 5px;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .title-ss {
    display: flex;
    background: #71b6f9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 0px;
    margin-top: 36px;
    margin-bottom: 20px;
    flex-direction: column;
    flex: 1 !important;
    h3 {
      text-align: center;
      font: normal normal bold 18px/17px Karla;
      letter-spacing: 0.36px;
      color: #ffffff;
      margin-top: 16px;
      margin-left: 6px;
    }
    img {
      width: 48px;
      height: 42px;
    }
  }
  .title {
    text-align: left;
    font: normal normal bold 18px/17px Karla;
    letter-spacing: 0.36px;
    color: #3f4351;
    opacity: 1;
    i {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.36px;
      color: #343a40;
      opacity: 1;
      // border: 2px solid;
      // border-radius: 15%;
      padding: 2px 3px;
    }
  }

  .box-btn { 
    flex: 1;
    padding: 10px;
    display: flex;
    // background-color: #ffb188;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .box-index {
    position: absolute;
    bottom: 10px;
    left: 20px;
    display: flex;

    .text-index {
      display: flex;
      flex-direction: row;
      max-width: 246px;
      align-items: baseline;
      span {
        width: 9px;
        height: 9px;
        background-color: #bfe0ff;
        border-radius: 50%;
      }
      h3 {
        text-align: left;
        font: normal normal normal 12px/17px Karla;
        letter-spacing: 0.24px;
        color: #000000;
        opacity: 1;
      }
    }
  }
}

.listcostom-select {
  flex-direction: column;
  width: 40%;
  align-self: center;
  margin: 0px auto;
}

.titleSelect {
  text-align: center;
  font: normal normal bold 12px/14px Karla;
  letter-spacing: 0.24px;
  color: #ffb188;
  opacity: 1;

  background: #ffeee5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  align-self: center;
  margin: 0px auto;
  padding: 4px 12px 12px 12px;
}
.titleSelect-blue {
  text-align: center;
  font: normal normal bold 12px/14px Karla;
  letter-spacing: 0.24px;
  color: #71b6f9;
  opacity: 1;

  background: #e5f2fe 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  align-self: center;
  margin: 0px auto;
  padding: 4px 12px 12px 12px;
}
