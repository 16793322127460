%btn-base {
  background: #1da4be;
  border: 0;
  font-family: Karla, serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 17px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  padding: 12px;
}

.dayOneModal {
  position: relative;
  width: 974px;
  height: 529px;
  //background-color: #8f5959;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  //background-image: url('../../../../assets/images/pvPharma5Game/images/component_56___1.svg');

  .block1{
    background-color: #AEDBE1;
    flex: .75;
  }
  .block2{
    flex: 2;
    background-color: #48B0F5;
    position: relative;
    overflow: hidden;
    .back{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000;
      z-index: 99;
      opacity: .66;
    }
    img{
      position: absolute;
      bottom: 0;
      height: 450px;
      width: 100%;
      right:  -15%;
    }

  }

  .control{
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    >{
      :nth-child(2){
        margin-left: 10px;
      }
    }
  }
  .btn{
    @extend %btn-base;
    font-family: Karla;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    i{
      margin-left: 10px;
    }


  }

  .btn_close{
    @extend %btn-base;
    background: #f6f7fc;
    font-family: Karla;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-align: left;
    color: #343a40;
    z-index: 1000;


  }
  & .discussMessage {

    width: 583px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    top: 10%;
    left: 33%;
    position: absolute;

    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
    padding: 10px;
    z-index: 1000;
    span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #51B9CD;
      text-transform: uppercase;
      opacity: 1;
    }
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -5%;
      top: 20%;
    }

    .audio {
      position: relative;
      top: 55px;
      left:-10px;
      width: 583px;
      //height: 63px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 4px;

      border-radius: 4px;
    }
  }



  &::after {
    content: '';
    position: absolute;
    left: 3%;
    top:9%;
    background-image: url('../../../../assets/images/pvPharma5Game/images/group_7788.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width:15em;
    height: 42em;
  }
}
