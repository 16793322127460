//RoundMenu.js
.round-menu-container {
    display: inline-block;

    .round-menu-btn {
        //Button
        height: 40px;
        width: 100%;
        // min-width: 220px;
        background: #FFFFFF;
        border: 1px solid #E3E5E6;
        box-sizing: border-box;
        border-radius: 10px;
        // border: 2px solid;

        .round-btn-inner {
            display: flex;
            width: 163px;
            align-items: center;
            justify-content: space-around;

            span {
                font-family: Quicksand;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                display: flex;
                align-items: flex-end;
                text-align: right;
                letter-spacing: 0.15px;
                color: #5E6366;
                text-transform: capitalize;
            }

            img.magnifier {
                //magnifier
                display: inline-block;
                margin-right: 4px;
            }
        }
    }

    .round-menu-menu {

        //Menu
        .round-menu-item {
            &:hover {
                background: red !important;
            }
        }
    }
}

//RoundText.js
.round-text-container {
    display: inline-block;
    // border: 2px solid;

    span {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: flex-end;
        text-align: right;
        letter-spacing: 0.15px;
        color: #5E6366;
    }
}

//do not remove this css rule, it is for the roundMenu
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPopover-paper {
    border-radius: 0px !important;
}