//SectionTitle
.home-title-container {}

.modal-title1-container {
    background: #E6E6F2;
    border-radius: 10px;
    display: flex;
    padding: 12px 100px;

    .modal-text {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #4A4AFF;
        min-width: 200px;
    }
}