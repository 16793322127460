.ranking-component-container:hover
  .ranking-right-content
  .ranking-component-item3
  > span {
  text-decoration: underline !important;
}

.ranking-kpi-container:hover > span {
  text-decoration: underline !important;
}
