//SavedScenariosCarousel.js
.saved-scenarios-carousel-container {
  //width: 1480px;
  height: 280px;
  background: #f4f2ff;
  border-radius: 10px;
  
 

  // SectionTitle.js
  & > *:nth-child(1) {
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: 25px;
  }

  .scenarios {
    padding:0px 20px ;

    .inner {
      position:relative;
      .content {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        scrollbar-width: 10px;
        position: absolute;
        left: 0;
        right: 0;
        padding-bottom:10px;
        

        :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

.MuiPopover-paper {
  // background: none !important;
  border-radius: 30px !important;
}

.carousel-part2 {
  display: inline-block;
  position: relative;
  left: 600px;
  z-index: 999 !important;

  .scenarios {
    .inner {
      display: flex;

      .div1 {
        margin-right: 60px;
      }
    }
  }
}

//scroll bar for inner
/* width */


#id1::-webkit-scrollbar {
  width: 2px;
}

/* Track */
#id1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: grey;
}

/* Handle */
#id1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: lightgray;
}

/* Handle */
#id1::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #f4f2ff;
}
