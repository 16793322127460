// @import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
}

//Student.js
.student-container {
  &:hover {
    // transform: scale(1.02);
  }

  .inner {
    width: 296px;
    padding: 0px 10px;
  }

  .student-infos {
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    min-width: 110px;

    div.infos {
      // margin-right: 15px;
      // border: 2px solid;
      justify-self: flex-end;
      align-self: flex-start;
      position: relative;
      right: 10px;

      .firstname,
      .lastname {
        text-transform: capitalize;
        font-family: "Karla";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: rgba(48, 47, 47, 0.9);
        margin-bottom: 3px;
        // margin-right: 15px;
      }
    }

    .team {
      font-family: "Karla";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #615c7e;
    }
  }

  .student-list-status {
    img.student-list-img {
      position: relative;
      right: 15px;
    }
  }
}

//StudentList.js
.teams-management-view-container {
  display: flex;
  flex-direction: row;
  // width: 1350px; // border: 2px solid;
  // margin-left: 380px;
  // padding-top: 60px;
  // padding-bottom: 500px;
}

//AddMemberBtn
.teams-section-container {
  // margin-left: 70px;
  //margin-top: -80px;
  flex: 1;
  //background-color:red;

  .top-stack {
    margin-bottom: 120px;
    display:flex;
    justify-content:space-between;
    align-items: center;
    //margin-top: 110px;
    padding: 0 20px  ;
    //background-color: #4b2c2c;
    .left-content {

      span.span-team {
        font-family: "Karla";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 28px;
        color: #11263c;
        white-space: nowrap; //this prevents a text from being printed on multiples lines
      }
    }

    .right-content {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;


      .input-wrapper {
        .input-inner {
          .input1 {
            width: 264px;
            background: #f5f5fa !important;
            box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.5),
              inset 2px 2px 4px rgba(170, 170, 204, 0.25),
              inset 5px 5px 10px rgba(170, 170, 204, 0.5),
              inset -5px -5px 10px #ffffff !important;
            padding-left: 15px;
          }
        }
      }
      .random-teams{
        margin-left: 10px;
      }
    }
  }

  .teamlist-wrapper {
    //display:none;
    //width:0px !important;
    // border: 2px solid;
    position: relative;
    bottom: 90px;

    .teamlist-animation {
      transition: 1s ease;
    }

    .teamlist {
      margin-top: -80px;
      // height: 155px;

      .logo {
        display: inline-block;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        left: 20px;
        top: 120px;
        // border: 2px solid;

        img {
          width: 60px;
          height: 60px;
        }
      }

      .team-list {
        margin-bottom: -80px;

        & > * {
          &:hover {
            background-color: #cec5c511;
          }
        }
      }
    }
  }
}

//ScrollDownText.js
.scroll-down-span {
  span.span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #828282;
    margin-top: 20px;
    white-space: nowrap;
  }
}

//StudentSelected
.student-selected-container {
  padding-top: 24px;

  img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    border-radius: 50%;
  }

  div.name {
    // border: 2px solid;
    white-space: nowrap;

    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #242424;
      //do not deletet his rule "nowrap"
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
}

//TeamList
.team-list-container {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  //max-width: 928px;
  width:100%;
  height: 155px;
  box-sizing: border-box;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;

  &:hover {
    background: rgba(245, 245, 220, 0.267);
  }

  .logo-wrapper {
    // border: 2px solid;
    //margin-left: 400px;
  }

  .students {
    //display: none;
    //background-color:red;
    margin-bottom: 10px;
    //min-width: 460px;
    //this property allows the srollbar to move a bit away from the students
    //display: inline-block;
    //box-sizing: border-box;

    //.student-selected {
    //  display: flex;
    //  flex-direction: row;
    //  overflow-x: scroll;
    //  overflow-y: hidden;
    //  //do not delete   margin-right: -125px; it allows the 4th member to be shown partially shown underneath add member tbn
    //  padding-bottom: 20px;
    //  margin-left: -40px;
    //  max-width: 450px;
    //}
  }

  .team-btns-filtered-data {
    .right-content {
      //margin-left: 202px;

      .add-members-btn {
        width: 370px;
        height: 94px;
        background: rgba(211, 211, 211, 0.05);
        border: 2px dashed #969696;
        box-sizing: border-box;
        border-radius: 10px;

        .text-add-team {
          font-family: "Karla";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #969696;
          text-transform: initial;
        }
      }
    }

    .delete-icon {
      display: flex;
      align-self: flex-start;
    }
  }

  .team-btns {
    &:hover {
      opacity: 0.9;
    }

    .left-content {
      align-items: center;
      justify-content: center;

      //pay attention to redundant classNames, for example, this btn's class was called "btn", another btn has the same classname in the project, so some unwated styles were applied here !!!
      .add-logo-btn {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        background: rgba(196, 196, 196, 0.2);
        border: 1px solid #969696;
        box-sizing: border-box;
        margin-right: -20px;
        margin-bottom: 8px;

        span.text {
          font-family: "Karla";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #969696;
        }
      }

      .input-team {
        background: rgba(196, 196, 196, 0.2);
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
        border-radius: 10px;
        width: 151px;
        padding-left: 15px;
        margin-top: 8px;
        margin-left: 25px; // position: relative;
        // top: 18px;
      }
    }

    .right-content {
      //margin-left: 202px;

      .add-members-btn {
        width: 370px;
        height: 94px;
        background: #ffffff;
        border: 2px dashed #969696;
        box-sizing: border-box;
        border-radius: 10px;

        .text-add-team {
          font-family: "Karla";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #969696;
          text-transform: initial;
        }
      }
    }
  }
}

//AddMemberTeam
.add-member-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .add-member-btn {
    width: 142px;
    height: 94px;
    background: #ffffff;
    border: 2px dashed #969696;
    border-radius: 10px;

    span.text {
      font-family: "Karla";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #969696;
      margin-left: 10px;
    }
  }
}

//these 2 mui classes solved the prbl
.MuiTextField-root {
  border-radius: 40px !important;
}

//the scrollbar
.student-list-list::-webkit-scrollbar {
  width: 10px;
}

.student-list-list::-webkit-scrollbar-track {
  // background-color: black;
  border-radius: 30px;
}

.student-list-list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: lightgray;
}

.student-list-list::-webkit-scrollbar-thumb:hover {
  background-color: white;
}

.student-selected::-webkit-scrollbar {
  height: 8px;
}

.student-selected::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.student-selected::-webkit-scrollbar-thumb {
  background-color: #e3e5e6;
  border-radius: 30px;
  width: 300px;
}

.student-selected::-webkit-scrollbar-thumb:hover {
  background-color: #303233;
  border-radius: 30px;
  width: 300px;
  z-index: 999;
}

.d_i_block {
  display: inline-block;
}

.d_block {
  display: block !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
}

.MuiSnackbarContent-message {
  font-family: Karla;
  margin: 0 auto;
}

.student-list-main-wrapper {
  div {
    span.span1 {
      margin-left: 420px;
      position: relative;
      top: 10px;
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 28px;
      color: #11263c;
      margin-left: 50px;
    }
  }

  //StudentList.js
  .student-list-container {
    background-color: rgba(196, 196, 196, 0.2);
    border: 1px solid #c6cacc;
    box-sizing: border-box;
    //box-shadow: 10px 10px 20px rgba(27, 49, 66, 0.1);
    border-radius: 50px;
    width: 339px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    height: 1040px;
    margin-top: 50px;

    // &:hover {
    //   transform: scale(1.01);
    // }

    .student-list-list {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      display: list-item;

      // border: 2px solid;
      .student-element-container {
        &:hover {
          transform: scale(1.08);
          transition: 0.15s ease;
        }
      }

      & > * {
        margin-bottom: 22px;
      }
    }
    .scroll-text {
      margin: 0 auto;
      justify-self: center;
    }
  }
}


.team_container{
    display:flex;
    //background-color: #863434;
    margin-bottom:10px;
    height: 145px;
     width: auto;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  .team_list{
    flex:5;
    display: inline-flex;
    //background-color: #4b79a1;
    position: relative;

    .team_wrapping{
     position: absolute;
      left: 0;
      right: 0;
      //top: 0px;
      //bottom: 0px;

      //border: 2px solid #341C09;
      white-space: nowrap;
      vertical-align: middle;
      overflow-x: scroll;
      height: 145px;

      .team_item{
        overflow: hidden;
        //border: 2px solid #B85B14;
        //background-color: #FC7307;
        min-width: 120px;
        padding:10px;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        margin-right: -4px;

      }
    }

  }
  .team_control{
    flex:.2;
    display: inline-flex;
    align-items: center;

    .team_control2{
      display:flex;
      flex-direction: column;
      height:100%;

      .btn_move{
        cursor:pointer;
        //background-color:red;
        i{
          font-size:45px;
          color:#838383
        }
      }
      :nth-child(2){
        margin-top:20px;

      }
    }
    .add-members-btn{
      margin-right: 10px;
      width: 142px;
      height: 94px;
      background: #FFFFFF;
      border: 2px dashed #969696;
      border-radius: 10px;
      font-family: 'Karla', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
      color: #969696;

    }
  }
}
