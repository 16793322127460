.rapport-activite {
  background: linear-gradient(#a0cad3 0%, #22a4bd 79.8%, #029ab8 100%);
  //border: 1px solid #707070;
  //opacity: 1;
  background-position: 100% 0;
  background-size: cover;
  overflow: hidden;

  display: flex;
  flex: 1;
  height: 100vh;
  position: relative;
  flex-direction: column;

  .back_image{
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url("../../../../assets/images/pvPharma5Game/images/web_1349___badges___3.svg");
    opacity: .15;
    //z-index: 1;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
    z-index: 10;
  }
  .img1_content {
    position: absolute;
    bottom: 0;
    height: 55vh;
    width: 16%;
    left: 0;
    z-index: 1;
   margin-bottom: 20px;
    .img1{
      //width: 50%;
      margin: 0;
      bottom: 0;
      height: 86%;
    }
    .img2{
      height: 86%;
      //border: 1px solid #2020c0;
      margin: 0;
      bottom: 0;
      transform: scaleX(-1);

    }

  }
  .img2_content {
    position: absolute;
    bottom: 0;
    height: 55vh;
    width: 16%;
    right: 0px;
    z-index: 1;
    //border: 1px solid red;

    .img1{
      width: 65%;
      //border: 1px solid rgba(79, 119, 86, 0.65);
      margin: 0;
      bottom: 0;
      transform: scaleX(-1);
    }
    .img2{
      width: 45%;
      //border: 1px solid #2020c0;
      margin: 0;
      bottom: 0;
    }
  }
  h4 {
    text-align: center;
    font: normal normal bold 26px/41px Karla;
    letter-spacing: 0.52px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
  .description{
    margin-top:0;
    font-family: Karla, serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-align: center;
    color: #fff;
    z-index: 100000;

  }
  .container-rap {
    flex: 1;
    display: flex;
    padding: 25px 16%;
    z-index: 20;

    .block-1-rep {
      // background-color: rgb(10, 23, 34);
      flex: 0.4;
      margin-right: 20px;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      header {
        flex: 0.1;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px 4px 0px 0px;

        h3 {
          font: normal normal bold 16px/17px Karla;
          letter-spacing: 0.32px;
          color: #3f4351;
          margin: 0px;
          margin-left: 25px;
        }
      }
      .list-days {
        flex: 1 1 auto;
        overflow-y: scroll;
        height: 0px;
        background-color: #ffffff;
        border-radius:  0px 0px 4px 4px;

        &::-webkit-scrollbar {
          width: 6.17px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #D8DBDD ;
          border-radius: 10px;
        }

        // scrollbar-color: rebeccapurple green;
        scrollbar-width: thin;

        ul {
          margin: 0px;
          padding: 0px;
          li {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #f1f5f7;
            margin: 0px;
            padding: 0px;
            &:hover {
              background-color: #f1f5f7;
              cursor: pointer;
              user-select: none;
            }
            .li-img {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 43px;
                height: 46px;
              }
            }
            .li-content {
              flex: 3;

              h5 {
                text-align: left;
                font: normal normal normal 12px/17px Roboto;
                letter-spacing: 0.24px;
                color: #98a6ad;
                opacity: 1;
                margin-bottom: 0px;
              }
              h3 {
                text-align: left;
                font: normal normal bold 15px/17px Karla;
                letter-spacing: 0.3px;
                color: #3f4351;
                opacity: 1;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
    .block-2 {
      background-color: #ffffff;
      flex: 1;
      border-radius: 4px;
      flex-direction: column;
      display: flex;
      .container-blk {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;

        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
          width: 6.17px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #D8DBDD ;
          border-radius: 10px;
        }
        scrollbar-width: thin;

        // background-color: #d68710;
      }

      header {
        border-bottom: 2px solid #f1f5f7;
        margin: 0px 50px;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: space-evenly;
        .img-block {
          //   background-color: #1795d4;
          display: flex;
          flex-direction: row;
          margin-right: 20px;

          img {
            width: 48px;
            height: 42px;
            margin-right: 10px;
          }
          .img-block-text {
            span {
              text-align: left;
              font: normal normal normal 16px/11px Karla;
              letter-spacing: 0px;
              color: #3f4351;
              opacity: 1;
            }
            h4 {
              text-align: left;
              font: normal normal bold 18px/11px Karla;
              letter-spacing: 0px;
              color: #3f4351;
              opacity: 1;
              margin: 0px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .msg-block {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 20px;
      position: absolute;
      top: 79%;
      left: -20px;
      background-color: #f8f9fa;
      -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }

  .block-btn-group {
    .block-btn-group-1 {
      background-color: #cff2e1;
      color: #10c469;
      padding: 10px 15px;
      border-radius: 25px;
      margin-right: 5px;
    }
    .block-btn-group-2 {
      background-color: #cff2e1;
      color: #10c469;
      padding: 10px 15px;
      border-radius: 25px;
      margin-right: 8px;
    }
    .block-btn-group-3 {
      background-color: #ffdede;
      color: #ff5b5b;
      padding: 10px 15px;
      border-radius: 25px;
    }
  }
}
