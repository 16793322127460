.box-2-3{
  display: flex;
  flex-direction: column;
  .step_quiz_1{

  }
  .step_quiz_btn{
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding: 20px 0;
    .step_quiz_btn_2{
      margin-left: auto;
    }
  }
}


.rapport_day_7_msg{
  width: 60%;
  height: 267px;
  overflow: hidden;
  .rapport_day_7_msg_img{
    bottom: -30rem;

  }
}


.rapport_day_7_text_pv5{
    width: 482px !important;
    &:after{
      left: -6% !important;
    }
}

.day_3_audio{
  position:absolute;
  //background: #6b3e91 0% 0% no-repeat padding-box;
  border-radius: 4px;
  bottom:-50px;
  width:  100%;
  //padding: 10px;
  margin-left: -10px;
}
