.market-competitors-table-container {
  margin-top:30px;
  margin-left: 20px;
  .market-competitors-table {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 0.5px solid lightgray;

    .head {
      background: #f4f2ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .body {
      .body-row {
        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }

        .c3 {
          .c3-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }

        .c4 {
          .c4-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }

        &.row1 {
          background-color: #f4f2ffab;
        }
      }
    }
  }
}

.indus-competitors-table-container {
  .indus-competitors-table {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 0.5px solid lightgray;
    .head {
      background: #f4f2ff;
      span{
        font-size:16px;
      }

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }

      }
    }
    .body {
      .body-row {
        &.row1 {
          background-color: #f4f2ffab;
        }
      }
    }
  }
}

//code added on 6-9-2022 , arrived here
.d_flex {
  width: 50%;
  display: flex;
  justify-content: center;
}
