.step_modal{
  width: 378px;
  height: 473px;
  border-radius: 12px;
  background: #E64532;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: Karla;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 13px;
    text-align: left;
    color: #fff;
    margin-bottom: 29px;
  }
  .score_body{
    width: 337px;
    height: 290px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .score_start{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .row_score{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .row_title{
        width: 177px;
        height: 26px;
        font-family: Karla;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: 17px;
        text-align: left;
        color: #343a40;
      }
      .row_btn{
        width: 101px;
        height: 34px;
        border-radius: 17px;
        background: #ffdede;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Karla;
        font-weight: normal;
        font-size: 10px;
        line-height: 9px;
        text-align: left;
        color: #ff5b5b;

      }

      .p1{
        font-weight: bold;
        color: rgba(0, 0, 0, 0.71);
        font-size: 17px;
      }
    }


  }

  .btn{
    border: 0;
    padding:  8px 12px;
    border-radius: 4px;
    font-family: Karla;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-align: left;
    color: #E64532;
    margin-top: 31px;
    i{
      margin-left: 5px;
    }
  }

}
