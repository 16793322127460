.info-container {
  display       : flex;
  height        : auto;
  flex-direction: column;

}

.content {
  display: flex;

  flex-direction: row;

}

.case-1 {
  display: flex;
  flex   : 1;

  justify-content: center;
  align-items    : center;
  flex-direction : column;

}

.case-2 {
  display: flex;
  flex   : 1;

  justify-content: flex-end;
  align-items    : center;

}

.case-2-inverse {
  display: flex;
  flex   : 1;

  justify-content: flex-start;
  align-items    : center;

}


.title-desc {
  align-self   : flex-start;
  margin-bottom: 20px;
  font         : 25px/29px Karla;
  font-weight  : bold;
  color        : #111137;
}

.desc {
  font : 16px/29px Karla;
  color: #111137;
  flex: 1;
}


.button-container {
  background-color: #5A97F8 !important;
  border-radius   : 4px !important;
  margin-bottom   : 10px;
  border-color    : transparent !important;
}


.button-txt {
  margin        : 0;
  text-align    : center;
  font          : 12px/17px Roboto;
  letter-spacing: 0.28px;
  color         : #FFFFFF;
  opacity       : 1;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 0;
  display: none;
}


ol{
  li{
    display: list-item;
  }
}
