//RoundClosedModal.js
.round-closed-modal-container {
    width: 100%;
    max-width: 692px;
    height: 537px;
    background: #FFFFFF;
    border-radius: 10px;

    .round-closed-modal-header {

        // the close btn inside
        >* {
            float: right;
            position: relative;
            top: 15px;
            right: 20px;
        }
    }

    .round-closed-modal-body {
        min-height: 380px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center !important;

        img {
            height: 337px;
            width: 293.6px;
        }

        span {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 28px;
            letter-spacing: 0.15px;
            text-transform: uppercase;
            color: #5E6366;
        }

    }

    .round-closed-modal-footer {
        display: flex;
        justify-content: center;

        .view-results-btn {
            margin-top: 20px;
        }
    }
}

//ViewTeamModal.js
.view-team-modal-container {
    background: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    max-width: 844px;
    height: 432px;

    .view-team-modal-header {

        .close-btn {
            float: right;
            position: relative;
            top: 10px;
            right: 10px;

        }
    }

    .view-team-modal-subheader {
        display: flex;
        margin: 0 auto;

        .view-team-modal-subheader-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width:100%;

            img {
                width: 86px;
                height: 86px;
                z-index: 999;
                position: relative;
                left: 80px;
            }

            .view-team-modal-subheader-inner {
                width: 327px;
                height: 48px;
                background: linear-gradient(90deg, rgba(209, 253, 255, 0.8) 0%, rgba(88, 153, 249, 0.8) 100%);
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                    color: #5E6366;
                }
            }
        }

    }

    .view-team-modal-body {
        display: flex !important;
        justify-content: space-around;
        align-items: center !important;
        margin-top: 30px;
    }


}

//TeamMember.js
.team-member-container {
    background: #FFFFFF;
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly;
    align-items: center;
    min-height: 250px;

    span.team-member-container-span1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #80B3FB;
        margin-bottom: 15px;

    }

    img.team-member-container-img1 {
        width: 144px;
        height: 144px;
        margin-bottom: 25px;
        border-radius: 99px;
    }

    span.team-member-container-span2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5E6366;
        margin-bottom: 15px;
    }

    img.team-member-container-img2 {
        display: inline-block;
        width: 21px;
        height: 16.6px;
    }
}

.simulation-finance-modal2-container {
    width: 831px;
    height: 487px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    top: 25%;
    left: 25%;
    // and yeah !!!!

    .header {
        display: flex;

        &>*:nth-child(1) {
            position: relative;
            left: 238px;
            margin-top: 17px;
        }

        //CloseBtn
        &>*:nth-child(2) {
            margin-left: 370px;
            position: relative;
            top: 10px;
            // right: 100px;
        }
    }

    .body {
        margin-left: 89px;
    }
}


.mui-box-modal{
    flex-direction: column;
    .confirm-modal{
        //position: relative;
        display:flex;
        flex-direction: column;
        flex: 1;
        background-color:white;

        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 648px;
        height: 537px;
        //bgcolor: 'background.paper',
        //box-shadow: 24px;
        border-radius: 10px;
        padding: 16px;


        .title{
            font-family: 'Roboto', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 28px;
            color: #5E6366;
            letter-spacing: 0.15px;

        }

        .save_btn{
            padding:0;
            display:flex;
            justify-content:center;
            align-items: center;
            width: 299px;
            height: 72px;
            background: linear-gradient(90deg, #96FBC4 0%, #F9F586 100%);
            border-radius: 20px;
            font-family: 'Roboto', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 22px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            cursor: pointer;
            margin-top:49px;
            color: #5E6366;


        }

        .input_name{
            background: rgba(227, 229, 230, 0.3);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            border: 0;
            width: 235px;
            height: 40px;
            padding-left: 19px;

            font-family: 'Roboto', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-transform: capitalize;

            &::placeholder{
                color: #C6CACC;

            }


        }

        .close-btn{
            top: 10px;
            right:20px;
            position: absolute;
            color: #5E6366;
            letter-spacing: 0.15px;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            cursor: pointer;

        }

        .btn-controller{
            margin-top:auto;
        }
        button{
            border: 0;
            padding:0;
            margin: 0;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            color: #FFFFFF;

        }
        .btn-cancel{
            width: 96px;
            height: 40px;
            background: #E3E5E6;
            border-radius: 5px;
            color:#5E6366;
            cursor: pointer;
        }
        .btn-yes{
            margin-left: 13px;
            width: 96px;
            height: 40px;
            background: #5899F9;
            border-radius: 5px;
            cursor: pointer;
        }
    }

}
