// Colors
$my-violet : #5469D4;
$my-grey1: #5E6366;
$my-grey2:#4F566B;
$my-grey3: #5A5B6A;
$my-grey4:#919699;
$my-blue: #5899F9;
$my-dark-blue: #142D3F;
$my-red: #EB5757;
$my-magenta: #BB6BD9;
$white: #FFFFFF;
$light: #F7FAFC;
$light2: #E3E5E6;
$light3: #F7F9FA;
$light-grey: #e3e5e683;
$light-green: rgba(74, 175, 5, 0.38);
$my-blue: #2D9CDB;
$my-purple: #9B51E0;


// Linear gradients
$my-linear-gradient1: linear-gradient(90deg, rgba(209, 253, 255, 0.8) 0%, rgba(88, 153, 249, 0.8) 100%);
$my-linear-gradient2: linear-gradient(147.72deg, #7652E7 0%, #53A6FC 100%);
$my-linear-gradient3: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%);
$my-linear-gradient4: linear-gradient(90deg, #D4FC79 0%, #96E6A1 100%);
$my-linear-gradient5: linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
$my-linear-gradient6 : linear-gradient(90deg, #96FBC4 0%, #F9F586 100%);

//Box shadows
$box-shadow1: 0px 1px 2px rgba(27, 78, 163, 0.24),
0px 2px 4px rgba(41, 121, 255, 0.24);
$box-shadow2 : 0px 1px 2px rgba(187, 107, 217, 0.5),
0px 2px 4px rgba(187, 107, 217, 0.5);
$box-shadow3: 0px 1px 2px rgba(27, 78, 163, 0.24),
0px 2px 4px rgba(41, 121, 255, 0.24);
$box-shadow4:0px 5px 30px rgba(0, 0, 0, 0.1);