//ScenarioComponent
.scenario-component-container {
  // display: inline-block;
  width: 180px;
  height: 155px;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  padding-bottom: 40px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 0.5px solid lightgray;

  &:hover {
    // border: 2px solid #2D9CDB;
    background-color: #2d9bdb56;
    transform: scale(1.017);
  }

  .item {
    background: none;
    box-shadow: none;

    &.item1 {
      display: inline-block !important;
      // border: 2px solid red;
      button {
        float: right;
        cursor: pointer;
        border: none;
        background: none;
        //margin-right: 5px;
        margin-top: 5px;

        //delete logo
        img.clear_icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          justify-self: flex-end;
          //margin-left: 145px;
          position: relative;
          bottom: 5px;
          &:hover {
            // width: 30px;
            // height: 30px;
            transform: scale(1.5);
          }
        }
      }
    }

    &.item2 {
      display: contents;
      justify-content: center;
      // position: relative;
      margin-top: -0.2px;
      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.4px;
        color: #11263c;
        // margin-top: 17px;
      }
    }

    &.item3 {
      // border: 2px solid blue;

      display: flex;
      justify-content: center;
      .dollar_img {
        width: 40px;
        height: 40px;
      }
      .percent_img {
        width: 40px;
        height: 40px;
      }
      img {
        display: inline-block;
        //margin-right: 20px;
      }
    }
  }
}

//shake animation
// @keyframes headShake {
//     0% {
//         transform: translateX(0) scale(1.5);
//     }

//     6.5% {
//         transform: translateX(-6px) rotateY(-9deg) scale(1.5);
//     }

//     18.5% {
//         transform: translateX(5px) rotateY(7deg) scale(1.5);
//     }

//     31.5% {
//         transform: translateX(-3px) rotateY(-5deg) scale(1.5);
//     }

//     43.5% {
//         transform: translateX(2px) rotateY(3deg) scale(1.5);
//     }

//     50% {
//         transform: translateX(0) scale(1.5);
//     }

//     100% {
//         transform: scale(1.5);
//     }
// }

// .headShake {
//     animation-timing-function: ease-in-out;
//     animation-name: headShake;
// }
