.marketProgressContainer {
    display       : flex;
    flex-direction: row;
    height        : 25rem;
}

.marketProgressRow1 {
    display       : flex;
    flex          : 3;
    flex-direction: column;
    margin-bottom : 2.25rem;
}

.priceContainer {
    flex: 2;

}

.volumesContainer {
    flex: 1
}

.marketProgressRow2 {
    display       : flex;
    flex          : 1;
    margin        : 1rem 1rem 0px 1rem;
    flex-direction: column;


}

.marketProgressRow3 {

    display       : flex;
    flex          : 1;
    margin        : 1rem 1rem 0px 1rem;
    flex-direction: column;

}


.headerTicket {
    background-color: #F4F5F9;
    color           : #313131;
    text-align      : center;
    height          : 60px;
    align-items     : center;
    justify-content : center;
    display         : flex;
    border          : 1px solid #DADEE0;
    font-size: 13px;
    font-family: Karla;
    font-weight: bold;
}


.lineTicket {
    background-color: white;
    p {
        text-align        : center;
        align-self        : center;
        margin-bottom     : 0px;
        flex              : 1;
        overflow          : hidden;
        text-overflow     : ellipsis;
        display           : -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        -webkit-box-orient: vertical;
        padding-left: 10%;
        text-align: initial;

        // text-align: left;
        font-size: 14px;
        font-family: Karla;
        color: #475466;
       
    }

    .rankGreen {

        flex: 1;

        p {
            border-radius   : 12px;
            background-color: #10C369;
            width           : 62px;
            height          : 16px;
            color           : #fff;
            font-weight     : bold;
            font-size       : 10px;
            margin          : auto;
        }
    }

    .rankRed {

        flex: 1;

        p {
            border-radius   : 12px;
            background-color: #FF4040;
            width           : 62px;
            height          : 16px;
            color           : #fff;
            font-weight     : bold;
            font-size       : 10px;
            margin          : auto;
        }
    }

    color          : #313131;
    text-align     : center;
    height         : 60px;
    align-items    : center;
    justify-content: space-around;
    display        : flex;
    border         : 1px solid #DADEE0;
}


.performanceContainer {
    display         : flex;
    flex-direction  : row;
    background-color: #F7F7F7;
    // height          : 800px;
    padding-bottom: 1rem;
    margin-top      : 1.5rem;

    .row1 {
        flex: 3;
    }

    .row2 {
        flex: 2;

        .priceChart {
            height          : 313px;
            background-color: rgb(255, 255, 255);
            margin-right    : 1rem;
        }

        .volumeChart {
            height          : 135px;
            background-color: #fff;
            margin-right    : 1rem;
            margin-top      : 1rem;
        }

        .tradeRow {
            // height        : 14.7rem;
            margin-top    : 1rem;
            margin-right  : 1rem;
            flex-direction: row;
            display       : flex;

            .statsJourney {
                flex        : 3;
                margin-right: 1rem;
            }

            .trade {
                flex            : 2;
                background-color: #fff;
                height          : 17rem;
                border          : 1px solid #DADEE0;
                padding         : 1rem;

                .tradePrice {
                    display        : flex;
                    flex-direction : row;
                    justify-content: space-between;
                    margin-top     : 1.5rem;


                    .rankGreen {

                        flex   : 1;
                        display: flex;

                        p {
                            display         : flex;
                            justify-content : space-evenly;
                            align-items     : center;
                            border-radius   : 12px;
                            background-color: #10C369;
                            width           : 72px;
                            height          : 24px;
                            color           : #fff;
                            font-weight     : bold;
                            font-size       : 13px;
                            margin          : auto;
                            text-align      : center;
                            align-self      : center;
                            justify-self    : center;
                        }
                    }
                }

                .variation {
                    font-size: 9px;
                    margin   : 0;
                    color    : #98A6B5;
                    float    : right;
                }

                .purchaseButton {
                    background-color: #04B303;
                    border-color    : #04B303;
                    width           : 100%;
                    height          : 39px;
                    margin-bottom   : 0.5rem;
                    margin-top      : 1.5rem;
                }

                .saleButton {
                    background-color: #FF4040;
                    border-color    : #FF4040;
                    width           : 100%;
                    height          : 39px;
                    margin-bottom   : 1rem;
                }

            }
        }
    }
}

.testt{
    background-color:red
}