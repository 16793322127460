.opex-mark-table-scenarios-container {
  width: 100%;
  overflow: hidden;

  .opex-mark-table {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;

    .table-head {
      background: #f9f8ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .table-body {
      .body-row {
        & > * {
          padding: 0px;
          padding-top: 0px;
        }
        & > *:nth-child(1) {
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c3 {
          width: 150px;
        }
      }
    }
  }
}

//CapexRDTable.js
.capex-rd-table-container {
  width: 100%;

  .capex-rd-table {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 210px;

    .capex-rd-head {
      background: #f4f2ff;

      .capex-head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }

    .capex-rd-body {
      .capex-rd-row {
        &.capex-rd-row1 {
          background-color: #f4f2ffab;
          .c3 {
            padding-top: 4px;
          }
        }
        & > * {
          padding: 0px;
        }
        & > *:nth-child(1) {
          //c1
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c4 {
          padding-top: 14px;
        }

        &.row2 {
          .c3 {
            .row2-stack {
              .c2-c1 {
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.cell_flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
