.profile {

  img{
    width: 42px;
    height: 42px;
  }
  span{
    margin-left: 20px;
    font-family: Karla, serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 10px;
    text-align: left;
    color: #323a46;
  }
}
