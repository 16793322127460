//SavedScenariosPage.js
.saved-scenarios-page-container {
  //margin-left: 20px;
  //margin-top: 80px;
  //border: 2px solid red;
  padding-top: 20px;
  padding-bottom: 60px;
  padding-right:15px;
  display:flex;
  flex-direction: column;
  position: relative;
  bottom: 30px;

  .saved-scenarios {
    

    .carousel {
      display: inline-block;
      padding: 0px;
    }
  }

  .results-inputs-btns {
    //margin-left: 380px;
    position: relative;
    top: 70px;
    left: 15px;
    z-index: 999 !important;

    & > *:nth-child(1) {
      margin-right: 19px;
    }
  }

  .tabbed-menu {
    background: #f9f8ff;
    display: inline-block;
    //width: 1480px;
    //padding: 10px 36px;
    padding-right: 0px;
    //padding-left: 50px;
    border-radius: 10px;
    //margin-left: 367px;
    //margin-top: -28px;
  }


}

.send-decision-btn {
  margin-bottom: 100px;
}
