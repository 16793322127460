.DayOneBackground {
  background-image: url('../../../images/StrategicGame/daysBackground.png');
  // width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: 20%;
  overflow: hidden;
  position: relative;
}

.presonDayOne {
  background-image: url('../../../images/StrategicGame/persondayone.png');
  width: 167px;
  height: 320px;
  background-position-x: 40%;
  background-size: cover;
  position: absolute;
  left: 5px;
  bottom: -90px;
}

.centerButtons {
  width: 50vw;
  height: 170px;
  position: absolute;
  left: 26%;
  top: 43%;
//  border: 1px solid #000;

  div {
    position: relative;
  height: 100%;
    span {
      background: #FC2A1F 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      // z-index: 9999;
      padding: 10px;
      border-radius: 50%;
      color: white;
      position: absolute;
      height:30px; 
      width:30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // border: 2px solid #000
    }
  }
}

.discussMessage {
  width: 590px;
  height: auto;
  position: absolute;
  bottom: 12%;
  left: 14%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 12px 16px;

  & span {
    text-align: left;
    font: normal normal bold 16px/17px Karla;
    letter-spacing: 0.32px;
    color: #71b6f9;
    text-transform: uppercase;
    opacity: 1;
  }

  & p {
    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
  }

  &::after {
    content: '';
    background-color: white;
    width: 60px;
    height: 48px;
    -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
    clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
    position: absolute;
    left: -5%;
    bottom: 20%;
  }
}

.notifyModal {
  position: relative;
  width: 380px;
  height: 415px;
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  .content-md {
    display: flex;
    flex-direction: column;
    .pack-img {
      flex: 1;
      background-color: #b4b2b2be;
    }
    .text-content {
      flex: 1;
      padding: 10px 20px;
      span {
        text-align: left;
        font: normal normal bold 16px/17px Karla;
        letter-spacing: 0.32px;
        color: #71b6f9;
        text-transform: uppercase;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #343a40;
        opacity: 1;
        margin-top: 10px;
      }
    }
  }
}
.desiModal {
  position: relative;
  width: 90%;
  height: 85vh;
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  .header {
    flex: 0.5;
  }
  .content {
    flex: 5;
    display: flex;
    // background-color: #59b85d;
    flex-direction: column;
    overflow-x: hidden;
    .content-row {
      display: flex;
      width: 100%;
      margin-top: 20px;
      flex-wrap: wrap;
      //   height: 100px;
      .item {
        // background-color: aqua;
        flex: 1 1 30%;
        border: 1px solid #e8e8e8;
        display: flex;
        padding: 15px 20px;
        margin-right: 20px;
        margin-top: 20px;
        // min-width: 273px;
        // max-width: 273px;
        flex-direction: column;
        border-radius: 4px;
        max-width: 30%;

        // width: 273px !important;

        p {
          flex: 2;
          text-align: left;
          font: normal normal normal 14px/17px Karla;
          letter-spacing: 0.28px;
          color: #343a40;
          opacity: 1;
        }
        .block {
          flex: 1;
          div {
            text-align: right;
            font: normal normal bold 20px/17px Karla;
            letter-spacing: 0.4px;
            color: #343a40;
          }
          p {
            text-align: right;
            font: normal normal normal 15px/17px Karla;
            letter-spacing: 0.3px;
            color: #b1b3b5;
          }
        }
      }
    }
  }
  .footer-m {
    flex: 0.7;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid;
    .budget-restant-pup {
      display: flex;
      flex-direction: column;
      .money-m {
        text-align: left;
        font: normal normal bold 20px/17px Karla;
        letter-spacing: 0.4px;
        color: #59b85d;
      }
      span {
        text-align: left;
        font: normal normal normal 15px/17px Karla;
        letter-spacing: 0.3px;
        color: #b1b3b5;
      }
    }
  }
}
.dayOneModal {
  position: relative;
  width: 974px;
  height: 529px;
  background-color: white;
  margin: 0 auto;
  background-image: url('../../../images/StrategicGame/bgModaldayone.png');

  & .discussMessage {
    width: 583px;
    height: 140px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    bottom: 41%;
    left: 25%;
    position: absolute;

    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
    span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71b6f9;
      text-transform: uppercase;
      opacity: 1;
    }
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -4%;
      top: 56%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url('../../../images/StrategicGame/persondayone.png');
    width: 260px;
    height: 340px;
  }
}

.budget_restant {
  position: absolute;
  right: 6%;
  bottom: 9%;

  & span {
    text-align: right;
    font: normal normal bold 20px/17px Karla;
    letter-spacing: 0.4px;
    display: block;
    color: #59b85d;
  }

  & p {
    text-align: right;
    font: normal normal normal 15px/17px Karla;
    letter-spacing: 0.3px;
    color: #000000;
  }

  & div {
    width: 173px;
    height: 40px;
    background: #71b6f9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;

    & span {
      text-align: left;
      font: normal normal bold 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #ffffff;
    }
  }
}
.budget_restant-mod {
  position: absolute;
  right: 3%;
  bottom: 6%;

  & span {
    text-align: right;
    font: normal normal bold 20px/17px Karla;
    letter-spacing: 0.4px;
    display: block;
    color: #59b85d;
  }

  & p {
    text-align: right;
    font: normal normal normal 15px/17px Karla;
    letter-spacing: 0.3px;
    color: #000000;
  }

  & div {
    width: 173px;
    height: 40px;
    background: #71b6f9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;

    & span {
      text-align: left;
      font: normal normal bold 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #ffffff;
    }
  }
}

.daysModal {
  min-width: 100%;
  background-color: transparent;

  & .modal-content {
    background-color: transparent;
  }
}

.phone-img {
  position: absolute;
  right: 28%;
  top: 25%;
  width: 80px;
  height: 80px;
  img {
    width: 80px;
  }

  i {
    background: #fc2a1f 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 10px;
    border-radius: 50%;
    color: white;
    position: absolute;
  }
}
