.menu_personnage_image{

  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -150px;
  left: 10px;
  z-index: 1;

  top: 0;

  img{
    position: relative;
    top: 5%;
    width: 50px;
    height: 500px;
  }
}

.sg-menu-item-box-img{

}

.row2 {

  position: relative;
  top: -20px;
  .title_x {
    font-family: Karla, serif;
    font-weight: normal;
    font-size: 28px;
    letter-spacing: 0.02em;
    line-height: 41px;
    text-align: center;
    color: #fff;

  }

  .title_x_2 {
    font-family: Karla, serif;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.02em;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;

  }

  .paragraph {
    display: flex;
    justify-content: center;
    width: 100%;

    span {
      width: 638px;
      height: 92px;
      font-family: Karla, serif;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 22px;
      text-align: center;
      color: #fff;
      //background-color: #2D9CDB;
    }

  }
}


.badges{

}


.badge_title{
  text-align: center;
  font: normal normal bold 38px/41px Karla;
  letter-spacing: 0.76px;
  color: #343A40;
  text-transform: uppercase;
  opacity: 1;
}

.badge_title_s{
  text-align: center;
  font: normal normal normal 13px/17px Karla;
  letter-spacing: 0.26px;
  color: #343A40 !important;
}

.classement_title{
  text-align: center;
  font: normal normal bold 26px/41px Karla;
  letter-spacing: 0.52px;
  text-transform: uppercase;
}

.classement_title_s{
  text-align: center;
  font: normal normal normal 16px/17px Karla;
  letter-spacing: 0.32px;
  color: #FFFFFF;
}



.clas_show_detail_hover_li{
  top: 30px;
  bottom: 0 !important;
  height: fit-content;
  z-index: 106;
}

.list_item_classment_pv5{
  display: flex;
  flex-direction: row;
   > {
     :nth-child(1){
       flex: 3;
     }

     :nth-child(2){
       flex: 1;
       width: 100px;
     }
     :nth-child(3){
       flex: 1;
       width: 100px;
       display: flex;
       flex-direction: row;
       >  :nth-child(2){
         //margin-right: auto;
       }
     }

   }
}

$siz : 43px;

.dropdown_menu{
  background-color: #ffffff !important;
  max-height: 35vh;
  overflow-y: scroll;
}
.menu_select_avatar{
  //background-color: red;

  .list_item{
    text-align: center;
  }
  img{
    width:$siz;
    height: $siz;
    border-radius: 50%;
    background-color: #EEEEEE;
    //padding: 2px;
    object-position: 0 3px;
  }
}
