.color-white {
  color: white !important;
}

.b-color-blue {
  background-color: #71b6f9 !important;
}

.jsjdh {
  height: 200px;
  background-color: #c22727 !important;
}

.content{
  &::-webkit-scrollbar {
    width: 6.17px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #D8DBDD ; 
    border-radius: 10px;
  }
  scrollbar-width: thin;
}

.budget_restant-mod{
  cursor: pointer;
}

.fa-bell{
  cursor: pointer;
}

