.container-day-2 {
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .box {
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-size: 36px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .box-1 {
    flex: 4;
    background-image: url('../../../../../assets/images/StrategicGame/group_5175-24.png');
    background-size: cover;
    position: relative;
    .pup {
      background-color: white;
      width: 92%;
      padding: 20px;
      border-radius: 5px;
      position: absolute;
      top: 30%;

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 20px solid #fffdfd;
        border-bottom: 20px solid #1a1d4400;
        border-left: 12px solid #9c3d3d00;
        border-right: 12px solid transparent;
        position: absolute;
        bottom: -39px;
        left: 59px;
      }
      h3 {
        font: normal normal bold 16px/17px Karla;
        //   letter-spacing: 0.5px;
        color: #71b6f9;
        text-align: left;
        text-transform: uppercase;
      }
      p {
        font: normal normal normal 14px/20px Karla;
        //   letter-spacing: 0.5px;
        color: #3f4351;
        text-align: left;
      }
    }
  }

  .box-2 {
    // background-color: blue;
    flex: 7;
    display: flex;
    flex-direction: column;
    padding: 0px;

    .box-2-1 {
      flex: 1;
      //   background-color: rgb(51, 255, 0);
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 0px 25px;

      .cat-ad {
        background: #ffea7e 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 0.5;

        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #000000;
        opacity: 1;
        padding: 10px;
      }
    }
    .box-2-2 {
      flex: 1;
      //   background-color: rgb(255, 153, 0);
      padding: 10px 60px 10px 20px;

      h3 {
        text-align: left;
        font: normal normal normal 18px/17px Karla;
        letter-spacing: 0.36px;
        color: #9f9f9f;
        text-transform: uppercase;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal bold 25px/29px Karla;
        letter-spacing: 0.5px;
        color: #3f4351;
        opacity: 1;
      }
    }
    .box-2-3 {
      flex: 10;
      //   background-color: rgb(255, 0, 170);
    }
  }

  .modal-block-1 {
    background-image: url('../../../../../assets/images/StrategicGame/group_5171.png');
    flex: 1.5 1;
    background-color: #a7dcf1;
    height: 70vh;
    background-size: contain;
    background-position-y: 100%;
    background-position-x: 35px;
    background-repeat: no-repeat;
  }
  .modal-block-2 {
    position: relative;
    background-image: url('../../../../../assets/images/StrategicGame/group_3746.png');
    flex: 4;
    background-color: #48b0f5;
    // height: 70vh;
    background-size: cover;
    background-position-y: 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    .btn-msg-can {
      position: absolute;
      z-index: 200;
      bottom: -19px;
      right: 170px;
      color: #343a40;
      background-color: #fff;
    }
    .btn-msg {
      position: absolute;
      z-index: 200;
      bottom: -19px;
      right: 59px;
      width: 100px;
    }
    .msg {
      width: 90%;
      background-color: white;
      z-index: 300;
      position: absolute;
      left: 5%;
      padding: 10px 20px;
      top: 20%;
      border-radius: 4px;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ffffff;
        position: absolute;
        top: 50px;
        left: -12px;
      }
      h3 {
        text-align: left;
        font: normal normal bold 16px/17px Karla;
        letter-spacing: 0.32px;
        color: #71b6f9;
        text-transform: uppercase;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #343a40;
        opacity: 1;
      }
    }
    // &::before {
    //   background-color: #000000;
    //   opacity: 66%;
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   z-index: 200;
    //   position: absolute;
    // }
  }
}

.dropdown-menu {
  width: 100%;
  .dropdown-item {
    white-space: initial;
    border-bottom: 1px solid #e8e8e8;
  }
}

.lable-select {
  text-align: left;
  font: normal normal normal 15px/17px Roboto;
  letter-spacing: 0.3px;
  color: #3f4351;
  opacity: 1;
}

.form-group-selecte {
  text-align: left;
  padding: 4px 7px;
  //   flex: 1;
  width: 50%;
}
