.regle-activite {
  border: 1px solid #707070;
  opacity: 1;
  display: flex;
  flex: 1;
  height: 100vh;
  position: relative;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;

    .sg-menu-item-title {
      color: #323a46;
    }
  }

  .header-rsdlk34 {
    h4 {
      color: #343a40;
      font-size: 38px;
      font-weight: bolder;
    }
    p {
      color: #343a40;
      font-size: 13px;
      // font-weight: re;
    }
  }
  h4 {
    text-align: center;
    font: normal normal bold 26px/41px Karla;
    letter-spacing: 0.52px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
  .container-rap {
    flex: 1;
    display: flex;
    padding: 25px 16%;
    z-index: 20;

    .block-1 {
      background-color: aliceblue;
      flex: 0.4;
      margin-right: 20px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      header {
        flex: 0.1;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        h3 {
          font: normal normal bold 16px/17px Karla;
          letter-spacing: 0.32px;
          color: #3f4351;
          margin: 0px;
          margin-left: 25px;
        }
      }
      .list-days {
        flex: 1 1 auto;
        overflow-y: scroll;
        height: 0px;
        background-color: #ffffff;
        ul {
          margin: 0px;
          padding: 0px;
          li {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #f1f5f7;
            margin: 0px;
            padding: 0px;
            &:hover {
              background-color: #f1f5f7;
              cursor: pointer;
              user-select: none;
            }
            .li-img {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 43px;
                height: 46px;
              }
            }
            .li-content {
              flex: 3;

              h5 {
                text-align: left;
                font: normal normal normal 12px/17px Roboto;
                letter-spacing: 0.24px;
                color: #98a6ad;
                opacity: 1;
                margin-bottom: 0px;
              }
              h3 {
                text-align: left;
                font: normal normal bold 15px/17px Karla;
                letter-spacing: 0.3px;
                color: #3f4351;
                opacity: 1;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
    .block-2 {
      background-color: #ffffff;
      flex: 1;
      border-radius: 4px;
      flex-direction: column;
      display: flex;
      .container-blk {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;

        display: flex;
        flex-direction: column;

        // background-color: #d68710;
      }

      header {
        border-bottom: 2px solid #f1f5f7;
        margin: 0px 50px;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;

        .img-block {
          //   background-color: #1795d4;
          display: flex;
          flex-direction: row;
          margin-right: 20px;

          img {
            width: 48px;
            height: 42px;
            margin-right: 10px;
          }
          .img-block-text {
            span {
              text-align: left;
              font: normal normal normal 16px/11px Karla;
              letter-spacing: 0px;
              color: #3f4351;
              opacity: 1;
            }
            h4 {
              text-align: left;
              font: normal normal bold 18px/11px Karla;
              letter-spacing: 0px;
              color: #3f4351;
              opacity: 1;
              margin: 0px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .msg-block {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 20px;
      position: absolute;
      top: 79%;
      left: -20px;
      background-color: #f8f9fa;
      -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }

  .block-btn-group {
    .block-btn-group-1 {
      background-color: #cff2e1;
      color: #10c469;
      padding: 10px;
      border-radius: 25px;
      margin-right: 5px;
    }
    .block-btn-group-2 {
      background-color: #cff2e1;
      color: #10c469;
      padding: 10px;
      border-radius: 25px;
      margin-right: 8px;
    }
    .block-btn-group-3 {
      background-color: #ffdede;
      color: #ff5b5b;
      padding: 10px;
      border-radius: 25px;
    }
  }
}
