

.container{

  .btn{
    display: flex;
    align-items: center;
    padding: 10px;
    margin:0;

    height: 42px;
    border-radius: 4px;
    background: #fdfdfd;
    border: 1px solid #b0b0b0;

    font-family: Karla, serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-align: left;
    color: #000;

    img{
      width: 25px;
      height: 19px;
      margin-right: 6px;
    }
  }

}
