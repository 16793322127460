//TotSvdScenarios.js
.small-componentt1-container {
  box-sizing: border-box;
  .total {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;

    div {
      margin-top: 20px;
      .text {
        font-family: "Karla";
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 16px;
        color: #6e6893;
      }
    }

    div {
      .nbr {
        position: relative;
        bottom: 10px;
        margin-left: 15px;
        color: rgb(43, 155, 219);
        // font-size: 26px;
        // font-weight: bold;
      }
    }
  }
}

// HomeScreenText.js
.home-screen-footer-spans-container {
  background: none;
  // border: 2px solid;

  .home-screen-footer-spans {
    margin-left: 50px;
    flex: 30%;

    span.span1 {
      display: inline-block;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 140.5%;
      color: #142d3f;
      margin-bottom: 15px;
    }

    span.span2 {
      display: inline-block;
      width: 316px;
      height: 56px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .home-tabbed-menu {
    // min-width: 600px;
    display: flex;
    justify-content: space-around;
    flex: 70%;

    > * {
      // border: 2px solid lightgrey;
      // width: 500px;
    }
  }
}
