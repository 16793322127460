@mixin mixin_karla_center {
    width: auto;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    display: flex;
}

@mixin mixin_flex_fit_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: fit-content;
}

@mixin mixin_roboto_uppercase {
    width: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}


@mixin btn_bg_color($bg, $text_color) {
    background: $bg !important;
    color: $text_color !important;
    // and we use this mixin like so :  .capacity-button {
    //     @include btn_bg_color($bg: $my-blue, $text_color:$white);
    // }
}