.daysModal {
  min-width: 100%;
  margin: 0px !important;
  .modalContent {
    background-color:transparent;
  }
 
}

.dayOneModal {
  position: relative;
  width: 382px;
  height: 492px;
  margin: 0 auto;
  background-color:rgb(255, 255, 255);
  display: flex;
  flex-direction: column;

  .block1{
    // flex: 2;
    height: 319px;
    background-image: url('../../../assets//images/StrategicGame/badge.png');
    display: flex;
    justify-content: center;
    align-items: center;
    .poligon {

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 155px;
      height: 134px;
      background: #FFEEDB;
      box-sizing: border-box;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

        }
  }
  .block2{
    flex: 1;
    display: flex;
    padding:29px;
    padding-top: 10px;
    flex-direction: column;
    justify-content:center;
    align-items: center;

    h3{
      text-align: center;
      font: normal normal bold 20px/17px Karla;
      letter-spacing: 0.4px;
      color: #FFC250;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 19px;
    }
    p{
      text-align: center;
      font: normal normal normal 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #343A40;
      opacity: 1;
    }
  }

  .discussMessage {
    width: 590px;
    height: auto;
    position: absolute;
    bottom: 40%;
    left: 25%;
    background: #3a1fb1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 12px 16px;
  
  }
}
 
