//------------
.dayOneModalCost {
  position: relative;
  width: 974px;
  height: 529px;
  background-color: white;
  margin: 0 auto;
  background-image: url('../../../assets/images/StrategicGame/group_3746.png');
  // background-size: 75%;
  background-position-x: 100%;
  background-position-y: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // background-position-y: 131%;
  .img1 {
    width: 119px;
    height: 130px;
    position: absolute;
    z-index: 100;
    left: 3%;
    top: 9%;
  }
  .img2 {
    width: 119px;
    height: 130px;

    position: absolute;
    z-index: 100;
    bottom: 20%;
    left: 3%;
  }
  .img3 {
    width: 119px;
    height: 130px;

    position: absolute;
    z-index: 100;
    right: 3%;
    top: 35%;
  }

  
  .discussMessageCustom1 {
    max-width: 583px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    top: 10%;
    left: 20%;
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;

    z-index: 100;
    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
    span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71b6f9;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 15px;
    }
    p {
      margin-top: 10px;
    }
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -5%;
      top: 35%;
    }
  }
  .discussMessageCustom3 {
    max-width: 583px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    top: 60%;
    left: 20%;
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;

    z-index: 100;
    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
    span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71b6f9;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 15px;
    }
    p {
      margin-top: 10px;
    }
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -5%;
      top: 35%;
    }
  }

  .discussMessageCustom2 {
    max-width: 583px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    right: 20%;
    top: 40%;
    position: absolute;
    padding: 10px;

    z-index: 100;
    text-align: left;
    font: normal normal normal 14px/17px Karla;
    letter-spacing: 0.28px;
    color: #343a40;
    opacity: 1;
    span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71b6f9;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 15px;
    }
    p {
      margin-top: 10px;
    }
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(0% 0%, 67% 35%, 0% 65%);
      position: absolute;
      right: -11%;
      top: 35%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    // background-image: url('../../../assets/images/StrategicGame/persondayone.png');
    width: 260px;
    height: 340px;
  }
}

//-----------
.budget_restant-mod {
  position: absolute;
  right: 3%;
  bottom: 6%;
  z-index: 100;
  & span {
    text-align: right;
    font: normal normal bold 20px/17px Karla;
    letter-spacing: 0.4px;
    display: block;
    color: #59b85d;
  }

  & p {
    text-align: right;
    font: normal normal normal 15px/17px Karla;
    letter-spacing: 0.3px;
    color: #000000;
  }

  & div {
    width: 173px;
    height: 40px;
    background: #71b6f9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;

    & span {
      text-align: left;
      font: normal normal bold 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #ffffff;
    }
  }
}

///-------------
.daysModal3 {
  min-width: 100%;
  background-color: transparent;

  & .modal-content {
    background-color: transparent;
  }
}
