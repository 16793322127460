//Table1.js
.opex-market-table-container {
  overflow-x: hidden;

  .category-wrapper {
    margin-left: 410px;
    display: inline-block;

    & > *:nth-child(1) {
      width: 600px;
    }
  }

  .opex-market-table {
    min-height: 228px;
    background: #ffffff;
    border-radius: 8px;

    .opex-market-table-head {
      background: #f4f2ffab;
      width: 587px;
    }

    .opex-market-table-body {
      background-color: white;
    }
  }
}

//Table2.js
.opex-sales-table-container {
  z-index: 999 !important;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  .category-wrapper {
    //the width property here is so important, without it, a greyish large stroke will appear at the bottom of the table, occupying the whole width of the page
    margin-left: 280px;
    display: flex;
    flex-direction: row;

    & > *:nth-child(1) {
      margin-right: 90px;
      position: relative;
      right: 45px;

      & > * {
        width: 420px;
      }
    }
    & > *:nth-child(2) {
      position: relative;
      right: 55px;

      & > * {
        width: 420px;
      }
    }
  }

  .opex-sales-table {
    width: 100%;
    height: 100%;
    min-height: 270px;
    background: white;

    .head {
      background: #f4f2ffab;

      .row {
        position: relative;

        .c1 {
          span {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
          }

          & > * {
            position: absolute;
            left: 24px;
            bottom: 15px;
          }
        }

        .c2 {
          // Move these cells relatively to the row start
          & > * {
            position: absolute;
            left: 258px;
            bottom: 15px;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 379px;
            bottom: 15px;
          }
        }

        .c4 {
          & > * {
            position: absolute;
            left: 554px;
            bottom: 15px;
          }
        }

        .c5 {
          & > * {
            position: absolute;
            left: 671px;
            bottom: 15px;
          }
        }
      }
    }

    .body {
      .body-row {
        position: relative;

        .cell1 {
          padding: 0px !important;

          & > *:nth-child(1) {
            position: absolute;
            left: 24px;
            bottom: 10px;
          }
        }

        .cell2 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 257px;
            bottom: 20px;
          }
        }

        .cell3 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 363px;
            bottom: 10px;
          }

          &.c3 {
            .popover-div {
              & > * {
                position: absolute;
                left: 0px;
                bottom: 3px;
              }
            }
          }
        }

        .cell4 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 552px;
            bottom: 20px;
          }
        }

        .cell5 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 660px;
            bottom: 10px;
          }
        }

        .cell6 {
          padding: 0px !important;

          & > * {
            position: absolute;
            left: 821px;
            bottom: 10px;
          }
        }
      }
    }
  }
}

//Table3.js
.opex-production-table-container {
  overflow-x: hidden;
  width: 100%;

  .category-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 120px;

    & > * {
      width: 340px !important;
    }

    & > *:nth-child(2) {
      position: relative;
      left: 15px;
      margin-right: 15px;
    }
    & > *:nth-child(3) {
      position: relative;
      left: 15px !important;
    }
  }

  .opex-production-table {
    width: 100%;
    background: #ffffff;

    .opex-production-table-head {
      background: #f4f2ff85;

      .row {
        position: relative;

        .c1 {
          span {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #6e6893;
            position: absolute;
            left: 24px;
            bottom: 15px;
          }
        }

        .c2 {
          & > * {
            position: absolute;
            left: 258px;
            bottom: 12.5px;
            // border: 2px solid;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 379px;
            bottom: 12.5px;
          }
        }

        .c4 {
          & > * {
            position: absolute;
            left: 533px;
            bottom: 12.5px;
          }
        }

        .c5 {
          & > * {
            position: absolute;
            left: 650px;
            bottom: 12.5px;
          }
        }

        .c6 {
          & > * {
            position: absolute;
            left: 792px;
            bottom: 12.5px;
          }
        }

        .c7 {
          & > * {
            position: absolute;
            left: 909px;
            bottom: 12.5px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .cell1 {
          & > * {
            position: absolute;
            left: 24px;
            bottom: 10px;
          }
        }

        .cell2 {
          & > * {
            position: absolute;
            left: 257px;
            bottom: 20px;
          }
        }

        .cell3 {
          & > * {
            position: absolute;
            left: 364px;
            bottom: 15px;
          }
        }

        .cell4 {
          & > * {
            position: absolute;
            left: 531px;
            bottom: 20px;
          }
        }

        .cell5 {
          & > * {
            position: absolute;
            left: 637px;
            bottom: 15px;
          }
        }

        .cell6 {
          & > * {
            position: absolute;
            left: 770px;
            bottom: 20px;
          }
        }

        .cell7 {
          & > * {
            position: absolute;
            left: 897px;
            bottom: 15px;
          }
        }

        .cell8 {
          & > * {
            position: absolute;
            left: 1028px;
            bottom: 8px;
          }
        }
      }
    }
  }
}

//Table4.js
.capex-quality-table-container {
  width: 100%;

  .capex-quality-table {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 210px;

    .capex-quality-head {
      background: #f4f2ff;

      .capex-quality-head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }

    .capex-quality-body {
      .capex-qa-row {
        &.row1 {
          background-color: #f4f2ffab;
        }
        & > * {
          padding: 0px;
        }
        & > *:nth-child(1) {
          //c1
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          width: 500px;

          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c3 {
          padding-top: 14px;
        }
      }
    }
  }
}

//Table3.js
.table3-category-wrapper {
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  right: 320px;

  & > * {
    //all children inside
    width: 238px !important;
  }
}

.MuiLinearProgress-bar {
  animation: none !important;
  // color: #25213B !important;
}

//LinearProgress.js
.linear-progress-container {
  display: inline-flex;
  //align-items: flex-start;

  div.linear-prog-wrapper {
    width: 180px;
    background: rgba(74, 175, 5, 0.41);
    border-radius: 20px;
    position: relative;

    .prog-span-wrapper {
      position: absolute;
      top: 17%;
      left: 40%;
      .linear-span {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: #ffffff !important;
        z-index: 999 !important;
      }
    }
  }
}

//Table19.js
.table19-all {
  .category-wrapper {
    position: relative;
    left: 225px;
  }

  .table19 {
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px;

    .head {
      background: #f4f2ff !important;

      .row {
        position: relative;

        .c2 {
          & > * {
            position: absolute;
            left: 260px;
            bottom: 12.5px;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 370px;
            bottom: 12.5px;
          }
        }
      }
    }

    .body {
      .row {
        position: relative;

        .c1 {
          & > * {
            position: absolute;
            left: 16px;
            bottom: 15px;
          }
        }

        .c2 {
          & > * {
            position: absolute;
            left: 271px;
            bottom: 20px;
          }
        }

        .c3 {
          & > * {
            position: absolute;
            left: 374px;
            bottom: 12px;
            line-height: 30px;
          }
        }

        &.row1 {
          .c2 {
            & > * {
              position: absolute;
              left: 261px;
              bottom: 20px;
            }
          }
        }

        &.row2 {
          .c3.cellule3 {
          }
        }

        &.row3 {
          .c3 {
          }
        }
      }
    }
  }
}

//Table20.js
.table20-all {
  // border: 2px solid;

  .category-wrapper {
    & > *:nth-child(1) {
      width: 250px;
      position: relative;
      right: 25px;
    }
  }
  .table20 {
    .body {
      .row1,
      .row2,
      .row3 {
        position: relative;
        .c1 {
          & > * {
            position: absolute;
            bottom: 10px;
          }
        }
        .c2 {
          & > * {
            position: absolute;
            top: 20px;
            // border: 2px solid;
          }
        }
      }
      .row2,
      .row3 {
        .cell2 {
          &.c2 {
            & > * {
              position: absolute;
              left: 260px;
              top: 20px;
            }
          }
        }
      }
    }
  }
}

//Table21.js
.opex-rh-table-container {
  .opex-rh-table {
    background: #ffffff;
    border-radius: 8px;

    .opex-rh-table-head {
      background: #f4f2ff;
      .opex-rh-table-head-row {
        & > * {
          padding: 0px !important;
        }
      }
    }

    .opex-rh-table-body {
      .opex-row1 {
        background: #f4f2ff68;
        max-height: 65px !important;
        & > * {
          padding: 8.5px !important;
        }
        .c2 {
          .c2-inner {
            margin-left: 22%;
          }

          .c2-item1 {
            margin-right: 50px;
          }
          .c2-item2 {
          }
        }
      }
      .opex-row2 {
        &.row3 {
          & > * {
            padding: 8.5px !important;
          }
          .row3-stack {
            & > * {
              padding: 0px !important;
            }
          }
          .c2-c1 {
            border-top: 0px !important;
            border-bottom: 0px !important;
          }
          .c2-c2 {
            border-top: 0px !important;
            border-bottom: 0px !important;
          }
        }
      }
    }
  }
}
