body {
  padding-bottom: 0px !important;
}

//NowEdgeLogo.js
.nowedge-logo-container {
  // display: inline-block;

  img {
    width: 241px;
    height: 77px;
  }
}

//HomeScreen.js
.home-screen-container {
  overflow-x: hidden;
  background: linear-gradient(
    178.48deg,
    rgba(88, 153, 249, 0.3) -128.77%,
    rgba(22, 48, 68, 0.107577) -47.57%,
    rgba(20, 45, 63, 0.102) -45.21%,
    rgba(88, 153, 249, 0.3) 97.18%
  );
  padding-bottom: 0px !important;

  .home-screen-header {
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }

  .home-screen-body {
    // width: 1400px;
    margin-top: 70px;
    // border: 2px solid;

    .home-screen-body-left-content {
      display: inline-flex;
      justify-content: space-evenly !important;
      align-items: center;
      flex-direction: column;
      // border: 2px solid;
      margin-left: 0px;

      .leadedge-logo {
        display: inline-block;
        margin-right: 120px;
        margin-bottom: 20px;
      }

      .welcome {
        display: inline-block;
      }

      span {
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 80%;
        color: #424551;
        position: relative;
        top: 30px;
      }
    }

    .home-screen-body-right-content {
      .video-wrapper {
        margin-left: 80px;
        position: relative;

        .video_control_icon_btn {
          width: 115px;
          height: 115px;
          border-radius: 50%;
          border: 1px solid #dcca87;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px;
          position: absolute;
          top: 35%;
          left: 41%;
          background: none;
        }

        video {
          display: inline-block;
        }
      }
    }
  }

  .home-screen-footer {
    width: 1140px;
    height: 190px;
    left: 151px;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    align-items: center;
    // border: 2px solid;
    margin-bottom: 169px;

    .home-screen-footer-spans {
      margin-left: 50px;
      flex: 30%;

      span.span1 {
        display: inline-block;
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 140.5%;
        color: #142d3f;
        margin-bottom: 15px;
      }

      span.span2 {
        display: inline-block;
        width: 316px;
        height: 56px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
      }
    }

    .home-tabbed-menu {
      padding-left: 15px;
      min-width: 765px;
      display: flex;
      justify-content: space-evenly;
      flex: 70%;
    }
  }
}

//SingleTab.js
.single-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  margin-right: 20px;
  // border: 2px solid red;

  .single-tab-btn {
    display: flex;
    flex-direction: column;

    svg {
      display: inline-block;
    }

    span {
      display: inline-block;
      width: 100px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 80%;
      text-align: center;
      letter-spacing: 1px;
      color: #142d3f;
      margin-top: 20px;
      text-transform: capitalize;
    }
  }
}

//HomeTabbedMenu.js
.home-tabbed-menu-container {
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;



  div.item5 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    background-color: #4777a7;
    padding: 40px 15px;
    margin-right: -10px;
    border-radius: 5px;
    transform: scaleY(1.1);

    & > *:nth-child(1) {
      position: relative;
      right: 11px;
      top: 12.5px;
    }

    .hidden-div {
      span.span {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        text-align: center;
        letter-spacing: 1px;
      }
    }
  }

  > * {
    margin-right: 10px;
    // border: 2px solid;
    box-sizing: border-box !important;
  }
}

.last-item {
  border: 2px solid red !important;
}
