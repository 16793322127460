.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  text-align: center;
}





.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
  height: initial !important;

}


.tabulator-row .tabulator-cell {
  height: initial !important;
   .rtl{
     padding-left: 13%;
   }
}

.tabulator .tabulator-tableHolder::-webkit-scrollbar {
  width: 6.17px;
}

.tabulator .tabulator-tableHolder::-webkit-scrollbar-thumb {
  background: #D8DBDD ; 
  border-radius: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}