//LogoTeamModal.js
.logo-team-modal-container {
  width: 711px;
  //height: 843px;
  background: #ffffff;
  border-radius: 10px;

  .header {
    .text {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 28px;
      color: #11263c;
      margin-left: 34px;
      margin-top: 40px;
    }

    button.close-btn {
      text-decoration: none;
      background: none;
      border: none;

      img.close-btn {
        margin-right: 25px;
        cursor: pointer;
      }
    }
  }

  .body {
    box-sizing: border-box;
    //background-color:red;
    .logos {
      box-sizing: border-box;
      height: 66vh;
      width: 500px;
      margin-top: 30px;
      overflow-y: scroll;
      overflow-x: hidden;
      // padding-left: 45px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;

      & > * {
        margin-bottom: 25px;
        cursor: pointer;
      }
    }

    .add-logo {
      margin-top: 40px;

      .btn {
        background: #ffffff;
        border: 2px dashed #969696;
        box-sizing: border-box;
        border-radius: 10px;
        // width: 135px;
        height: 50.44px;

        span.text {
          font-family: "Karla";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #969696;
          text-transform: initial;
        }
      }
    }
  }

  .select-logo-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding:20px 20px;
    .logo-modal-btns {
      margin-right: 15px;

      & > *:nth-child(1) {
        margin-right: 15px;
      }

      & > *:nth-child(2) {
        //margin-right: 15px;
      }
    }
  }
}

.logo-team-wrapper {
  width:fit-content;
  .logo-team-container {
    display: inline-block;
    box-sizing: border-box;

    button.logo-btn {
      background: none;
      border: none;
      cursor: pointer;
      box-sizing: border-box;

      //how to select multiple items with css or JS on click ??
      &:focus {
      }
    }

    div {
      span.brand {
        font-family: "Karla";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #11263c;
      }
    }
  }
}

.logo-team-list-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
  top: 10px;

  img {
    //margin-bottom: 20px;
  }

  div {
    // border: 2px solid;
    position: relative;
    //bottom: 30px;

    span.brand {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #11263c;
      // display: inline-block;
      text-align: center !important;
      white-space: nowrap;
    }
  }
}

.select-team-modal-container {
  //width: 571px;
  //height:60%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding:10px 19px;

  .msg-title{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #828282;
    text-align: center;
  }
  .header {
    //padding-top: 47px;
    //margin-left: 55px;
    //padding-bottom: 43px;
  //background:red;
    padding:10px;
    span.text {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 28px;
      color: #11263c;
    }

    .close-btn {
      cursor: pointer;
      //text-decoration: none;
      //background: none;
      //border: none;

      img.close-btn {
        margin-right: 25px;
        cursor: pointer;
        position: relative;
        bottom: 25px;
      }
    }
  }

  .body {
    //background-color: red;
    flex:1;
    margin: 20px 30px;
    .students {
      height: 500px;
      width: 430px;
      overflow-y: auto;
      //margin-bottom: 37px;
      padding-left: 10px;
      overflow-x: hidden;

      //each student
      .student-element {
        width: 400px;
        margin-bottom: 20px;

        &:hover {
          transform: initial !important;
          background: rgba(211, 211, 211, 0.445);
          cursor: pointer;
          // padding: 0px 4px;
          // border: .2px solid rgba(128, 128, 128, 0.486);
        }
      }
    }
  }

  .select-members-modal-footer {
    //background:red;
    padding:10px 0;
    display: flex;
    justify-content: flex-end;

    .modal-btns {
      //margin-top: 30px;
      margin-right: 20px;

      & > *:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}

//the logos section scrollbar
.logos::-webkit-scrollbar,
.students::-webkit-scrollbar {
  width: 10px;
}

.logos::-webkit-scrollbar-track,
.students::-webkit-scrollbar-track {
  background-color: darkgrey;
  border-radius: 30px;
}

.logos::-webkit-scrollbar-thumb,
.students::-webkit-scrollbar-thumb {
  border-radius: 20px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.685);
  }
}
