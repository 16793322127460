//styles for Round component
.button-container {
    background: none !important;
    border: none !important;

    //on hover over the round boxes
    &:hover {
        transform: scale(1.05);
    }
}

//do not put round container inside button-container class
.round-container {
    cursor:pointer;
    //height: 120px;
    display: flex;
    flex-direction: column;
    padding:5px 10px;
    background: #FFFFFF;

    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    h5{
        font-family: 'Karla', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        color: #082429;
        //line-height: 32px;
    }

    h6{
        font-family: 'Karla';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
    }


}

//styles for LineCircles component

.line-circle-container {
    margin: 0 5% ;
    background-color:white;
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: space-around;
    height:10px;
    > {
        div{
            position: relative;
            top: -10px;
        }
    }
}

//styes for the color of the circle in LineCircles.js
.green {
    fill: #4AAF05 !important;
}

.orange {
    fill: #F9C851 !important;
}

.red {
    fill: #FF5B5B !important;
}

//Rounds Component Styles
.rounds-container {
    flex:1;
    position: relative;
    //background-color: linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%) !important;
    //background: linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%);

    height: 100%;
    .inner {
        height: 100%;

        
        background: linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%);
        .logo {
            position: relative;
            //bottom: 80px;
            //left: 40px;
        }

        .rounds-container-row1 {
            display: flex !important;
            justify-content: space-evenly;
            position: relative;
            align-items: center;
            //background-color:red;

            //right: 30px;
        }

        .rounds-container-row2 {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            //align-items: center;
            //width: 1440px;
            //margin: 0 auto;
            //margin-right: 125px;
            //position: relative;
            //right: 30px;
        }

        .circle-lines {
            display: flex;
            justify-content: center;
            margin: 40px 0;
            margin-bottom: 150px;
            //margin-right: 170px;
        }

    }

    .round{
        width: 100%;
        //background-color: #2020c0;
        padding:0;
        position: fixed;
        top: 53%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);

       > {
           :nth-child(4){
               margin-bottom:20px;
           }
       }
    }
}

//for the Logo
.logo-container {
    margin: 10px 15px ;
    display:flex;
    align-items:baseline;

    //height:120px;
    .logo{
        display: inline-block;
        //width:50px;
        height:50px;
        //border: 1px solid black;
    }
    h1{
        position: relative;
        top: -13px;
        left:10px;
    }
}

.round-moderator {
    cursor: pointer;

    .round-moderator-container {
        background: none !important;
        border: none !important;

        &:focus {
            transform: scale(1.2);
        }

        //border-gray sould not be deleted here
        .round-moderator-inner,
        .border-gray {
            //width: 202px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 170px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;


            .top-content {
                padding-top: 20px;
                //padding-left: 20px;

                span.title {
                    font-family: 'Karla';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    //line-height: 32px;
                    color: #082429;

                }
            }

            .body-content {
                 display: flex;
                align-items: center;
                margin-top: 8px;
                padding-left: 20px;

                span {
                    font-family: 'Karla';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15px;
                    //line-height: 32px;
                    margin-right: 20px;
                    // margin-left: 18px;
                    // min-width: 150px;
                    white-space: nowrap !important;
                    // border: 2px solid;
                }

                img {
                    // border: 2px solid;
                }
            }

            .round-moderator-footer {
                // border: 2px solid;

                div {
                    margin-top: 12px;
                }
            }
        }
    }

    .round-icon {

        // display: none;
        .round-moderator-circle-icon {
            margin-left: 140px;
            // border: 2px solid;
            position: relative !important;
            top: 37px !important;
        }
    }
}

//border-gray sould not be deleted here
.border-gray {
    border: 2px solid rgb(150, 150, 150);
}

span.round-span2 {
    color: #FFBC1B !important;
}

span.round-span1 {
    color: #FF5B5B !important;
}

.start-btn2 {
    background: red !important;
}

.start-btn {
    background: #75AD4F !important;
}

span.round-span3 {
    color: #EB5757 !important;
}
