.center-content-t {
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(0deg, #91a87d 0%, #b7e093 100%) 0% 0%
    no-repeat padding-box;
  height: 100vh;

  .block-1 {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    // border: 1px solid;

    .parc-btnQ {
      text-align: left;
      font: normal normal bold 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #2874b3;
      opacity: 1;
    }
    .sg-menu-item-title {
      color: white;
    }
    .sg-menu-item-btn-config-p {
      z-index: 200;
      top: 57px;
    }
  }
  .block-2 {
    flex: 10;
    border: 1px solid;
    display: flex;
    flex-direction: row;

    .col-1-cen {
      flex: 1;
      background-image: url('../../../../assets/images/StrategicGame/component-grid_521.png');
      background-size: cover;
      position: relative;
    }
    .col-2-cen {
      flex: 1;
      display: flex;
      // border: 1px solid #707070;
      opacity: 1;
      flex-direction: column;
      display: flex;
      .cent-btn-control {
        display: flex;
        flex: 1;
        .cent-blockr {
          display: flex;
          flex: 1;
          height: 73px;
          justify-content: center;
          align-items: center;
          align-content: center;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
          margin-right: 25px;
          i {
            font-size: 45px;
            margin-right: 12px;
            color: #29abe2;
          }
          div {
            text-align: left;
            font: normal normal normal 15px/10px Karla;
            letter-spacing: 0px;
            color: #3f4351;
            opacity: 1;
            display: flex;
            flex-direction: column;
          }
          span {
            text-align: left;
            font: normal normal bold 17px/10px Karla;
            letter-spacing: 0px;
            color: #3f4351;
            opacity: 1;
          }
        }
      }
      .cent-title {
        flex: 1;
        h3 {
          text-align: center;
          font: normal normal bold 26px/41px Karla;
          letter-spacing: 0.52px;
          color: #ffffff;
          text-transform: uppercase;
          opacity: 1;
        }
        p {
          text-align: center;
          font: normal normal normal 16px/17px Karla;
          letter-spacing: 0.32px;
          color: #ffffff;
          opacity: 1;
        }
      }

      .cent-result {
        // display: flex;
        width: 50vw;
        flex: 4;
        margin-top: 20px;
        margin-right: 25px;
      }
    }
  }
}
