
@mixin define-font($size:14px, $family:Karla,$weight:bold , $color:#313131) {
      font-family: $family;
      font-size: $size;
      font-weight: $weight;   
      color:$color;
  }

.tbl_cnt{
    border-collapse: collapse; 
    width: 100%;
    word-break:break-all;

    tbody{
        display: block;
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ffffff;
        tr:first-child{
           
            // background-color: #82f8c7;
            // td:first-child{
            //     background-color: #bda229;
            //     border: 1px solid #313131;
            //     flex: 7;
            // }
            // td:last-child{
            //     background-color: #bda229;
            //     border: 1px solid #313131;
            //     flex: 1;
            // }

        }
        tr{
            text-align: center;
            width:100%;
            display:flex;
            td{
                @include define-font($color:#475466,$weight:normal);
                flex: 30 1 auto;

                display: flex;
                padding: 15px 10px;
                border-bottom: 1px solid #DADEE0 ;

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                }
            }
        }

    }

    thead {
        tr{
            background-color: #F4F5F9;
             text-align: center;
             width:100%;
             display:flex;
             td{
                @include define-font;
                flex: 30 1 auto;
                 display: flex;
                 padding:20px 10px;
                 flex-direction: column;
                 align-items: baseline;
                 input{
                     width: 120px;
                     
                 }

                 select{
                    width: 120px;
                    height: 27px;
                 }
             }
            }
    }
  

    }
