.MuiStepContent-transition {
  transition: none !important;
  overflow: hidden !important;
  // transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.stepper-container {
  //width: 1400px;
  // width: 1300px;
  //when we set a width lesser than 1400px, the connectors overlap on the icons!!
  //min-height: 610px;
  padding:35px 0 ;
  height: 500px;
  background-color: white;
  //margin: 0 auto;
  //margin-top: 100px;
  // border: 2px solid;
  overflow: hidden !important;
  border: 1px solid #c1c9d2;
  box-sizing: border-box;
  box-shadow: 0px 24px 50px rgba(55, 65, 81, 0.25);
  border-radius: 8px;

  .fragment1 {
    position: relative;
    top: 90px;

    span.span {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 140.5%;
      color: #142d3f;
      margin-left: 20px;
      position: relative;
      top: 20px;
    }

    .reset-btn {
      position: relative;
      bottom: 20px;
      right: 20px;

      &:hover {
        transform: scale(1.14);
        transition: 0.3s ease;
      }
    }
  }

  .fragment2 {
    //position: relative;
    //top: 70px;
    //background-color:red;
    //height:100px;

    .image{
      height:200px;
    }
    .step {
      font-family: Karla;
      font-weight: bolder;
      letter-spacing: 0.22em;
      position: relative;
      //top: 40px;
      //left: 10px;
    }

    .cancel-btn {
      position: relative;
      bottom: -15px;
      margin-left: 10px;

      & > * {
        &:hover {
          transform: scale(1.14);
          transition: 0.3s ease;
        }
      }
    }

    .box {
      // margin-left: 80px;

      .previous-next-btns {
        position: relative;
        bottom: 40px;
        margin-right: -35px;

        //prev btn
        & > :nth-child(1) {
          height: 37px;

          &:hover {
            transform: scale(1.14);
            transition: 0.3s ease;
          }
        }

        //next btn
        & > :nth-child(2) {
          height: 37px;

          &:hover {
            transform: scale(1.14);
            transition: 0.3s ease;
          }
        }
      }

      .close-btn {
        & > * {
          position: relative;
          bottom: 690px;
          //right: 12px;
        }
      }

      .skip-btn {
        // display: none;
        position: relative;
        bottom: 40px;
      }
    }
  }

  .stepper {
    //margin-top: 97px;
    //background: #f1f3f4;
    position: relative;
    //top: 70px;

    .step {
      .step-label {
        span.label {
          font-family: "Karla";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 140.5%;
          color: #142d3f;
        }
      }

      .step-content {
        // border: 2px solid;
        flex:1;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background-color:red;
        //span.description {
        //  font-family: "Work Sans";
        //  font-style: normal;
        //  font-weight: 400;
        //  font-size: 15px;
        //  line-height: 130%;
        //  color: #142d3f;
        //}

        //img {
        //  // position: relative;
        //  // left: 15%;
        //  width: 300px;
        //  height: 300px;
        //  margin: 0 auto;
        //}
      }
    }
  }
}

// when placing this css rule inside of the container, the styles arent applied
#popup {
  position: relative;
  top: 10%;
  left: 25%;
}

.MuiStepContent-root {
  // width: 100vw;
  // border: 2px solid;
}
