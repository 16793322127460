.simulation-input-popover-container {
    width: 361px;
    height: 120px;
    background: #e2dfdf36;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999 !important;
    margin-bottom: 8px;

    span.text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #1B1940;
        margin-top: 10px;
    }

    .btns {
        display: flex;
        margin-top: 10px;
        margin-left: 170px;

        div.btn1 {
            display: inline-block;
            order: 2;
        }

        //btn2
        div {
            display: inline-block;
            order: 1;
        }
    }
}

.d_none {
    display: none !important;
}

.d_block {
    display: inline-block !important;
}