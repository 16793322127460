.scenario-component-container:hover .item.item2 > span {
  font-weight: 900;
}

.scenario-component-container:hover .item.item3 > img.dollar_img {
  transform: scale(1.3);
  border: 1px solid #96e6a1;
  border-radius: 50%;
  /* transition: 0.1s ease; */
}
.scenario-component-container:hover .item.item3 > img.percent_img {
  transform: scale(1.3);
  border: 1px solid #76ABF9;
  border-radius: 50%;
}
