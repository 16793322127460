.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #F2F2F2;

  .tooltiptext_bottom {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #F2F2F2;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: -10px;
    margin-left: -60px;

    font: normal normal medium 14px/17px Roboto;
    letter-spacing: 0px;
    color: #F2F2F2;
    opacity: 1;
  
      &::after  {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 60%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #252525 transparent;
      }
  
  }

 .tooltiptext_right {
  visibility: hidden;
  width: 300px;
  background-color: #F2F2F2;
  color: #252525;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -50px;
  right: 25px;
  padding: 10px;

    &::after  {
      content: "";
      position: absolute;
      top: 35%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #F2F2F2;
    }

  }

}

.tooltip:hover .tooltiptext_bottom{
  visibility: visible;
}
.tooltip:hover  .tooltiptext_right{
  visibility: visible;
}