//TableBodyRowHeader.js
.table-header-container {
  display: inline-flex !important;

  span.title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #25213b;
    // margin-bottom: 2px;
  }

  span.subtitle {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // line-height: 16px;
    letter-spacing: 0.05em;
    color: #6e6893;
    // margin-bottom: -2px;
  }
}

//ValueTableCell.js
.table-data-value-container {
  display: inline-flex !important;
  align-items: center;

  span.span-text-value {
    display: inline-block;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 15.3px;
    line-height: 18px;
    color: #6e6893;
  }
}

//VariationTableCell.js and TDataIndicatorSpan.js
.table-data-indicator-container {
  display: inline-flex !important;

  input.table-data-indicator-input {
    display: inline-block;
    background: rgba(196, 196, 196, 0.3);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6e6893;
    padding: 5px 10px;
    border: none !important;
    text-align: center;

    &::placeholder {
      position: relative;
      left: 10px;
    }
    &:focus {
      border: 1px solid lightgray !important;
    }
    &:hover {
      border: 1px solid lightgray !important;
    }
    &:disabled {
      background-color: rgba(211, 211, 211, 0.3);
    }
  }

  span.span1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6e6893;
  }

  div.percentage {
    img {
      //indicator
      display: inline-block;
    }

    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13.5px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 3.5px;
      &.percent-symbol {
        margin-left: 1.5px;
      }
    }
  }
}

//TableHeadItem.js
.table-head-item {
  background: #e6e6f2;
  border-radius: 10px;
  padding: 0px 10px;
  display: inline-block;
  // padding: 30px;

  span.year {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #4a4aff;
  }
}

// Tablecategory
.table-category-container {
  background: #f4f2ff8d;
  border: 1px solid #8e8e8e43;
  box-sizing: border-box;
  height: 45px;

  .table-category-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 16px;

    span.category {
      font-family: Karla;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase !important;
      color: #6e6893;
      margin-right: 12px;
      text-align: center !important;
    }

    span.symbol {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #6e6893;
      // text-align: center !important;
    }
  }
}

//CancelSave btns
.cancel-save-container {
  display: inline-block;

  & > *:nth-child(1) {
    margin-right: 20px;
  }
}

//twoarrows.js
.two-arrows-container {
  display: inline-block;

  .two-arrows-btn {
  }
}

//CurveBtn.js
.curve-btn-container {
  background: rgba(132, 132, 255, 0.88);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 150px;
  z-index: 999 !important;

  &:hover {
    transform: scale(1.04);
  }

  .button {
    .curve-btn-text {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      color: #fbfbfde5;
      text-transform: initial;
    }
  }
}

.curve-btn-container-small {
  background: rgba(132, 132, 255, 0.88);
  border-radius: 10px;
  width: 90px;
  z-index: 999 !important;
  &:hover {
    transform: scale(1.04);
  }

  .button {
    .curve-btn-text {
      font-family: "Karla";
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      color: #fbfbfde5;
      text-transform: initial;
    }
  }
}

//Percent.js
.percent-container {
  display: inline-block;

  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}

//TableHeadItemText.js

.table-head-item-text {
  // width: fit-content;
  background: #e6e6f2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  span.text {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #4a4aff;
  }
}

.table-head-label {
  display: inline-block;

  span.label {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #6e6893;
  }
}

.table-head-last-item {
  background: #e6e6f2;
  border-radius: 10px;
  width: 185px;
  .last-item-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 2px;

    .last-text {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #4a4aff;
      text-align: center;
    }
  }
}
