//participant path
.ranking-component-container {
  display: flex;
  align-content: center;
  height: 105px;
  background: #ffffff;
  border-radius: 10px;
  width: 1374px;

  .ranking-left-content {
    width: 25%;
  }
  .ranking-right-content {
    width: 75%;
    padding-left: 60px;
  }
}

//moderator path
.ranking-moderator-container {
  display: flex;
  align-content: center;
  height: 93px;
  background: #ffffff;
  border-radius: 10px;
  width: 1250px;

  .ranking-moderator-left-content {
    width: 25%;
  }
  .ranking-moderator-right-content {
    width: 75%;
    // padding-left: 60px;
  }
}

//participant path
.ranking-component-brand {
  margin-left: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .ranking-component-brand-stack {
    width: 150px;
    .ranking-component-rank {
      span {
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 28px;
        color: #416ea7;
        // width: 60px;
        margin-top: 50%;
      }
    }

    .ranking-component-img {
      img {
        border-radius: 50px;
        width: 80px;
        height: 80px;
      }
    }
  }

  .ranking-component-company {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 28px;
      color: #11263c;
    }
  }
}

//participant path
.ranking-kpi-container {
  display: flex;
  align-content: center;

  .ranking-kpi-title {
    margin-bottom: 15px;
    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #11263c;
      white-space: nowrap;
    }
  }

  .ranking-kpi-value {
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #416ea7;
    }
  }
}

//moderator path
.ranking-component-moderator-brand {
  margin-left: 15px;

  .ranking-component-moderator-brand-stack {
    width: 100px;

    .ranking-component-moderator-rank {
      span {
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 28px;
        color: #416ea7;
        margin-top: 50%;
      }
    }
    .ranking-moderator-component-img {
      img {
        border-radius: 50px;
        width: 60px;
        height: 60px;
      }
    }
  }
  .ranking-component-moderator-company {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 26px;
      color: #11263c;
    }
  }
}

//moderator path
.ranking-moderator-kpi-container {
  display: flex;
  align-content: center;

  .ranking-moderator-kpi-title {
    margin-bottom: 15px;
    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 21.5px;
      line-height: 30px;
      color: #11263c;
      white-space: nowrap;
    }
  }

  .ranking-moderator-kpi-value {
    display: flex;
    justify-content: center;
    span {
      font-family: "Karla";
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 25px;
      color: #416ea7;
    }
  }
}

//styles not used
.ranking-component-header {
  span.kpi1,
  span.kpi2,
  span.kpi3,
  span.kpi4 {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #11263c;
    white-space: nowrap;
  }

  span.kpi4 {
    // margin-right: 20px;
  }
}
.ranking-component-results {
  .kpi1-result-value,
  .kpi2-result-value,
  .kpi3-result-value,
  .kpi4-result-value {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    color: #416ea7;
  }
  .kpi4-result-value {
    position: relative;
    left: 280px;
  }
}
