//Table5.js
//these styles apply to tables 6 and 5

.capex-capacity-table-container {
  width: 100%;

  .capex-capacity-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .capex-capacity-table-head {
      background: #f4f2ff;

      .capex-head-row {
        .c1 {
          padding-left: 8px;
          .hint-btnn {
            border: 0px !important;
            background: none !important;
          }
        }
        .c2,
        .c3,
        .c4,
        .c5,
        .c6,
        .c7,
        .c8 {
          margin-right: 3.5px;
        }
        & > * {
          margin-right: 8px;
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .capex-capacity-table-body {
      .row1 {
        .c7,
        .c8,
        .c6 {
          padding-top: 8.5px;
        }
      }

      .row2,
      .row3,
      .row4 {
        .data-cell {
          padding-top: 36px;
        }
        .c9 {
          padding-top: 23px;
        }
      }

      .capex-body-row {
        &.row1 {
          background-color: #f4f2ffab;
        }
        & > * {
          padding: 0px;
        }
        & > *:nth-child(1) {
          //c1
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c4 {
          padding-top: 14px;
        }
      }
    }
  }
}

// Table7.js
.finance-decision-table-container {
  width: 100%;
  
  .finance-decision-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .finance-decision-table-head {
      background: #f4f2ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
          width: 23%;
        }

        .finance-head-categ {
          .categ-div {
            margin-right: 5.5px;
          }
        }
      }
    }
    .finance-decision-table-body {
      .body-row {
        &.row1 {
          background-color: #f4f2ffab;
        }
        & > * {
          padding: 0px;
        }
        & > *:nth-child(1) {
          //c1
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c4,
        .c3,
        .c5 {
          padding-top: 14px;

          .cell_flex_center {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .row2 {
        .c5 {
          padding-top: 10px;
        }
        .c6 {
          padding-top: 12px;
        }
      }
    }
  }
}

//FinanceRemunerationTable.js
.finance-remun-table-container {
  width: 100%;
 

  .finance-remun-table {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .finance-remun-table-head {
      background: #f4f2ff;

      .head-row {
        & > * {
          padding: 0px;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
    }
    .finance-remun-table-body {
     
      .body-row {
        &.row1 {
          background-color: #f4f2ffab;
        }
        & > * {
          padding: 0px;
        }
        & > *:nth-child(1) {
          //c1
          padding: 0px;
          padding-left: 14px;
          padding-top: 14px;
        }

        .c2 {
          .c2-c1 {
            border-top: 0px;
            border-bottom: 0px;
          }
          .c2-c2 {
            border-top: 0px;
            border-bottom: 0px;
          }
        }
        .c3 {
          padding-top: 14px;
        }
      }
      .row1 {
        .c3 {
          padding-top: 14.5px;
        }
      }
      .row2 {
        .c3 {
          padding-top: 25px;
        }
        .c4 {
          padding-top: 17px;
        }
      }
    }
  }
}

//Table9.js
.table9-all {
  .table9-container {
    width: 880px;
    height: 140px;

    .table9-head {
      width: 880px;

      .table9-row {
        .c2 {
          position: relative;
          right: 10px;
        }

        .c3 {
          position: relative;
          right: 10px;
        }
      }
    }

    .table9-body {
      width: 880px;

      .row {
        .cell2 {
          position: relative;
          left: -5px;
        }

        .cell3 {
          position: relative;
          bottom: 15px;
          left: 5px;
        }

        .cell4 {
          .value {
            margin-right: -12px;
          }
        }
      }
    }
  }
}

//prop class in Table5.js, 3rd row
.blur {
  // dont delete the height here, this class controls the 3rd row of the blurred table no5
  filter: blur(2px);
  height: 61px;
}

//Percent.js span color className prop
.red {
  color: #eb5757 !important;
}

.green {
  color: #4aaf05 !important;
}

// VariationTableCell  nested div prop class
.d_none {
  display: none !important;
}

.d_block {
  display: block !important;
}
