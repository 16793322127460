.accounting-section-container {
    width: 100%;
    display: inline-block;
    background: #F9F9F9;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;

    .left-content {

        span.title {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 140.5%;
            color: #142D3F;

        }
    }

    .right-content {

        .right-content-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span.value {
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 140.5%;
                color: #353F4A;
            }

            img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
                margin-right: 5px;
            }

            span.percentage {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
            }
        }
    }
}
