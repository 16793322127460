//Pie charts
.chart1-container {
  height: 478px;
  width: 328px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;

  span.type-of-chart {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    //line-height: 132%;
    color: #333333;
    margin-left: 32px;
    position: relative;
    top: 32px;
    z-index: 999;
    // border: 1px solid red;
  }
}

.chart2-container {
  height: 478px;
  //width: 328px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;

  span.type-of-chart {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 132%;
    color: #333333;
    margin-left: 32px;
    position: relative;
    top: 32px;
    z-index: 999;
  }

  .chart {
    position: relative;
    top: -20px;
  }
}

//Chart5
.chart5-container {

  height: 425px;
  //width: 328px;
  // border-radius: 4px;
  // border: 1px solid red;
  span.type-of-chart {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 132%;
    color: #333333;
    margin-left: 32px;
    position: relative;
    top: 32px;
    z-index: 999;
  }
}

.chart5-container {
  &.chart9-container {
    height: 478px;
  }
}
.piechart8 {
  //height: 400px;
  padding-bottom:30px;

  .the-chart {
    //margin-top: 50px;
    //position: relative;
    //bottom: 20px;
    //margin-bottom: 80px;
  }
}

//column charts
// Chart1


.column-chart1-container {
  //width: 532px;
  height:374px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 10px;
  align-items: self-end;
  display: flex;

  .chart {
    display: inline-block;
  }
}

//Chart2
.column-chart2-container {
  width: 100%;
  //width: 535px;
  border-radius: 40px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  display: inline-block;
  padding: 20px;
  //background-color:red;
}

// Chart4
.column-chart4-container {
  height: 360px;
  width: 100%;
  border-radius: 40px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 10px;
  align-items: self-end;
  display: flex;
}

//chart5
.column-chart5-container {
  //width: 642px;
  height: 374px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  border-radius: 40px;
  padding: 20px 20px 20px 20px;
}

//chart6
.column-chart6-container {
  height: 332px;
  //width: 633px;
}

//HeaderChart.js
.header-chart-container {
  display: inline-block !important;

  .header-chart-inner {
    display: flex;
    //justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .header-chart-img {
      width: 23px;
      height: 23px;
      margin-right: 25px;
    }

    span.header-chart-text {
      font-family: "Karla";
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 132%;
      color: #11263c;
      white-space: nowrap;
    }
  }
}

.mixed-chart1-container {
  //width: 532px;
  height:374px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 10px;
  align-items: self-end;
  display: flex;

  .chart {
    display: inline-block;
  }
}

.mixed-chart3-container {
  //width: 1329px;
  height: 471px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 20px 0px 0px 20px;

  .chart {
    display: inline-block;
  }
}

.mixed-chart4-container {
  //width: calc(100% - 23px);
  height: 430px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding-bottom: 45px;
  padding-top: 15px;
  padding-left: 15px;
   //margin-right:30px;
  //.chart {
  //  display: inline-block;
  //}
}

.mixed-chart5-container {
  .chart {
    display: inline-block;
  }
}

.spline-chart1-container {
  //width: 535px;
  height: 364px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding-bottom: 55px;
  padding-top: 15px;
  padding-left: 15px;
}

.spline-chart3-container {
  width: 100%;
  height: 374px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 10px;
  align-items: self-end;
  display: flex
}

.spline-chart4-container {
  //width: 1260px;
  height: 430px;
  margin-left: 20px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
  padding-bottom: 0px;
  padding: 15px;
}

.area-chart1-container {
  width: 600px;
  height: 394px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px 0px 15px 15px;

  .chart {
    display: inline-block;
  }
}

.area-chart2-container {
  width: 633px;
  height: 385px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
  padding-bottom: 45px;
  padding-top: 35px;
  padding-left: 15px;
}

// .x-axis-labels {}
// https://stackoverflow.com/questions/27418104/whats-the-difference-between-displayinline-flex-and-displayflex
