
$font-k : 'Karla';
$color-text: #080E5E;

.module-list-not{
    font-family:$font-k;
    font-size: 14px;
    background-color: transparent ;
    color: #464B68
}
.module-list-not:hover{
  background-color: #D9E9F5;
  border-radius: 10px;
  color: #464B68
}


.activity-container {
    background-color: transparent !important;
    box-shadow: none !important
  }

.video-container {
    font-family:$font-k;
    font-size:16px;
    color:#6A6A8D ;
    font-weight:300;
    line-height: 1.6;
}
.video-content{
  background-color: #000000
}
.f-s-10{
    font-size: 10px;
}

.module-list::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
.module-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
   
/* Handle */
.module-list::-webkit-scrollbar-thumb {
  background: #64A7FB; 
  border-radius: 10px;
}

.module-list{
  // padding-top: 15px
}


.modules-desc-top{
  color: $color-text;
  font-family: $font-k;
  overflow-x: auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.modules-title-top{
  color: $color-text;
  
}

.modules-title-bottom{
  color: $color-text;
  
}


.modules-desc-bottom{
  color: #000000;
  ol{
  

    li{
      display:list-item;
      list-style-type:decimal;

    }
    
  }
  ul{
    li{
      display:list-item;
      list-style-type:disc;
    }
}
  
}

.module-list_active{
  background-color: #64A7FB; 
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  
}

.module-list_active:hover{
  background-color: #64A7FB; 
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-weight: bold;
}


.bg-t{
  background-color: transparent
}



@media only screen and (max-width: 768px) {
  .controls-bar{
    flex-direction: column
  }
  .module-select{
    width: 80% !important;
  }
  .module-btn{
    width: 100%;
  }
}