.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  //pointer-events: none;
  z-index: 5000;



  .modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 95vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    overflow: scroll;
    padding: 10px 8%;

    .list{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      img{
        margin:  5px 0;
      }
    }
    .paragraph{
      font-family: Karla;
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.02em;
      line-height: 17px;
      color: #3f4351;

    }
    .block1{
      display: flex;
      flex-direction: row;
      img{
        margin-right: 5px;
      }
      h3{
        font-family: Karla, serif;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 17px;
        text-align: left;
        color: #3f4351;

      }
    }
    .block3{
      border-radius: 4px;
      background: #1da4be;
      padding: 10px 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img{
        width: 48.07px;
        height: 42.17px;
        margin-right: 39px;
      }
      h4{
        font-family: Karla, serif;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 17px;
        text-align: center;
        color: #fff;
      }
    }

  }

  .modal_header,
  .modal_footer {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    z-index: 2;
    .next_btn{
      background: #1DA4BE;
      padding: 0 30px;
    }
    .prv_btn{
      margin-right: 27px;
      margin-left: auto;

      background: #6C757D;
    }
   >{
    :nth-child(1) {
      margin-right: 27px;
      margin-left: auto;

      //background: #6C757D;
    }

    //:nth-child(2) {
    //  background: #1DA4BE;
    //}
  }
    button{
      border: 0;
      border-radius: 4px;
      padding: 10px 15px ;
      font: normal normal bold 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        margin-left: 10px;
      }
    }
  }

  .modal_title {
    margin: 0;
  }

  .modal_body {
    flex: 1;
    padding: 10px;
    position: relative;

    .msg{
      position: absolute;
      top: 20%;
      left: 62%;
      transform: translateX(-55%);
      width: 65%;
      padding: 16px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4px;

      ::after{
        position: absolute;
        top: 25%;
        left: -20px;
        content: '';
        width: 0;
        height: 0;
        border-right: solid 30px rgba(255, 255, 255);
        border-bottom: solid 15px transparent;
        border-top: solid 15px transparent;

      }
      h3{
        text-align: left;
        font: normal normal bold 16px/17px Karla;
        letter-spacing: 0.32px;
        color: #1DA4BE;
        text-transform: uppercase;
      }
      p{
        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #343A40;
      }
    }
  }

}
