.day-3-content {
  display: flex;
  background-color: #ebeff2;
  // height: 100vh;
  flex-direction: column;
  padding: 0px 70px;

  .box-2-1_dy_3 {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top : 10px;

    .cat-ad {
      background: #ffea7e 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 0.5;

      text-align: left;
      font: normal normal normal 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #000000;
      opacity: 1;
      padding: 10px;
      
    }
    
  }
  .box-2-1_dy_3_f {
    // flex: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    // align-items: center;
    // align-content: center;
    // padding: 0px 25px;
    
    
  }
  .box-2-2 {
    flex: 1;
    
    padding: 10px 60px 0px 0px;

    h3 {
      text-align: left;
      font: normal normal normal 18px/17px Karla;
      letter-spacing: 0.36px;
      color: #9f9f9f;
      text-transform: uppercase;
      opacity: 1;
    }
    p {
      text-align: left;
      font: normal normal bold 25px/29px Karla;
      letter-spacing: 0.5px;
      color: #3f4351;
      opacity: 1;
    }
  }
  .box-2-3 {
    // flex: 1;
    display: flex;
    // max-height: 80%;
    // overflow-y: auto;
    // border: 1px solid;
    .block-1_day3 {
      flex: 0.7;
      // background-color: rgb(255, 0, 170);

      height: 72vh;
      overflow-y: scroll;
      border: 1px solid #ced3d9;
      scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent; /* Chrome/Safari/Webkit */
        }
      .list-dr {
        // width: 100%;
        display: flex;
        flex-direction: column;

        // margin-left: auto;
        
        padding: 10px 10px 5px 10px;

        

        .list-dr-item {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 1px 3px #0000001a;
          border-radius: 4px;
          opacity: 1;
          padding: 20px;
          //   border: 1px solid;
          margin-bottom: 5px;
        }
        overflow-y: scroll;
        // padding: 30px 10px;
        overflow-x: scroll;
        // height: 100%;
        flex: 1;
      }
    }
    .block-2 {
      flex: 1.3;
      // background-color: rgb(255, 0, 170);

      flex-direction: row;
      display: flex;
      div {
        display: inline-block;
      }
      .block-2-1 {
        flex: 0.1;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 40px;
          height: 40px;
        }
        // background-color: rgb(0, 47, 255);
      }
      .block-2-2 {
        display: flex;
        flex: 1;
        // background-color: rgb(208, 255, 0);
        flex-direction: column;
        padding-top: 15px;

        .block-2-2row {
          display: flex;
          flex: 1;
        }
        .drag-block {
          flex: 1;
            // border: 1px solid;
          margin: 0px;
          margin-right: 5px;
          display: flex;
          flex-direction: column;

          .body {

            background-color: #f5f5f5;
            border: 2px dashed #CED3D9;
            max-height: 229px ;
            min-height: 229px ;
            overflow-y: scroll;
            overflow-x: hidden;

            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* IE 10+ */
            &::-webkit-scrollbar {
              width: 0px;
              background: transparent; /* Chrome/Safari/Webkit */
            }
            
            .item {
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 1px 3px #0000001a;
              border-radius: 4px;
              opacity: 1;

              text-align: left;
              font: normal normal normal 15px/17px Karla;
              letter-spacing: 0.3px;
              color: #3f4351;
              // margin: 5px;
              max-height:  32px;
              text-overflow: ellipsis;
              // width: 200px !important; 
              white-space: nowrap; 
              margin: 2px 5px;

             

              p{
              // text-align: center;
              padding-top: 5px;
              padding-left: 5px;
              white-space: initial;
              overflow-wrap: break-word;
              word-break: break-word;
              word-break: break-word;
              hyphens: auto;
              overflow: hidden;
              margin-bottom: 11px;
              }
            }
          }
          div {
            flex: 5;
            display: flex;
            flex-direction: column;
            background-color: rgb(255, 255, 255);
          }
          .title {
            flex: 0.13;
            text-align: left;
            font: normal normal bold 16px/17px Karla;
            letter-spacing: 0.32px;
            color: #3f4351;
            opacity: 1;
            padding-right: 5px;
          }
        }
      }
    }
  }
}
