//HomeAnalysisHeader.js styles
.home-analysis-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 64px;

    //right content
    .logo {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .logo-btn {
            margin-left: 30px;
            background: none !important;

            img {
                display: inline-block;
                cursor: pointer !important;
                z-index: 999 !important;
            }

            // You need to change the z-index so that #firstdiv is considered on top of the other divs
        }
    }

    //left content
    .home-analysis-container-right {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        div.help-btn {
            // border: 2px solid;
            z-index: 999 !important;

            //what is inside
            >* {
                height: 40px !important;
            }
        }

        .home-analysis-container-user-name {
            display: flex;
            align-items: center;
            justify-content: center;

            width: auto;
            min-width: 150px;

            span {
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.75px;
                text-transform: uppercase;
                color: #4777A7;

            }
        }

        .home-analysis-container-user-picture {
            img {
                border-radius: 99px;
                position: relative;
            }
        }
    }
}
