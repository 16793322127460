//ScenarioTooltip1.js
.scenario-tooltip1-container {
    // background: #98DFD3;
    border-radius: 30px;
    height:  fit-content;
    width: 1200px;
    z-index: 999 !important;
    padding: 20px 20px;

    &>:nth-child(1) {
        margin-right: 20px;
        margin-left: 0px;
    }

    &>:nth-child(2) {
        margin-right: 20px;
    }

    &>:nth-child(3) {
        margin-right: 20px;
    }

    &>:nth-child(4) {
        margin-right: 0px;
    }
}