.go-to-leadedge-btn {
    //applying width and height to this container will cause the sibling elemnnts to shake, padding solves the prblm
    // width: 151px;
    // height: 190px;
    background: linear-gradient(147.72deg, #7652E7 0%, #53A6FC 100%) !important;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-sizing: border-box !important;

    &:hover {
        opacity: .9;
        transform: scale(1.025);
    }

    img.img {
        position: relative;
        right: 15px;
    }

    div.text {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 30px 4px;
        margin-left: 12px;

        .go {
            position: relative;
            bottom: 10px;
            left: 1.5px;
        }

        .to {
            position: relative;
            left: 2.5px;
        }

        .leadedge {
            position: relative;
            top: 10px;
            left: 5px;
        }
    }
}