.container-day-4-pv5 {
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .box {
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-size: 36px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .box-1 {
    flex: 4;
    background-image: url('../../../../../assets/images/pvPharma5Game/images/component_58___1.svg');
    background-size: cover;
    position: relative;

    .pup {
      background-color: white;
      width: 92%;
      padding: 20px;
      border-radius: 5px;
      position: absolute;
      top: 30%;

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 20px solid #fffdfd;
        border-bottom: 20px solid #1a1d4400;
        border-left: 12px solid #9c3d3d00;
        border-right: 12px solid transparent;
        position: absolute;
        bottom: -39px;
        left: 59px;
      }
      h3 {
        font: normal normal bold 16px/17px Karla;
        //   letter-spacing: 0.5px;
        color: #71b6f9;
        text-align: left;
        text-transform: uppercase;
      }
      p {
        font: normal normal normal 14px/20px Karla;
        //   letter-spacing: 0.5px;
        color: #3f4351;
        text-align: left;
      }
    }
  }

  .box-2 {
    // background-color: blue;
    flex: 7;
    display: flex;
    flex-direction: column;
    padding: 0px;
            //  background-color: rgb(0, 217, 255);

    .waves-effect_ew{
            //  background-color: rgb(51, 255, 0);
            //  margin-left: 45px;
            //  margin-right: 9px;
        // margin-right: ;
    }

    .box-2-1_ew {
      flex: 1;
      // background-color: rgb(51, 255, 0);
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 0px;
      // width: 100%;
      margin: 0px 95px;

      .cat-ad {
        background: #ffea7e 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 0.5;

        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #000000;
        opacity: 1;
        padding: 10px;
      }
    }
    .box-2-2_ew {
      flex: 1;
      // background-color: rgb(255, 153, 0);
      padding: 10px 0px 10px 0px;
      padding-left: 0px;
      margin-left: 95px;
      margin-right: 95px;

      .s-title {
        font-family: Karla;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #3f4351;
      }
      h3 {
        text-align: left;
        font: normal normal normal 18px/17px Karla;
        letter-spacing: 0.36px;
        color: #9f9f9f;
        text-transform: uppercase;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal bold 25px/29px Karla;
        letter-spacing: 0.5px;
        color: #3f4351;
        opacity: 1;
      }
    }
    .box-2-3 {
        flex: 5;
        display: flex;
        margin-left: 95px;
        margin-right: 95px;
        //width: 100%;


      .content-notif-moy {
        display: flex;
        flex-direction: column;
        flex: 1;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 100%;

        .list-chois {
          display: flex;
          flex-direction: column;
          padding: 15px 20px 0px 20px;
          .custom-checkbox {
            text-align: initial;
            label {
              text-align: left;
              font: normal normal normal 12px/17px Karla;
              letter-spacing: 0px;
              color: #6c757d;
              opacity: 1;
            }
          }
        }
        .content-notif-moy-nt {
          display: flex;
          flex-direction: row;
          background-color: #51B9CD;
          align-content: center;
          align-items: center;
          border-radius: 4px;
          opacity: 1;
          padding: 15px;
          .text-notf {
            margin-left: 15px;
            h3 {
              text-align: left;
              font: normal normal bold 16px/17px Karla;
              letter-spacing: 0.32px;
              color: #ffffff;
              opacity: 1;
            }
            h4 {
              text-align: left;
              font: normal normal normal 14px/17px Karla;
              letter-spacing: 0.28px;
              color: #ffffff;
              opacity: 1;
              margin-top: 5px !important;
            }
          }
        }
      }

      .box-2-3-4_ew {
        display: flex;
        flex: 1;
        height: 67vh;
        // border: 2px solid red;
                //  background-color: #10c469;

        margin: 0px 20px 10px 20px;
        .drag-block-1 {
          // background-color: #10c469;
          padding-right: 5px;
          border: 1px solid;
          flex: 1;
          margin-right: 8px;
          overflow-y: scroll;




          .row-items {
            display: flex;
            flex-direction: row;
            padding: 10px 10px;
            display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
            .items-box {
              flex: 1;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 1px 3px #0000001a;
              border-radius: 4px;
              margin-left: 12px;
              min-width: 186px;
              max-width: 186px;
              height: 52px;
              text-align: left;
              font: normal normal normal 15px/17px Karla;
              letter-spacing: 0.3px;
              color: #3f4351;
              padding: 15px 10px;
              margin-bottom: 15px;
            }

            .valid {
              background-color: #c91919;
              border: none;
            }
          }
        }
        .drag-block-2 {
          border: 1px solid;
          flex: 1;
          .row-items {
            display: flex;
            flex-direction: column;
            padding: 8px 10px;
            .item-content {
              display: flex;
            }
            .item-title {
              font: normal normal bold 16px/17px Karla;
              color: #3f4351;
              margin-bottom: 14px;
            }
            .items-box {

              flex: 1;
              // background: #ffffff 0% 0% no-repeat padding-box;
              // box-shadow: 0px 1px 3px #0000001a;
              border-radius: 4px;
              margin-left: 12px;
              border: 2px dashed #CED3D9;
              background: #F5F5F5 0% 0% no-repeat padding-box;


              text-align: left;
              font: normal normal normal 15px/17px Karla;
              letter-spacing: 0.3px;
              color: #3f4351;
              padding: 28px 10px;
              height: 71px !important;

            }
            .valid {
              background-color: #10c469;
              border: none;
            }
            .not-valid {
              background-color: #ff5b5b;
              border: none;
            }
          }
        }
      }

      .step_quiz{
        display: flex;
        flex-direction: column;
        flex: 1;
        .step_quiz_btn{
          display: flex;
          padding: 10px;
          justify-content: space-between;
          z-index: 2;
          .next_btn{
            margin-left: auto;
          }
        }
      }

      .step_quiz_4{
        display: flex;
        flex-direction: column;

        .step_quiz_btn{
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
        }
      }


    }

    .box-2-3-step-2 {
      // flex: 1;
      display: flex;
      // max-height: 80%;
      // overflow-y: auto;
      // border: 1px solid;
      .block-2_day4-ph5 {
        flex: 0.7;
        height: 72vh;
        overflow-y: auto;
        border: 1px solid #ced3d9;
        //scrollbar-width: none; /* Firefox */
        //-ms-overflow-style: none;  /* IE 10+ */

        &::-webkit-scrollbar {
          width: 4px;
        }

        ///* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #1DA4BE;
          border-radius: 2px;
        }




        .list-dr-ph5 {
          // width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 10px 5px 10px;
          min-height: 100%;


          .list-dr-item {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 3px #0000001a;
            border-radius: 4px;
            opacity: 1;
            padding: 20px;
            //   border: 1px solid;
            margin-bottom: 5px;
            text-align: left;

            font: normal normal normal 15px/17px Karla;
            letter-spacing: 0.3px;
            color: #3F4351;
            display: flex;
            img{
              margin-right: 15px;
              align-self: flex-start;
              margin-top: 6px;

            }

          }
          //overflow-y: scroll;
          // padding: 30px 10px;
          //overflow-x: scroll;
          // height: 100%;
          flex: 1;
        }
      }
      .block-2 {
        flex: 1.3;
        // background-color: rgb(255, 0, 170);

        flex-direction: row;
        display: flex;
        div {
          display: inline-block;
        }
        .block-2-1 {
          flex: 0.1;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 40px;
            height: 40px;
          }
          // background-color: rgb(0, 47, 255);
        }
        .block-2-2 {
          display: flex;
          flex: 1;
          // background-color: rgb(208, 255, 0);
          flex-direction: column;
          padding-top: 15px;

          .block-2-2row {
            display: flex;
            flex: 1;
          }
          .drag-block {
            flex: 1;
            // border: 1px solid;
            margin: 0px;
            margin-right: 5px;
            display: flex;
            flex-direction: column;

            .body {

              background-color: #f5f5f5;
              border: 2px dashed #CED3D9;
              max-height: 229px ;
              min-height: 229px ;
              overflow-y: scroll;
              overflow-x: hidden;

              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none;  /* IE 10+ */
              &::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* Chrome/Safari/Webkit */
              }

              .item {
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #0000001a;
                border-radius: 4px;

                text-align: left;
                font: normal normal normal 15px/17px Karla;
                letter-spacing: 0.1px;
                color: #3f4351;
                // margin: 5px;
                max-height:  35px;
                text-overflow: ellipsis;
                // width: 200px !important;
                white-space: nowrap;
                margin: 2px 5px;

                position: relative;

                img{
                  top: 8px;
                  left: 5px;
                  width: 10px;
                  height: 10px;
                  position: absolute;
                }


                p{
                  // text-align: center;
                  padding-top: 5px;
                  padding-left: 20px;
                  white-space: initial;
                  overflow-wrap: break-word;
                  word-break: break-word;
                  word-break: break-word;
                  hyphens: auto;
                  overflow: hidden;
                  margin-bottom: 11px;
                }
              }
            }
            div {
              flex: 5;
              display: flex;
              flex-direction: column;
              background-color: rgb(255, 255, 255);
            }
            .title {
              flex: 0.13;
              text-align: left;
              font: normal normal bold 16px/17px Karla;
              letter-spacing: 0.32px;
              color: #3f4351;
              opacity: 1;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  .modal-block-1 {
    background-image: url('../../../../../assets/images/StrategicGame/group_5171.png');
    flex: 1.5 1;
    background-color: #a7dcf1;
    height: 70vh;
    background-size: contain;
    background-position-y: 100%;
    background-position-x: 35px;
    background-repeat: no-repeat;
  }
  .modal-block-2 {
    position: relative;
    background-image: url('../../../../../assets/images/StrategicGame/group_3746.png');
    flex: 4;
    background-color: #48b0f5;
    height: 70vh;
    background-size: contain;
    background-position-y: 154%;
    background-position-x: 97px;
    background-repeat: no-repeat;
    .btn-msg-can {
      position: absolute;
      z-index: 200;
      bottom: -19px;
      right: 200px;
      color: #343a40;
      background-color: #fff;
    }
    .btn-msg {
      position: absolute;
      z-index: 200;
      bottom: -19px;
      right: 59px;
    }
    .msg {
      width: 90%;
      background-color: white;
      z-index: 300;
      position: absolute;
      left: 5%;
      padding: 10px 20px;
      top: 20%;
      border-radius: 4px;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ffffff;
        position: absolute;
        top: 50px;
        left: -12px;
      }
      h3 {
        text-align: left;
        font: normal normal bold 16px/17px Karla;
        letter-spacing: 0.32px;
        color: #71b6f9;
        text-transform: uppercase;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal normal 14px/17px Karla;
        letter-spacing: 0.28px;
        color: #343a40;
        opacity: 1;
      }
    }
    &::before {
      background-color: #000000;
      opacity: 66%;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 200;
      position: absolute;
    }
  }
}

.dropdown-menu {
  width: 100%;
  .dropdown-item {
    white-space: initial;
    border-bottom: 1px solid #e8e8e8;
  }
}

.lable-select {
  text-align: left;
  font: normal normal normal 15px/17px Roboto;
  letter-spacing: 0.3px;
  color: #3f4351;
  opacity: 1;
}

.form-group-selecte {
  text-align: left;
  padding: 4px 7px;
  //   flex: 1;
  width: 50%;
}


.day_4_step3{

  flex: 1;
   margin-top: 20px;
  img{
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

}
