.kpi-component-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgb(255, 255, 255);
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  //width: 158px !important;
  height: 179px !important;
  flex:1;
  // margin-right: 20px;

  .kpi-component-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #9fa2b4;
  }

  span.kpi-component-value {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #11263c;
  }

  .kpi-component-footer {
    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 16px;
      display: inline-block;
      align-items: center;
      letter-spacing: 0.4px;
    }

    img.arrow_icon {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
  }
}
