//To achieve the big part of responsivness, always add width: 100%; and max-width: "value"px on the container css selector 
//responsivness for p tags ==>  word-break: break-all;
@mixin mixin_roboto_20px {
    display: inline-block !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #5E6366;
    margin: 0 auto !important;
    text-align: center;
}

//Relevant to PopupCongratulations.js
.pop-up-congratulations-container {
    width: 100%;
    max-width: 915px;
    background: #FFFFFF;
    border: 1px solid #C1C9D2;
    box-sizing: border-box;
    box-shadow: 0px 24px 50px rgba(55, 65, 81, 0.25);
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 60px;


    .pop-up-congratulations-img {
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            max-width: 353px;
        }
    }

    .pop-up-congratulations-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 180px;

        p.p1 {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 50px;
            color: #069697;
            word-break: break-all;
            text-align: center;
            margin-top: -40px;
        }

        .span2-wrapper {
            display: flex;
            justify-content: center;

            span.span2 {
                display: inline-block;
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 140.5%;
                color: #142D3F;
                opacity: 0.6;
            }
        }
    }

    .get-started-btn {
        display: flex;
        justify-content: center;
        margin-top: 0px;

        &:hover {
            margin-right: 10px;
            // transition: .66s ease;
        }
    }

}

//Relevant to PopupHint.js
.pop-up-hint-container {
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 50px;
    padding-left: 200px;
    padding-right: 400px;
    width: 1400px;
    height: 800px;

    div.close-btn {
        display: inline-block;

        &>* {
            position: relative;
            top: 50px;
            left: 780px;
        }
    }

    div.content {
        background: white;
        border-radius: 10px;
        width: 831px;
        padding: 22px 0px 59px 0px;

        .pop-up-hint-header {
            background: linear-gradient(90deg, #F5F7FA 0%, #C3CFE2 100%);
            border: 1px solid #D0D1D2;
            box-sizing: border-box;
            border-radius: 20px;
            display: flex !important;
            width: 329px !important;
            align-items: center;
            justify-content: space-evenly;
            height: 55px;
            margin: 0 auto;

            img {
                display: inline-block;
            }

            span.span0 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 29px;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                color: #082429;
                position: relative;
                right: 30px;
            }
        }

        div.close_btn {
            display: inline-block;

            .button {
                float: right;
                margin-right: 20px !important;
                margin-top: -5px;
            }
        }

        .pop-up-hint-body {
            display: inline-block;
            margin: 0 auto;
            margin-top: 50px;

            .p1.p,
            .p2.p {

                span.span1 {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: 0.15px;
                    color: #424551;
                }

                div.pop-up-hint-body-spans {
                    display: inline;

                    span.body-span {
                        display: block !important;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: 0.15px;
                        padding-top: 2px; // for spaces betwwen the lines

                        .prop-span {
                            color: rgba(68, 125, 160, 0.856);
                        }

                        span.bullet {
                            color: #424551;
                        }

                        .text-span {
                            color: #424551;
                        }
                    }
                }
            }
        }
    }
}

//Relevant to PopupSuccess.js
.pop-up-success-container {
    width: 100%;
    max-width: 844px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly !important;
    height: 537px;

    .pop-up-success-header {
        display: inline-block;
        margin-bottom: -40px;
        height: 30px !important;

        .btn {
            display: inline-block;
            margin-top: 10px;
            margin-right: 10px;
        }
    }

    .pop-up-success-body {
        display: flex;
        flex-direction: column !important;
        align-items: center;

        img.img {
            display: flex; // to center it
            margin: 0 auto;
            // border: 2px solid;
        }

        span.span0 {
            display: inline-block !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 28px;
            letter-spacing: 0.15px;
            color: #5E6366;
            margin: 0 auto !important;
        }

        span.span1 {
            @include mixin_roboto_20px;
        }

        span.span2 {
            @include mixin_roboto_20px;
            margin-left: 5px !important;
            width: 440px;
        }
    }

    div#btn-wrapper {
        display: flex;
        margin: 0 auto;

        .pop-up-success-footer {
            //btn
            display: flex;
            margin: 0 auto;
            position: relative;
            right: 20px;

            &.btn {
                &:hover {
                    margin-right: 33px;
                    transition: .66s ease;
                }

            }
        }
    }

    .ok-btn {
        // display: none;
        margin: 0 auto;
        position: relative;
        bottom: 40px;
    }
}

//for the LeadEdgeBtn gradient-green
.hidden {
    display: none !important;
}

.shown {
    display: inline-block !important;
}

//Relevant to PopupCheckSaving.js
.popup-check-saving-container {
    background: #FFFFFF;
    border-radius: 10px;
    height: 180px !important;
    width: 100%;
    max-width: 648px;

    .popup-check-saving-stack {

        .popup-check-saving-header {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            min-height: 80px;

            .close-btn {

                margin-right: 10px;
                align-self: flex-end; //wow ! this is an amzing css flex rule === float : right , which does not work always
            }

            span {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 28px;
                text-align: center !important;
                letter-spacing: 0.15px;
                color: #5E6366;
            }
        }

        .popup-check-saving-footer {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .cancel-btn {
                margin-right: 15px;
                display: inline-block !important;
            }

            .save-btn {
                display: inline-block !important;
            }
        }
    }
}

//Relevant to PopupCheckDelete.js
.popup-check-delete-container {
    width: 100%;
    max-width: 692px;
    height: 537px;
    background: #FFFFFF;
    border-radius: 10px;

    .popup-check-delete-stack {

        .popup-check-delete-header {
            display: inline-block;

            .close-btn {
                // float: right;//this is equivalent to the code below
                display: flex;
                justify-content: flex-end;
                margin-right: 10px;
                position: relative;
                top: 10px;
            }
        }

        .popup-check-delete-body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly; //this is the best one for this purpose
            align-items: center;
            min-height: 380px;

            img {
                width: 112.5px;
                height: 112.5px;
            }

            span.span1 {
                display: inline-block;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 28px;
                letter-spacing: 0.15px;
                color: #5E6366;
            }

            span.span2 {
                display: inline-block;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 19px;
                line-height: 40px; //changed from 22px
                letter-spacing: 0.05em;
                text-transform: initial;
                color: #9FA2B4;
                text-align: center !important; // all added
                margin-left: 100px;
                margin-right: 50px;

                span.number {
                    font-weight: 900;
                    font-size: 22px;
                    letter-spacing: 0.1em;
                    color: #424551bd;
                }
            }
        }

        .popup-check-delete-footer {
            display: flex;
            justify-content: center !important;

            .cancel-btn {
                display: inline-block;
                margin-right: 20px;
                width: 144px;
                height: 45px;
            }

            .delete-btn {
                display: inline-block;
                width: 144px !important;
                height: 45px !important;
            }
        }
    }
}

//Relevant PopupSentSuccess.js
.popup-sent-success-container {
    width: 692px !important;
    height: 537px !important;
}

//Relevant PopSentSuccessPrompt.js
.popup-sent-success-prompt-container {
    width: 692px;
    height: 521px;
    background: #FFFFFF;
    border-radius: 10px;

    .popup-sent-success-prompt-stack {

        .popup-sent-success-prompt-header {
            display: inline-block;

            .close-btn {
                float: right;
                margin-right: 5px;
                margin-top: 10px;
            }
        }

        .popup-sent-success-prompt-body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            min-height: 330px;

            img {
                display: inline-block;
            }

            span.span1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 28px;
                display: flex;
                align-items: flex-end;
                letter-spacing: 0.15px;
                color: #5E6366;
                margin-top: 35px;
            }
        }

        .popup-sent-success-prompt-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 45px;

            .cancel-btn {
                display: inline-block;
                width: 144px;
                height: 45px;
                margin-right: 25px;
            }

            .send-btn {
                display: inline-block;
                width: 144px;
                height: 45px;
            }
        }
    }
}

//Relevant to RenameScenario.js

.rename-scenario-container {
    width: 648px;
    min-height: 230px;
    background: #FFFFFF;
    border-radius: 10px;
    padding-bottom: 25px !important;

    .rename-scenario-header {

        //close btn
        &>* {
            float: right;
            position: relative;
            top: 10px;
            right: 10px;
        }
    }

    .rename-scenario-body {
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        span {
            display: inline-block;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 0.15px;
            color: #5E6366;
        }

        .input-field {
            display: inline-block !important;
            background: rgba(227, 229, 230, 0.3);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 235px;
            min-height: 40px;

            &::placeholder {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                color: #C6CACC;
                margin-left: 45px !important;

            }
        }
    }

    .rename-scenario-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        // margin-right: 80px;
        margin-top: 15px;

        .cancel-btn {
            display: inline-block;
            width: 96px;
            height: 40px;
            margin-right: 20px;
        }

        .save-btn {
            display: inline-block;
        }
    }
}

//Overlay.js
.overlay-container {
    z-index: 999;
    // border: 20zspx solid red !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    background: rgba(0, 0, 0, .9);
    filter: blur(1000px) !important;
}