//HomeAnalysisMenu.js
.home-analysis-menu-container {
  .home-analysis-menu-tabs-stack {
    display: inline-block;
    //margin-left: 250px;
    // border: 2px solid red;
    position: relative;
    bottom: 20px;

    .home-analysis-menu-tabs {
      //margin-left: 15%;
      display: inline-block !important;
      //width: 1000px;

      .tab {
        &:hover {
          background-color: #e5efff;
          //transform: scale(1.1);
        }
      }
    }
  }

  //tab panels and content
  .home-analysis-menu-tabpanels-stack {
    // height: 1130px;
    //padding: 50px;
    //padding-bottom: 200px;

    .tab-panel1 {
      overflow-y: hidden;
      overflow-x: hidden;
      // border: 2px solid;
      margin-top: -80px;
    }
  }
}

//ScenarioTabbedMenu.js
.class1 {
  padding-top: 50px;
  padding-bottom: 20px;

  .tabs-stack {
    .tabs {
      display: inline-block;
      border-bottom: 1px solid #c6c2de;
      //width: 1350px;

      .tab {
        margin: 0px 10px;

        &:focus {
          color: #2d9cdb;
          border-radius: 7px 7px 25px 0px;
          transition: 0.6s ease;
        }

        &:hover {
          color: #2d9cdb;
          border-radius: 7px 7px 25px 0px;
          border-bottom: 2.5px solid #2d9cdb;
          transition: 0.6s ease;
        }
      }
    }
  }

  //tab panels and content
  .tabpanels-stack {
    .tab-panel {
      //width: 1400px;
    }
  }
}

//InputsTabbedMenu.js and ResultsTabbedMenu.js
.saved-scenarios-page-tabbed-menu {
  //padding-bottom: 80px;
  //background: #5d19d2;
  //width:100%;
  //height:300px;
  

  .tabs-stack {

    position: relative;
    top: 120px;
    padding-right: 15px;
    padding-left: 15px;
    //background-color: #00a138;

    .tabs {
      display: flex;
      //margin-top: -15px;
      border-bottom: 2px solid #c6c2de;
      //width: 1350px;
      position: relative;
      //right: 20px;

      .tab {
        margin-right:35px;
        //width:fit-content;
        //flex: auto;
        //border: 1px solid black;
        //margin: 0px 20px;
        //background-color: #c43e3e;

        &:hover {
          color: #2d9cdb;
        }

        &.inputs-tab {
          margin-right: 5px;
          margin-left: 5px;
          position: relative;
          bottom: 10px;
        }
      }

      .total {
        //margin-left: 400px;
      }
      &.scenario-tabbed-menu-tabs {
        &.inputs-menu-tabs {
          position: relative;
          //top: 25px;
        }
      }
    }
  }

  //tab panels and content
  .tabpanels-stack {
    width: auto;
    position: relative;
    top: 120px;

    .tab-panel {
      overflow: hidden;
      //width: 1400px;
      padding: 2px 30px;

      &.saved-scenarios-tab-panel {
        overflow: hidden;
        //width: 1480px;
        height: auto;
        padding: 2px 30px;
        padding-bottom: 80px;

        &.over {
          position: relative;
          right: 80px;
        }

        &.fin {
          position: relative;
          right: 120px;
        }

        &.sales {
          position: relative;
          right: 80px;
        }

        &.prod {
          position: relative;
          right: 80px;
        }

        &.hr {
          position: relative;
          right: 10px;
        }
      }
    }

    .send-decision-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      margin-right: 20px;
    }
  }
}


//MenuSimulation.js
.menu-simulation-container {

  padding-bottom: 40px;
  padding-right: 20px;
  position: relative;
  bottom: 185px;
  margin-top: 40px;

  .scenario-tabbed-menu-container {
    position: relative;
    .total {
      box-sizing: border-box;

      .total-inner {
        display: inline-block;
        // border: 2px solid;
        min-height: 75px;
        //margin-left: 1050px;
        position: relative;
        bottom: 722px;
      }
      .total-inner-finance {
        display: inline-block;
        min-height: 75px;
        //margin-left: 1050px;
        position: relative;
        bottom: 755px;
      }
    }

    .cancel-save-btns {
      display: inline-block;
      position: relative;
      bottom: 265px;
      //left: 1090px;
      z-index: 10;
    }
    .cancel-save-btns-finance {
      display: inline-block;
      position: relative;
      bottom: 298px;
      left: 1090px;
      z-index: 10;
    }

    .simulate-btn {
     display: flex;
     margin-top: 100px;
     width:100%
    }

    .tabs-stack {
      position: relative;
      top: 100px;

      &.scenario-tabbed-menu-tabs-stack {

        //height: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        //padding:10px 10px;
        //padding-right: 20px;
        border-bottom: 2px solid #c6c2de;

        position: relative;
        //bottom: 10px;

       > {
         :nth-child(2) {
           position: relative;
           bottom: -16px;
         }
       }
        .tabs {
          &.scenario-tabbed-menu-tabs {
            display: block;
            //margin-top: 100px;
            //width: 1105px;
            margin-bottom: -10px;
            //margin-left: 190px;
            //left: 38px;

            position: relative;
            bottom: -12px;

            &.simulat-tabs {
              // border: 2px solid;
            }

            .tab {

              margin: 0px 30px;
              position: relative;
              left: -35px;
              bottom: 8px;

              &:focus {
                color: #2d9cdb;
              }

              &:hover {
                color: #2d9cdb;
                //border-bottom: 1.8px solid #2087c2cb;
              }

              &.tab2 {
                margin-left: -20px;
                position: relative;
                right: 70px;
              }
              &.opex-tab {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }

    //tab panels and content
    .tabpanels-stack {
      &.scenario-tabbed-menu-tabpanels-stack {
        // apply hover style on this container
        //width: 90%;
        //margin-left: 8%;
        position: relative;
        top: 90px;

        .simul-tabpanels-inner {
          // border: 2px solid;
        }
      }
      .tab-panel {
        overflow-y: scroll;
        overflow-x: hidden;
        //padding: 2px 30px;
      }

      .send-decision-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        margin-right: 20px;
      }
    }
  }
}

//OpexTabbedMenu.js and SimulationOpexTablesContent.js
// SimulationCapexTablesContent.js
// SimulationFinanceTablesContent.js
.tabbed-menu-opex-btns-container {
  //padding-left: 30px;
  //width: 85%;
  //margin-left: 50px;

  &.padding-bottom {
    // border: 1px solid;
    padding-bottom: 120px;
  }
  &.padding-bottom-last-tab {
    padding-bottom: 120px;
  }

  .opex-tabs-stack {
    
    width:100%;
    .opex-tabs {
      //border-bottom: 1.5px solid rgba(133, 133, 133, 0.541) !important;
      padding-bottom: 20px;
      margin-bottom: 40px;
      padding-left: 8px;
      padding-right: 8px;

     
      &.simul-tabs-finance {
        
        bottom: 33px;
      }
      &.capex-tabs-to-right {
        //margin-left: 750px;
      }

      &.scenarios-inputs-tabs {
        position: relative;
        top: 30px;
        //margin-left: 800px;
      }
      &.move-to-left {
        //margin-left: 800px;
      }

      &.inputs-opex-tabs,
      &.inputs-fin-tabs {
        //margin-left: 800px;
      }
      &.move-to-bottom {
        position: relative;
        top: 30px;
      }

      button.MuiButtonBase-root {
        padding: 0px 12px;
        height: 36px !important;

        span.MuiTouchRipple-root {
          height: 36px !important;
        }
      }

      .tab {
        margin: 5px 10px;
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(27, 78, 163, 0.24),
          0px 2px 4px rgba(41, 121, 255, 0.24);
        border-radius: 15px;
        margin-top: 20px;

        &:hover {
          background: #2d9bdb47;
        }

        &.simulation-tab {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  //tab panels and content
  .opex-tabpanels-stack {
    //min-height: 400px;
    position: relative;
    //right: 50px;

    .tab-panel {
      width: 100%;
      overflow-y: hidden;
      margin-top: -30px;

      &.p1,
      &.p2,
      &.p3,
      &.p4 {
        .table {
          // display: inline-block;
          //margin-top: 58px;
          //margin-bottom: 33px;
          // margin-left: 155px;
          //margin-left: 30px;
          //height: 273px;
          position: relative;
          top: 100px;
          z-index: 0;

          &.simulation-table {
            //width: 100%;
            height: 480px;
            background: #f4f2ff;
            //padding: 24px 40px 90px 40px;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            position: relative;
            top: -20px;
            right: 20px;
            overflow-y: hidden;
            padding:60px 34px 20px 34px;

            .cancel-save-container{
              position:absolute;
              bottom:20px;
              right:35px;
            }
            .opex-sales-table {
              // border: 2px solid green;
            }

            &.simu-fin-table {
              position: relative;
              
            }
            &.sim-fin-table2 {
              position: relative;
              
            }
          }

          //these t2, t3 and t4 tables are in Simulation menu

          &.t2 {
            overflow-x: hidden;
          }

          &.t3 {
            overflow-x: hidden;
          }

          &.t4 {
            overflow-x: hidden;
          }

          //thse tables are in Saved Scenarios Menu, Opex
          &.saved-opex-t1 {
            position: relative;
            top: 31px;
          }

          &.saved-opex-t2 {
            position: relative;
            top: 31px;
          }

          &.saved-opex-t3 {
            position: relative;
            top: 31px;
          }

          &.saved-opex-t4 {
            position: relative;
            top: 31px;
          }

          //thse tables are in Saved Scenarios Menu, Capex
          &.saved-capex-t1 {
            position: relative;
            top: 31px;
          }

          &.saved-capex-t2 {
            position: relative;
            top: 31px;
          }

          &.saved-capex-t3 {
            position: relative;
            top: 31px;
          }

          // thse tables are in Saved Scenarios Menu, Finance
          &.saved-finance-t1 {
            position: relative;
            top: 31px;
          }

          &.saved-finance-t2 {
            position: relative;
            top: 31px;
          }
        }
        .simul-table-wrapper {
          width: 100%;
          height: 500px;
          background: #f4f2ff;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          overflow: hidden;
          padding: 24px 40px 90px 40px;
          margin-top: 39px;
          margin-left: 10px;
        }
      }
    }

    hr.horizontal-ruler {
      height: 0.02rem;
      width: 875px;
      border-radius: 0px;
      position: relative;
      top: 290px;
      left: 320px;
      z-index: 999;
    }
  }
}

//prop classes
.d_block {
  display: inline-block;
}

.d_none {
  display: none;
}

.test-table {
  margin-top: 500px;
  margin-left: 200px;
}

.scenario-simulation-container{
   position: relative;


  .scenario-simulation{
    background: linear-gradient(90deg, #ACCBEE 0%, #E7F0FD 100%);
    width: 590px;
    height: 87px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 5px;

  }
  .close-button{
   position:absolute;
    top:0;
    right:0;
    cursor: pointer;
  }
}

.btn-save{
  margin-top:40px;
  margin-right: 20px;
  display: flex;
  width: 220px;
  height: 60px;
  justify-content:center;
  align-items: center;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #5E6366;
  background: linear-gradient(90deg, #D4FC79 0%, #96E6A1 100%);
  border-radius: 20px;

}
