//ParticipantMainPage.js
.company-container{
  flex:1;

}
.home-analysis-page-container {
 

  &.participant-page {
    .participant-page-subheader {
      .stack1 {
        & > * {
          position: relative;
          bottom: 25px;
        }
      }
    }
  }

  .header {
    margin-bottom: 15px;
    margin-left: -15px;
  }

  .subheader {
    //width: 1729px;
    position: relative;
    top: 15px;
    padding-right: 20px;
    .stack1 {
      padding-top: 30px;
      //z-index: 999 !important;

      .link {
        text-decoration: none;

        //back home btn
        & > * {
          position: relative;
          bottom: 20px;

          &:hover {
            transform: scale(1.08);
            transition: 0.15s ease;
          }
        }
      }
    }

    .stack2 {
      //Rounds Component

      .team-menu {
        display: inline-block;
        position: relative;
        margin-top: -25px;
      }

      .round-menu {
        display: inline-block;
        z-index: 100;
        margin-left: -8px;
      }
    }
  }

  .rest-of-content {
    position: relative;
    bottom: 480px;

    .sidebar {
      display: inline-block;
      position: relative;
      top: 600px;
      z-index: 999 !important;

      .left-side-navigation-list {
        .ListItemButton {
          // background: red;
          .external-tab {
          }
        }
      }
    }

    .view-team-btn {
      position: relative;
      top: 660px;
      left: 40px;
    }

    .participant-view-team-btn {
      position: relative;
      top: 710px;
      right: 300px;
      border: 2px solid;

      &:hover {
        color: white !important;
      }
    }

    .moderator-main-page-ruler {
      border: 1px dashed #e3e5e6;
      position: relative;
      top: 36px;
    }

    .participant-page-ruler {
      border: 1px dashed rgba(128, 128, 128, 0.406);
      position: relative;
      top: 36px;
    }

    .round-finished {
      // border: 2px solid;
      display: inline-block;
      margin-left: 22%;
      margin-top: 30px;
    }
  }

  .add-content {
    width: 1500px;
  }
}

//MycompanyOverview
.mycompany-overview-container,
.mycompany-finance-container,
.mycompany-sales-container,
.mycompany-production-container,
.mycompany-hr-container,
.mycompany-market-container,
.mycompany-industry-container {
  //padding: 20px 0px 20px 38px;
}

.mycompany-overview-container,
.mycompany-finance-container,
.mycompany-sales-container,
.mycompany-production-container,
.mycompany-hr-container {
  position: relative;
  //left: 100px;
}

//OverviewPart1.js
.overview-part1-container,
.finance-part1-container,
.sales-part1-container,
.production-part1-container {
  .kpi {
    margin-bottom: 34px;
  }

  .kpis {
    .row1 {
      & > *:nth-child(1) {
        margin-left: 52px;
        margin-right: 60px;
        margin-bottom: 34px;
      }

      & > *:nth-child(2) {
        margin-right: 60px;
        margin-bottom: 34px;
      }

      & > *:nth-child(3) {
        margin-right: 60px;
        margin-bottom: 34px;
      }

      & > *:nth-child(4) {
        margin-bottom: 34px;
      }
    }

    .row2 {
      & > *:nth-child(1) {
        margin-left: 52px;
        margin-right: 60px;
      }
      & > *:nth-child(3) {
        margin-left: 60px;
      }

      & > *:nth-child(4) {
        margin-left: 60px;
      }
    }
  }
}

.sales-part1-container {
  .kpis {
    position: relative;
    right: 15px;

    .row1 {
      margin-left: 60px;
      & > *:nth-child(1) {
        margin-right: 40px;
      }

      & > *:nth-child(2) {
        margin-right: 40px;
      }

      & > *:nth-child(3) {
        margin-right: 40px;
      }
    }
  }
}

//HRPart1.js
.hr-part1-container {
  width: 100%;

  .kpi {
    margin-bottom: 34px;
  }

  .kpis {
    margin-left: 20px;
    .row1 {
      & > *:nth-child(1) {
        // margin-left: 42px;
        margin-bottom: 34px;
        margin-right: 2%;
      }

      & > *:nth-child(2) {
        margin-bottom: 34px;
        margin-right: 2%;
      }

      & > *:nth-child(3) {
        margin-bottom: 34px;
        margin-right: 2%;
      }

      & > *:nth-child(4) {
        margin-bottom: 34px;
        margin-right: 2%;
      }
    }
  }
}

//The elements are all set to display: inline-block;. When you give one of the elements a margin-top, you push the whole line down.
//OverviewPart2.js
.overview-part2-container-company {
  display: grid !important;
  grid-template-columns: 650px 650px;
  grid-gap: 20px;
  width: 100%;
  overflow-y: hidden !important;

  .text-and-chart {
    &.item1 {
      & > *:nth-child(1) {
        margin: 30px 0px;
        margin-top: 42px;
      }

      & > *:nth-child(2) {
        margin-right: 28px;
      }
    }

    &.item2 {
      & > *:nth-child(1) {
        margin: 30px 0px;
        margin-top: 42px;
      }

      & > *:nth-child(2) {
        margin-top: 17px;
      }
    }

    &.item3 {
      & > *:nth-child(1) {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      & > *:nth-child(2) {
        margin-top: 30px;
      }
    }

    &.item4 {
      margin-left: 10px;

      & > *:nth-child(1) {
        margin-top: 27px;
        margin-bottom: 20px;
        position: relative;
        right: 10px;
      }

      & > *:nth-child(2) {
        margin-top: 25px;
        position: relative;
        right: 10px;
      }
    }
  }
  //i did this because a hover problem emerged
  .text-and-chart-item5 {
    margin-left: 15px;

    & > *:nth-child(1) {
      margin-top: 27px;
      position: relative;
      right: 10px;
    }

    & > *:nth-child(2) {
      margin-top: 35px;
      position: relative;
      right: 15px;
    }
  }

  &.overview-part2-container-scenarios {
    padding-left: 25px;
    overflow: hidden !important;
    padding-bottom: 100px;
    margin-top: 16px;

    & > * {
      margin-left: 59px;
    }
    .text-and-chart {
      &.item4 {
        & > *:nth-child(1) {
          margin-left: 55px;
        }

        & > *:nth-child(2) {
          margin-left: 59px;
        }
      }
    }
  }
}

//FinancePart2.js
.finance-part2-container-company {
  width: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding-left: 20px;
  margin-top: 100px;

  .finance-main-grid {
    // border: 1px solid;
    position: relative;
    bottom: 20px;

    .grid-no1 {
      position: relative;
      top: 60px;

      .text-and-chart {
        & > *:nth-child(1) {
          margin: 15px 0px;
          position: relative;
          right: 21px;
        }
      }
    }

    .grid-no2 {
      position: relative;
      top: 60px;

      .text-and-chart {
        & > *:nth-child(1) {
          margin: 20px 0px;
          position: relative;
          right: 21px;
        }
        & > *:nth-child(2) {
          width: 760px;
        }
      }
    }

    .grid-no3 {
      position: relative;
      top: 205px;

      .text-and-chart {
        & > *:nth-child(1) {
          margin: 35px 0px;
          position: relative;
          right: 21px;
        }
      }
    }

    .grid-no4 {
      position: relative;
      top: 205px;

      .text-and-chart {
        & > *:nth-child(1) {
          margin: 35px 0px;
          position: relative;
          right: 21px;
        }
        & > *:nth-child(2) {
          margin-top: 20px;
        }
      }
    }
  }
  .finance-stack1 {
    position: relative;
    bottom: 780px;
    .income-statement {
      margin-bottom: 20px;
    }
    .cash-statement {
    }
  }
  .finance-stack2 {
    position: relative;
    bottom: 780px;

    .balance-sheet {
      .bal-header {
        position: relative;
        right: 36px;
      }
    }
  }

  &.finance-part2-container-scenarios {
    padding-left: 55px;
    width: 103%;
    overflow: hidden !important;
    height: 3380px;
    margin-top: 3px;
    padding-bottom: 80px;

    .finance-main-grid {
      position: relative;
      left: 20px;

      .grid-no1,
      .grid-no2,
      .grid-no3,
      .grid-no4 {
        .text-and-chart {
          & > *:nth-child(1) {
            margin-left: 71px;
            // border: 1px solid;
          }
        }
      }
      .grid-no1,
      .grid-no2 {
        .text-and-chart {
          & > *:nth-child(1) {
            margin-bottom: 50px;
          }
        }
      }
      .grid-no3,
      .grid-no4 {
        .text-and-chart {
          & > *:nth-child(1) {
            margin-bottom: 50px;
          }
        }
      }
    }

    .finance-stack1 {
      position: relative;
      bottom: 780px;
      left: 20px;

      .income-statement {
        margin-bottom: 20px;
        position: relative;
        left: 30px;
      }
      .cash-statement {
        position: relative;
        left: 23px;
      }
    }

    .finance-stack2 {
      position: relative;
      left: 20px;

      .balance-sheet {
        .bal-header {
          margin-left: 70px;
        }
        .balance-sheet-sheet {
          position: relative;
          left: 8px;

        }
      }
    }
  }
}

//SalesPart2.js and ProductionPart2.js
.production-part2-container-company {
  width: 1520px !important;
  overflow-x: hidden;
  padding-left: 20px;
  margin-left: 45px;
  margin-top: 69px;

  .prod-stack1 {
    // margin: 45px 0px;
    .stack1-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 45px;
      }
    }
    .stack1-elem2 {
      & > *:nth-child(1) {
        position: relative;
        right: 80px;
        margin-bottom: 45px;
      }
      & > *:nth-child(2) {
        position: relative;
        right: 79px;
      }
    }
  }
  .prod-stack2 {
    margin: 45px 0px;

    .stack2-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 45px;
      }
      & > *:nth-child(2) {
      }
    }
    .stack2-elem2 {
      & > *:nth-child(1) {
        margin-bottom: 65px;
        position: relative;
        right: 100px;
      }
      & > *:nth-child(2) {
        position: relative;
        right: 99px;
      }
    }
  }
  .prod-stack3 {
    margin: 45px 0px;

    .stack3-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 65px;
      }
    }
    .stack3-elem2 {
      & > *:nth-child(1) {
        margin-bottom: 65px;
      }
    }
  }
  .prod-stack4 {
    margin: 45px 0px;

    .stack4-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 45px;
      }
    }
    .stack4-elem2 {
      & > *:nth-child(1) {
        margin-bottom: 45px;

        position: relative;
        right: 90px;
      }
      & > *:nth-child(2) {
        position: relative;
        right: 90px;
      }
    }
  }

  &.production-part2-container-scenarios {
    // border: 1px solid;
    margin-top: 58px;
    padding-bottom: 50px;
    margin-left: 65px;
  }
}

//HRPart2.js
.hr-part2-container-company {
  width: 100%;
  margin-top: 35px;

  .hr-stack1 {
    margin-bottom: 60px;

    .stack1-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 22px;
      }
    }
    .stack1-elem2 {
      position: relative;
      right: 130px;

      & > *:nth-child(2) {
        position: relative;
        top: 20px;
      }
    }
  }
  .hr-stack2 {
    .stack2-elem1 {
      & > *:nth-child(1) {
        margin-bottom: 50px;
      }
    }
  }

  &.hr-part2-container-scenarios {
    margin-top: 60px;
    margin-left: 15px;
    padding-bottom: 40px;

    .hr-stack1 {
      margin-bottom: 50px;

      .stack1-elem2 {
        position: relative;
        right: 62px;
        & > *:nth-child(2) {
          position: relative;
          top: 20px;
        }
      }
    }
    .hr-stack2 {
      .stack2-elem1 {
        margin-bottom: 50px;
        & > *:nth-child(1) {
          margin-bottom: 50px;
        }
      }
    }
  }
}

//MarketPart2.js
.market-part2-container {
  //background-color: red;
  //width: 1520px !important;
  overflow: hidden;
  //padding-left: 20px;
  //margin-left: 45px;

  .mark-stack-no1 {
    // border: 2px solid;
    margin-top: 15px;
    //margin-left: 36px;
    .text-and-chart {
      & > * {
        margin-bottom: 30px;
      }
      & > *:nth-child(2) {
        //margin-left: 38px;
      }
    }
  }
  .market-grid-container {
    gap: 1em;
    // border: 2px solid red;
    //margin-left: 38px;


    .grid-no2 {
      margin-top: 28px;
      margin-bottom: 30px;
    }
    .grid-nod{
      //background-color: rgba(79, 119, 86, 0.65);
      //margin-top: 25px;
      //margin-right: 90px;
      //margin-bottom: 88px;

      //width:33%;

      height:200px;
      padding:0;
      border: 1px solid #6b3e91;


      .pie-charts {
        //background-color: #02a8b5;
        //height: 850px;
        //min-width: 360px;
        //background: #ffffff;
        //border: 1px solid #e5e5e5;
        //box-sizing: border-box;
        //border-radius: 4px;
        //margin-left: 10px;
        //padding-left: 15px;
        //padding-right: 10px;
        //padding-top: 25px;
        //padding-bottom: 50px;
        //
        //& > * {
        //  position: relative;
        //  bottom: 16px;
        //}
        //
        //& > *:nth-child(2) {
        //  margin-top: -80px;
        //}
      }
    }
  }
  .mark-stack-no2 {
    margin-top:30px;
  }
}

//IndustryPart2.js
.industry-part2-container {
   //border: 2px solid red;
  width:100%;
  //width: 1520px !important;
  //overflow: hidden;
  //padding-left: 20px;
  //margin-left: 45px;
  padding-top: 15px;
  .indus-stack-no1 {
    //margin-top: 15px;
     margin-bottom: 34px;
    //margin-left: 36px;
    //& > * {
    //  margin: 60px 0px;
    //}
  }
  .indus-grid-container {
    margin-left: -28px;
    padding-right: 20px;

  }

  .indus-stack-no2 {
    margin-bottom: 68px;
    margin-right: 107px;
    margin-left: 36px;
    .table {
      & > *:nth-child(1) {
        margin: 70px 0px;
      }

    }
  }
  .indus-footer-table {
    margin-top: 30px;
    margin-left: 20px;
    width: 100%;
  }
}

.sales-part2-container-company {
  width: 100%;
  overflow-x: hidden;
  padding-left: 20px;
  margin-left: 45px;
  position: relative;
  right: 60px;

  .stack1-elem1 {
    width: 650px;

    & > *:nth-child(1) {
      margin: 35px 0px;
      // border: 2px solid;
    }
  }
  .stack1-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
    }
  }
  .stack2-elem1 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack2-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack3-elem1 {
    width: 660px;

    & > *:nth-child(1) {
      margin: 45px 0px;
    }
    & > *:nth-child(2) {
      position: relative;
      top: 10px;
    }
  }
  .stack3-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 45px 0px;
      position: relative;
      right: 10px;
    }
    & > *:nth-child(2) {
      position: relative;
      bottom: 10px;
      right: 10px;
    }
  }

  &.sales-part2-container-scenarios {
    padding-bottom: 40px;
    margin-top: 16px;

    & > * {
      margin-left: 79px;
      margin-top: 7px;
    }

    .stack3-elem2 {
      & > *:nth-child(1) {
        margin-left: 8px;
      }
      & > *:nth-child(2) {
        margin-left: 8px;
      }
    }
  }
}

.production-part2-container {
  position: relative;
  right: 65px;

  .stack1-elem1 {
    width: 650px;
    // border: 2px solid;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack1-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack2-elem1 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack2-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
    & > *:nth-child(2) {
      width: 642px;
      position: relative;
      top: 20px;
    }
  }
  .stack3-elem1 {
    width: 660px;
    & > *:nth-child(1) {
      margin: 45px 0px;
    }
    & > *:nth-child(2) {
      position: relative;
      bottom: 5px;
    }
  }
  .stack3-elem2 {
    width: 650px;

    & > *:nth-child(1) {
      margin: 45px 0px;
      position: relative;
      right: 10px;
    }
    & > *:nth-child(2) {
      position: relative;
      bottom: 10px;
      right: 10px;
    }
  }
  .stack4-elem1 {
    width: 660px;
    & > *:nth-child(1) {
      margin: 45px 0px;
    }
    & > *:nth-child(2) {
      position: relative;
      bottom: 10px;
    }
  }
  .stack4-elem2 {
    width: 650px;

    & > *:nth-child(1) {
      margin: 45px 0px;
      position: relative;
      right: 10px;
      // border: 2px solid red;
    }
    & > *:nth-child(2) {
      position: relative;
      bottom: 10px;
      right: 10px;
      // border: 2px solid red;
    }
  }
}

.hr-part2-container {
  .stack1-elem1 {
    width: 650px;
    // border: 2px solid;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack1-elem2 {
    width: 650px;
    & > *:nth-child(1) {
      margin: 35px 0px;
      margin-top: 55px;
    }
  }
  .stack2-elem1 {
    // width: 650px;
    & > *:nth-child(1) {
      margin: 55px 0px;
      // margin-top: 55px;
    }
  }
}

.finance-part1-container {
  .kpis {
    position: relative;
    left: 20px;
  }
}

.finance-part2-container {
  position: relative;
  right: 20px;
}

.menus-and-their-content{
  flex: 1;
  //padding-right: 10px;
}
