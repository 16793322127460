//------------
.dayOneModalPV {
  position: relative;
  width: 40%;
  //height: 90vh;
  background-color: white;
  margin: 0 auto;
  padding: 20px;

  .block-select {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    align-content: center;
    .icon {
      display: inline-block;
      // border: 3px solid;
      width: fit-content;
      text-align: center;
      align-self: center;
      color: #bfe0ff;
      padding: 0px 5px;
      // border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 10px;
       
    }
  }
  .title-s {
    display: flex;
    background: #71b6f9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 0px;
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
      text-align: center;
      font: normal normal bold 18px/17px Karla;
      letter-spacing: 0.36px;
      color: #ffffff;
      margin-top: 16px;
      margin-left: 6px;
    }
    img {
      width: 48px;
      height: 42px;
    }
  }
  .title {
    text-align: left;
    font: normal normal bold 18px/17px Karla;
    letter-spacing: 0.36px;
    color: #3f4351;
    opacity: 1;
    i {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.36px;
      color: #343a40;
      opacity: 1;
      // border: 2px solid;
      border-radius: 15%;
      padding: 2px 3px;
    }
  }

  .box-btn {
    // position: absolute;
    // bottom: 10px;
    // right: 20px;

    padding: 10px;
    display: flex;
    
    flex-direction: row;
    justify-content: flex-end;
  }
}
