.finEdge_s_title2 {
  text-align: left;
  font: bold 31px/41px Karla;
  letter-spacing: 0.76px;
  color: #56B956;
  text-transform: uppercase;
}

.finEdge_s_title1 {
  text-align: left;
  font: 31px/41px Karla;
  letter-spacing: 0.76px;
  color: #343a40;
  text-transform: uppercase;
}

.finEdge_desc {
  text-align: left;
  font: 12px/17px Karla;
  letter-spacing: 0.24px;
  color: #343a40;
  opacity: 1;
}