//variables
$black: black;
$my-grey: #5e6366;

//NavigationTab.js
.navigation-tab-stack {
  display: inline-block;

  .navigation-tab-stack-inner {
    width: 280px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    box-shadow: none !important;
    padding: 7px 80px 7px 20px;
    border: none !important;
    background: white;

    &:hover {
      border-radius: 10px;
      transform: scale(1.05);
      cursor: pointer;
      background: #98b4e349;
    }
    &:focus {
      transform: scale(1.08);
      cursor: pointer;
    }

    .navigation-tab-stack-inner-text {
      span.caption {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 3px;
        margin-bottom: 5px;
        color: rgba($black, 0.87);
        display: flex;
        // border: 2px solid;
        min-width: 220px;
      }

      span.subcaption {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        letter-spacing: 0.4px;
        color: $my-grey;
      }
    }

    img {
      display: inline-block;
      margin-right: 18px;
    }
  }
}

.left-side-navigation-container {
  .left-side-navigation-list {
    display: inline-block;

    .ListItemButton {
    }
  }
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root {
  animation: none !important;
  background: none !important;

  &:focus {
    background: none !important;
    border-radius: 15px !important;
  }

  &:hover {
    border-radius: 15px !important;
    background: none !important;
    position: relative;
    left: 3px;
  }
}

//this is the Mui Css class that controls the buttons on the LeftSideNavigation
//delete the ripple animation on click sur le btn, l'animation "ondulation"
// we can also add disableRipple prop to ListItemButton mui tag
.MuiTouchRipple-root {
  display: none !important;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root {
  // background: none !important;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  &:hover {
    background: none !important;
  }
}

.MuiButtonBase-root-MuiListItemButton-root-Mui-autoFocus {
  background: red !important;
}

.MuiButtonBase-root-MuiListItemButton-root-MuiButtonBase-autoFocus {
  background: red !important;
}
