.daysModal {
  min-width: 100%;
  margin: 0px !important;

  .modalContent {
    background-color:transparent
  }
 
}

.dayOneModal {
  position: relative;
  width: 974px;
  height: 529px;
  margin: 0 auto;
  background-image: url('../../../assets/images/StrategicGame/bgModaldayone.png');

  .discussMessage {
    width: 590px;
    height: auto;
    position: absolute;
    bottom: 40%;
    left: 25%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 12px 16px;
  
    & span {
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71b6f9;
      text-transform: uppercase;
      opacity: 1;
    }
  
    & p {
      text-align: left;
      font: normal normal normal 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #343a40;
      opacity: 1;
    }
  
    &::after {
      content: '';
      background-color: white;
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -5%;
      bottom: 20%;
    }
  }

  

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url('../../../assets/images/StrategicGame/Groupe_masques_12_min.png');
    width: 260px;
    height: 340px;
  }
}
 
