.btn_content{
    display: flex;
    justify-content:flex-end;
    align-items:center;

}
.headerTicket {
    background-color: #F4F5F9;
    color           : #313131;
    text-align      : center;
    height          : 60px;
    align-items     : center;
    justify-content : center;
    display         : flex;
    border          : 1px solid #DADEE0;
    font-size: 13px;
    font-family: Karla;
    font-weight: bold;

    .bl1{
       flex: 1.3;
    }
    .bl2{
        flex: 1;
        text-align: start;
        padding-left: 12px;
    }
}

