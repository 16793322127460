$backgroundColor:#f00; //change background color of table

// @import  "tabulator_simple"; //import table scss
.tabulator{
    .tabulator-headers{
        // border: 1px solid #DADEE0 !important;
        background-color: #F4F5F9;
        .tabulator-col{
            background-color: #F4F5F9;
        }
    }
}

.tabulator-row.tabulator-selected{
    background-color:#EBF1F3 !important;

}

.tabulator-row.tabulator-selectable{

    &:hover{
        background-color:#EBF1F3 !important;

    }      

}

.tabulator .tabulator-header .tabulator-col{
	background: #f5f5f5 !important; /*highlight selected row red, make sure it overrides existing styling*/

}


[type="search"] {
    border: 1px solid #CED8E7;

    &:focus{
        border: 0px !important;
        // background-color: #313131;
    }
}



.tabulator-row.tabulator-group {
    padding: 5px;
    padding-left: 10px;
    background: #ffffff;
    font-weight: 700;
    min-width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    border-right: 0px; 
    border-top: 0px;

    font-family: Karla;
    font-size: 14px;
    font-weight: bold;
    color: #475466;
    
    }


.tabulator-col-content{
    height: 73px;
}

.tabulator-cell{
 
    /* color: #313131; */
    font-family: Karla;
    font-size: 14px;
    color: #475466;
    padding: 10px 0px;
    text-align: center;
}

.tabulator-col-title{
    font-family: Karla;
    font-size: 14px;
    font-weight: bold;
    color: #313131;
}


.tabulator .tabulator-headers{

    // border: 1px solid #DADEE0;
  }


 

  .tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title{

  text-align: left;
}