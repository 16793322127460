.single-tab-container .single-tab-btn:hover {
  background: #4777a7;
  /*box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);*/
  border-radius: 5px;
  transition: 0.7s ease;
}
.single-tab-container .single-tab-btn:focus > span {
  color: white !important;
}

.single-tab-container .single-tab-btn:focus {
  /*transform: scale(1.1);*/
}

.single-tab-container svg {
  fill: #34607f;
  stroke: white;
}

/* Reverse it when hover */
.single-tab-container:hover svg {
  fill: white;
  stroke: #34607f !important;
}

.single-tab-container:hover span {
  color: white;
}
.hidden-div {
  /*display: none;*/
}




